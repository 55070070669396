import React, { useState } from "react";
import { useEffect } from "react";
import Iframe from "react-iframe";
import { useMyContext } from "../../contexts/StateHolder";
import { findSubOrganizationSecret } from "../../scripts/dataHandlers";
import * as settings from "../../config_settings.json";
import Loader from "react-loader-spinner";
import { useCookies } from "react-cookie";

export default function GamesAnalytics({ accessedbySuperAdmin }) {
  const { user } = useMyContext();
  console.log("user", user);
  const [cookies] = useCookies("");

  const [secret, setSecret] = useState(null);

  // need to find secret of organization before showing the Iframe
  useEffect(() => {
    const fetchSecret = async () => {
      const res = await findSubOrganizationSecret(
        settings.organization.organizationId,
        settings.organization.key,
        user?.organizationId
      );
      setSecret(res?.secret);
      // console.log('res', res);
    };

    if (user) {
      if (accessedbySuperAdmin) {
        setSecret(cookies?.supAdSec);
      } else {
        if (user?.organizationId * 1 === settings.organization.organizationId) {
          setSecret(settings.organization.key);
        } else {
          fetchSecret();
        }
      }
    }
  }, [user, accessedbySuperAdmin]);

  return user && secret ? (
    <Iframe
      url={`https://staging1.event-analytics.icareus.com/analytics/events/dashboard?organizationId=${
        accessedbySuperAdmin ? cookies?.supAdOrg : user?.organizationId
      }&secret=${secret}`}
      width="100%"
      height="1300px"
      className="myClassname"
    />
  ) : (
    <>
      <div className="display-flex-center" style={{ minHeight: "100vh" }}>
        <Loader type="TailSpin" color="black" height={50} width={50} />
      </div>
    </>
  );
}
