import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../Shared/Button/Button";
import Header from "../../../Shared/Header/Header";
import * as classes from "./AdminUsers.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as settings from "../../../config_settings.json";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../contexts/StateHolder";
import { deleteUser, fetchAllAdmins } from "../../../scripts/dataHandlers";
import { useCookies } from "react-cookie";
import Modal from "../../../Shared/Modal/Modal";
import { toast } from "react-toastify";

export default function AdminUsers() {
  const { t } = useTranslation();

  const { user } = useMyContext();

  const [allAdmins, setAllAdmins] = useState(null);
  const [cookies] = useCookies([" "]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);

  const fetchAdmin = useCallback(async () => {
    console.log("user", user);
    const res = await fetchAllAdmins(
      cookies?.supAdOrg ? cookies?.supAdOrg : user?.organizationId,
      user?.userToken
    );

    // console.log('res', res);
    setAllAdmins(res?.users);
  }, [user, cookies?.supAdOrg]);

  useEffect(() => {
    user?.organizationId && fetchAdmin();
  }, [user]);

  const confirmDeleteUserhandler = async () => {
    console.log("el", selectedDeleteItem);
    const res = await deleteUser(
      cookies?.supAdOrg ? cookies?.supAdOrg : user?.organizationId,
      selectedDeleteItem?.userId,
      cookies?.supAdSec ? cookies?.supAdSec : user?.userSecret,
      user?.userToken
    );
    console.log("res", res);
    if (res?.data?.status === "ok") {
      // we have deleted user
      await fetchAdmin();

      toast.success("Deleted successfully");
    } else {
      // something went wrong
      toast.error(t("changePassword.somethingWrongTryAgain"));
    }
    setSelectedDeleteItem(null);
    setShowDeleteModal(false);
  };

  const cancelModalHandler = () => {
    setSelectedDeleteItem(null);
    setShowDeleteModal(false);
  };

  const deleteUserHandler = (el) => {
    setSelectedDeleteItem(el);
    setShowDeleteModal(true);
  };

  return (
    <div className={classes.projects_info}>
      <Header
        title={t("adminUsers.allAdminDetails")}
        extraClassname
        styles={{ color: "black" }}
        color="black"
      />
      {showDeleteModal && (
        <Modal
          button1OnClick={confirmDeleteUserhandler}
          button2OnClick={cancelModalHandler}
          buttonText1="confirm"
          buttonText2={"cancel"}
          text={"Are you sure  you want to delete ?"}
        />
      )}
      <div className={`${classes.projects_details} protrebleheavy`}>
        <div className={classes.projects_info__order_number}>#</div>

        <div className={classes.project_info__name}>
          {t("signUpForm.firstName")}
        </div>
        <div className={classes.project_info__created}>
          {" "}
          {t("signUpBar.inputPlaceholderEmail")}
        </div>

        <div className={classes.project_info__action}>
          {t("adminUsers.actions")}
        </div>
      </div>
      {allAdmins && allAdmins.length === 0 && (
        <h2 className="project-notFound">{t("adminUsers.noAdminFound")}</h2>
      )}

      {allAdmins &&
        allAdmins.length > 0 &&
        allAdmins.map((el, i) => (
          <div
            className={`${classes.projects_detail} regular`}
            key={el?.userId}
          >
            <div className={classes.projects_info__order_number}>{i + 1}</div>
            <div className={classes.projects_info__name}>{el.firstName}</div>
            <div className={classes.project_info__created}>
              {el.emailAddress}{" "}
            </div>
            <div className={classes.project_info__action}>
              {/* Need to pass exact userId based on api  */}
              <Button
                inverse
                className="project-info__openbutton"
                to={`/${settings.routes.adminUsersList}/${el?.userId}`}
                //   onClick={() => openprojectButtonHandler(el)}
              >
                <FontAwesomeIcon icon="edit" />
              </Button>
              <Button
                danger
                className="project-info__deletebutton"
                onClick={() => deleteUserHandler(el)}
              >
                <FontAwesomeIcon icon="trash" />
              </Button>
              <Button
                className="project-info__openbutton"
                inverse
                to={`/${settings.routes.adminUsersChangepassword}/${el?.userId}`}
                //   onClick={() => openprojectButtonHandler(el)}
              >
                <FontAwesomeIcon icon="lock" />
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
}
