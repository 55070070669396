import { useTranslation } from "react-i18next";
import { convertCurrency, trancuateDesc } from "../../../scripts/utils";
import * as classes from "./PackageComponent.module.css";
import { useMyContext } from "../../../contexts/StateHolder";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import Button from "../../../Shared/Button/Button";
import { addZeroes } from "../../../scripts/utils";
import { VALIDATOR_REQUIRE } from "../../../Shared/Validation/Validator";
import { Input1 } from "../../../Shared/Input1/Input1";
import * as settings from "../../../config_settings.json";

//shows single package information. Doesn't handle ticket packages or f-liiga single event purchase packages yet.
const PackageComponent = ({
  pkg,
  buyButtonAction,
  userPackages,
  isValid,
  email,
  orgId,
}) => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(["ue"]);

  console.log("pkgname", pkg);

  const {
    user,
    userEmail,
    setUserEmail,
    ticketQuantity,
    setTicketQuantity,
    packageTargetOrganization,
    setPackageTargetOrganization,
  } = useMyContext();
  console.log(settings?.organization?.packages?.inssiDivari?.packageId, pkg.id);

  const increaseTicketQuantity = () => {
    console.log(pkg?.quantity * 1, pkg);
    // package quanity is 0 means we can add unlimited product
    if (pkg?.quantity === 0) {
      setTicketQuantity(ticketQuantity + 1);
    } else {
      if (ticketQuantity >= pkg?.quantity * 1) return;
      setTicketQuantity(ticketQuantity + 1);
    }
  };
  const decreaseTicketQuantity = () => {
    if (ticketQuantity <= 1) return;

    setTicketQuantity(ticketQuantity - 1);
  };

  const selectPackageOrganization = (el, val) => {
    setPackageTargetOrganization(val);
  };

  const getInssiTeams =
    settings?.organization?.packages?.inssiDivari?.teams?.map((value) => {
      return { label: value.name, value: value.organizationId };
    });
  const getDefaultTeam = getInssiTeams?.filter((value) => value.value == orgId);

  const checkForLogin = (el) => {
    if (
      el.id === settings?.organization?.packages?.inssiDivari?.packageId &&
      !(packageTargetOrganization > 0)
    ) {
      toast.error(t("packages.SelectPaymentClub"));
      return;
    }
    console.log(el);
    if (!user.loggedIn && (userEmail || cookies?.ue)) {
      // user wnats to buy from email
      return buyButtonAction(el);
    } else if (user.loggedIn) {
      // buy as a loggedIn user
      return buyButtonAction(el);
    }
  };
  useEffect(() => {
    if (email) {
      setUserEmail(email);
      setCookie("ue", email, {
        path: "/",
      });
    }
  }, [email, setCookie, setUserEmail]);
  console.log(pkg?.category?.contentType);

  const [numberOfTextSize, setNumberOfTextSize] = useState(700);

  return (
    <>
      <div className={classes.packageComponent} key={pkg.id}>
        <div className={classes.packageTitle}>{pkg.name}</div>
        <div
          className={`${classes.packagePrice} probold`}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>{addZeroes(pkg.price)}</p>
          <p>{convertCurrency(pkg.currency)}</p>
        </div>
        <div
          className={classes.packageDescription}
          dangerouslySetInnerHTML={{
            __html: trancuateDesc(pkg.description, numberOfTextSize),
          }}
          onClick={() => {
            setNumberOfTextSize((prev) =>
              numberOfTextSize === 700 ? 20000 : 700
            );
          }}
        />
        <div className={classes.packageBuyButtonContainer}>
          {userPackages.findIndex(
            (userPkg) =>
              userPkg.sku === pkg.sku &&
              userPkg.validFrom < Date.now() &&
              userPkg.validTo > Date.now()
          ) !== -1 ? (
            <div id={pkg.id} name={pkg.id}>
              <Button className={classes.packageBoughtButtonText}>
                {t("packages.Bought")}
              </Button>
            </div>
          ) : (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {pkg?.category?.contentType === 3 && pkg?.quantity > 10000 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "10px",
                    marginTop: "5px",
                  }}
                >
                  {" "}
                  <div style={{ fontWeight: 600 }}>
                    {t("packages.quantity")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Button
                      onClick={decreaseTicketQuantity}
                      disabled={ticketQuantity === 1}
                      style={{
                        padding: "10px 20px ",
                        height: "38px",
                      }}
                    >
                      -
                    </Button>
                    <input
                      value={ticketQuantity}
                      style={{
                        width: "50px",
                        flex: 1,
                        textAlign: "center",
                        height: "38px",
                        outline: "none",
                        border: "none",
                      }}
                    />

                    <Button
                      onClick={increaseTicketQuantity}
                      disabled={
                        pkg?.quantity !== 0 && ticketQuantity === pkg?.quantity
                      }
                      style={{
                        padding: "10px  20px",
                        height: "38px",
                      }}
                      inverse
                    >
                      +
                    </Button>
                  </div>
                </div>
              )}
              {settings?.organization?.packages?.inssiDivari?.packageId ===
                pkg.id && (
                <div
                  id="inssiDivariSelect"
                  className={classes.divariTeamSelect}
                >
                  <Input1
                    id="INSSI_PACKAGE_TEAM"
                    type="text"
                    element={"select_dropdown"}
                    validators={[VALIDATOR_REQUIRE()]}
                    label={"label"}
                    placeholder={"Valitse seura"}
                    errorText={"error"}
                    onInput={selectPackageOrganization}
                    selectData={getInssiTeams}
                    defaultValue={""}
                    // also need to check if these are select_dropdown or input component
                    initialValue={""}
                    initialValid={true}
                    disabled={false}
                  />
                </div>
              )}
              <div id={pkg.id} name={pkg.id} onClick={() => checkForLogin(pkg)}>
                {pkg?.category?.contentType === 3 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItem: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Button
                        className={classes.packageBuyButtonText}
                        disabled={!isValid || !pkg?.locationAccess?.access}
                      >
                        {t("packages.buyTicket")}
                      </Button>
                      {!pkg?.locationAccess?.access && (
                        <p className="font-300 regular">
                          Kyseinen ostotapahtuma ei ole mahdollinen
                          sijainnissasi
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <Button
                    className={classes.packageBuyButtonText}
                    disabled={!isValid}
                  >
                    {t("packages.Order")}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PackageComponent;
