import React, { useCallback, useEffect, useState } from "react";
import AssetVideoPlayer from "../../VideoPlayer/AssetVideoPlayer";
import EventsRoomDescription from "./EventsRoomDescription";

import * as classes from "./UpcomingEvent.module.css";
import EventAllRecordings from "./EventAllRecordings";
import { useParams } from "react-router";
import Loader from "react-loader-spinner";

const CompletedEventWithRecording = ({
    routes,
    singleEvent,
    setTicketAccessGranted,
    ticketAccessGranted,
    ticketAccessRequired,
    recordingAssetId,
    isLiveEvents,
    defaultLanguage,
    setRecordingAssetId,
}) => {
    const [recordingAssetsNumber, setRecordingAssetsNumber] = useState(0);
    const [eventRecordingLoading, setEventRecordingLoading] = useState(true);
    const { orgId, eventId, recordingId } = useParams();

    const checkForVideoAccess = useCallback(async () => {
        if (singleEvent?.isMultiroom ? singleEvent?.rooms[0].recordings?.length > 1 : singleEvent?.recordings?.length > 1) {
            // this means there are more than 1 recording for that particular event and we need to show them in allrecordings

            setRecordingAssetsNumber(singleEvent?.isMultiroom ? singleEvent?.rooms[0].recordings?.length : singleEvent?.recordings?.length);
        }
        setEventRecordingLoading(false);
    }, [recordingId, singleEvent]);
    useEffect(() => {
        if (singleEvent?.eventId) {
            checkForVideoAccess();
        }
    }, [singleEvent?.eventId]);

    useEffect(() => {
        if (recordingId) {
            setEventRecordingLoading(true);
            setRecordingAssetId(recordingId);
            setTimeout(() => {
                setEventRecordingLoading(false);
            }, 1000);
        }
    }, [recordingId]);

    return eventRecordingLoading ? (
        <div className="display-flex-center">
            {" "}
            <Loader type="TailSpin" color="#161eaf" />{" "}
        </div>
    ) : (
        singleEvent && (
            <>
                {ticketAccessGranted && !ticketAccessRequired && (
                    <div className={classes.SecondaryContainer}>
                        <AssetVideoPlayer asset={recordingAssetId} isEvent={true} />
                    </div>
                )}

                <EventsRoomDescription
                    liveEvent={isLiveEvents}
                    routes={routes}
                    ticketAccessGranted={ticketAccessGranted}
                    singleEvent={singleEvent}
                    ticketAccess={ticketAccessRequired}
                    defaultLanguage={defaultLanguage}
                    setTicketAccessGranted={setTicketAccessGranted}
                    futureEvents={false}
                    hideBuyButton={true}
                />
                {ticketAccessGranted && !ticketAccessRequired && recordingAssetsNumber >= 1 && (
                    <EventAllRecordings singleEvent={singleEvent} orgId={orgId} eventId={eventId} />
                )}
            </>
        )
    );
};

export default CompletedEventWithRecording;
