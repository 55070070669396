import React, { useReducer, useEffect } from "react";
import { validate } from "../Validation/Validator";
import * as classes from "./Input1.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button/Button";
import { useState } from "react";
import "./Input1.css";

// for date and timee picker
import "react-datepicker/dist/react-datepicker.css";
import * as rdrLocales from "react-date-range/dist/locale";

// ffor duration picker
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import settings from "../../config_settings.json";

import DatePicker from "react-datepicker";
import date from "date-and-time";

import Select from "react-select";
import colors from "../../scripts/colors";

const inputReducer = (state, action) => {
  const { type, value, validators } = action;

  switch (type) {
    case "CHANGE":
      return {
        ...state,
        value,
        isValid: validate(value, validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "CHANGE_DATE":
      return {
        ...state,
        value,
        isValid: true,
      };
    case "CHANGE_DURATION":
      const d = new Date(value._d.getTime());

      return {
        ...state,
        value: date.format(d, "HH:mm:ss"),
        isValid: true,
      };
    default:
      return state;
  }
};

export const Input1 = ({
  onInput,
  id,
  validators,

  type,
  element,
  placeholder,
  rows,
  errorText,
  label,
  labelInfo,
  initialValid,
  initialValue,
  iconName,
  selectData,
  disabled,
  style,
  button_label,
  changeButton,
  checked,
  switchChangedHandler,
  required,
  selectHomePage,
  colorStyles,
  defaultValue,

  ...props
}) => {
  const defaultColorStyles = {
    control: (styles, state) => ({
      ...styles,
      borderRadius: "1rem",
      height: "50px",
      minHeight: "50px",
      maxHeight: "50px",
      backgroundColor: "white",
      borderColor: colors.brand,
    }),
  };

  const initialState = {
    value: initialValue || "",
    isValid: initialValid || false,
    isTouched: false,
  };
  const [state, dispatch] = useReducer(inputReducer, initialState);

  // for text copied
  const [textCopied, setTextCopied] = useState(false);

  useEffect(() => {
    if (textCopied) {
      setTimeout(() => {
        setTextCopied(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textCopied]);

  // text copied end

  const { value, isValid, isTouched } = state;
  // as soon as the Input element is loaded it takes the value from the state and sends to the onInput which is our
  useEffect(() => {
    // if the function onInput is called it will the function InputHandler in form-hook(for validity check). ALl the data will be passed from here. This function Inputs all handle one input whereas onInput which in returns InputHandler is responsible for handling all the form
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changehandler = (e, date, duration) => {
    // console.log(props.isMulti);
    // date is for date picker
    // console.log(e, date, duration);
    if (props.isMulti ? e[0]?.value && e[0]?.label : e?.value && e?.label) {
      // it means it belongs to select_dropdown

      // is multi means they will have multiple values and should be an array

      dispatch({
        type: "CHANGE",
        value: props.isMulti ? e.map((el) => el?.value) : e?.value,
        validators,
      });
    } else if (date) {
      console.log(e, date);

      setStartDate(date);
      return dispatch({
        type: "CHANGE_DATE",
        value: date,
        validators,
      });
    } else if (duration) {
      return dispatch({
        type: "CHANGE_DURATION",
        value: duration,
        validators,
      });
    } else {
      dispatch({
        type: "CHANGE",
        value: e.target.value,
        validators,
      });
    }
  };
  const touchedHandler = () => {
    dispatch({ type: "TOUCH" });
  };

  const changeButtonClick = (e) => {
    e.preventDefault();
    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setTextCopied(true);
  };
  let elements;
  const [startDate, setStartDate] = useState();

  if (element === "input") {
    if (type === "switch_checkbox") {
      elements = (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between ",
            alignItems: "center",
          }}
        >
          <p
            className={`font-400`}
            style={{
              //   marginBottom: '5px',
              padding: "0rem 0rem 0.2rem 1rem",
              color: colors.brand,
            }}
          >
            {label}
          </p>

          {/* css related to button_ switch is in Input1.css */}
          <div className="button-switch">
            <input
              type="checkbox"
              id={id}
              className="switch"
              checked={checked}
              onChange={(e) => switchChangedHandler(e.target.checked)}
              disabled={disabled}
            />
            <label htmlFor={id} className="lbl-off">
              Off
            </label>
            <label htmlFor={id} className="lbl-on">
              On
            </label>
          </div>
        </div>
      );
    } else if (type === "date_time") {
      elements = (
        <div>
          {" "}
          {!iconName ? (
            <p
              className={`font-400 protrebleheavy`}
              style={{
                marginBottom: "5px",
                padding: "0rem 0rem 0.2rem 1rem",
                color: colors.brand,
              }}
            >
              {label}
            </p>
          ) : (
            <p>
              {
                <FontAwesomeIcon
                  icon={iconName}
                  className={classes.iconColor}
                />
              }
            </p>
          )}
          <label
            className={`${classes.inp} ${
              !isValid && isTouched && classes.formInputInvalid
            } `}
          >
            <div style={{ position: "relative" }}>
              {" "}
              <DatePicker
                className={`${classes.input_text} font-200  protrebleheavy `}
                selected={value}
                onChange={(date) => changehandler(date, date)}
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="aika"
                dateFormat="MMMM d, yyyy H:mm "
                locale={
                  settings.language.includes("fi")
                    ? rdrLocales.fi
                    : rdrLocales.en
                }
                onBlur={touchedHandler}
                value={value}
                required={required}
                showMonthDropdown
                showYearDropdown
                showTimeSelect
                dropdownMode="select"
                disabled={disabled}
                // withPortal
              />
            </div>
          </label>
        </div>
      );
    } else if (type === "duration_picker") {
      elements = (
        <div className="protrebleheavy ">
          {" "}
          {!iconName ? (
            <p
              className={`font-400`}
              style={{
                marginBottom: "5px",
                padding: "0rem 0rem 0.2rem 1rem",
                color: colors.brand,
              }}
            >
              {label}
            </p>
          ) : (
            <p>
              {
                <FontAwesomeIcon
                  icon={iconName}
                  className={classes.iconColor}
                />
              }
            </p>
          )}
          <label
            className={`${classes.inp} ${
              !isValid && isTouched && classes.formInputInvalid
            } datetime-picker  `}
          >
            <div style={{ position: "relative" }}>
              {" "}
              <Datetime
                input={true}
                timeFormat="HH:mm:ss"
                dateFormat={false}
                className={`${classes.input_text} font-400  `}
                timeConstraints={{
                  hours: { min: 0, max: 3 },
                  minutes: { min: 0, max: 0 },
                  seconds: { min: 0, max: 0 },
                }}
                value=""
                // this is for onBlur->onOpen
                onOpen={touchedHandler}
                onChange={(e, date) => changehandler(date, null, e)}
                initialValue={initialValue}
                inputProps={{ disabled }}
              />
            </div>
          </label>
        </div>
      );
    } else {
      elements = (
        <label
          className={`${classes.inp} ${
            !isValid && isTouched && classes.formInputInvalid
          } `}
        >
          <input
            className={`${classes.input_text} font-400 regular`}
            placeholder="&nbsp;"
            // placeholder={placeholder}
            id={id}
            type={type}
            onChange={changehandler}
            onBlur={touchedHandler}
            value={value}
            disabled={disabled}
            style={style}
          />
          <span className={`${classes.label} font-200 protrebleheavy`}>
            {label}
          </span>
          {iconName && (
            <span className={`${classes.input_icon} font-500`}>
              {<FontAwesomeIcon icon={iconName} />}
            </span>
          )}
          {changeButton && (
            <span className={`${classes.input_icon_change} font-500`}>
              <Button onClick={changeButtonClick} inverse>
                {" "}
                {button_label}
              </Button>
            </span>
          )}
          {changeButton && textCopied && (
            <div className={textCopied ? "copy-container active" : ""}>
              <div className={textCopied ? "copy-popup active" : ""}>
                <div className="font-600">Copied to clipboard!</div>
                <div className="font-600">&#128077;</div>
              </div>
            </div>
          )}
        </label>
      );
    }
  } else if (element === "textarea") {
    elements = (
      <div className="protrebleheavy">
        <p
          className={`font-400  `}
          style={{
            //   marginBottom: '5px',
            padding: "0rem 0rem 0.2rem 1rem",
            color: colors.brand,
          }}
        >
          {label}
        </p>

        <textarea
          id={id}
          onChange={changehandler}
          onBlur={touchedHandler}
          value={value}
          rows={rows || 3}
          className={`${classes.input_text} font-400 regular `}
        />
      </div>
    );
  } else if (element === "select") {
    /* dont pass iccon name in select */

    elements = (
      <div className={classes.select_label}>
        {!iconName ? (
          <p
            className={`font-400 protrebleheavy`}
            style={{
              //   marginBottom: '5px',
              padding: "0rem 0rem 0.2rem 1rem",
              color: selectHomePage ? "rgb(0,172,222)" : "white",
            }}
          >
            {label}
          </p>
        ) : (
          <p>
            {<FontAwesomeIcon icon={iconName} className={classes.iconColor} />}
          </p>
        )}
        <select
          style={style}
          value={value}
          onChange={changehandler}
          onBlur={touchedHandler}
          className={`${classes.input_text} font-400 regular  `}
        >
          <option
            value={placeholder}
            className={classes.selectFirstSelectedValue}
            // disabled
          >
            {placeholder}
          </option>
          {selectData?.map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </select>{" "}
      </div>
    );
  } else if (element === "select_dropdown") {
    console.log(selectData);
    elements = (
      <div>
        {!iconName ? (
          <p
            className={`font-300 protrebleheavy`}
            style={{
              //   marginBottom: '5px',
              padding: "0rem 0rem 0.2rem 1rem",
              color: selectHomePage ? "rgb(0,172,222)" : colors.brand,
            }}
          >
            {label}
          </p>
        ) : (
          <p>
            {<FontAwesomeIcon icon={iconName} className={classes.iconColor} />}
          </p>
        )}

        {labelInfo && (
          <>
            <p
              className={`font-300 regular`}
              style={{
                color: colors.primary,
                marginTop: "5px",
              }}
            >
              {labelInfo}
            </p>
            <br />
          </>
        )}

        <div className="protrebleheavy">
          {" "}
          <Select
            options={selectData}
            // value={value}
            onChange={changehandler}
            onBlur={touchedHandler}
            // isClearable={true}
            placeholder={placeholder}
            className={`font-200`}
            styles={colorStyles ? colorStyles : defaultColorStyles}
            defaultValue={defaultValue?.label && defaultValue}
            isDisabled={disabled}
            {...props}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      {elements}
      {!isValid && isTouched && errorText && (
        <p className={`${classes.errorText} font-300 regular`}>{errorText}</p>
      )}
    </>
  );
};
