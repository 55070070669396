import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { momentDate } from "../../../scripts/utils";
import Header from "../../../Shared/Header/Header";
import * as classes from "./UpcomingEvent.module.css";

const CompletedEventsWithoutRecording = ({ singleEvent, defaultLanguage }) => {
  const { t } = useTranslation();

  return (
    <div className={`${classes.EventTertairy} smallcasebold`}>
      <div className={classes.EventPrimary}>
        <Header
          extraClassname={true}
          title={singleEvent?.name[defaultLanguage || "en_US"]}
          styles={{ color: "black" }}
          color={"black"}
        />

        <div className={classes.EventTimeDescription}>
          <div className={`${classes.EventPrimary_date} font-300`}>
            <div>
              <FontAwesomeIcon icon="calendar" />
            </div>

            <div>
              {momentDate(
                singleEvent.startTimePublic
                  ? singleEvent?.startTimePublic
                  : singleEvent?.startTime
              )}
            </div>
          </div>
          <div className={`${classes.EventPrimary_time} font-300`}>
            <div>
              {" "}
              <FontAwesomeIcon icon="clock" />
            </div>
            <div>
              {moment(
                singleEvent.startTimePublic
                  ? singleEvent?.startTimePublic
                  : singleEvent?.startTime
              )
                .locale("fi")
                .format("LT")}
            </div>{" "}
          </div>
        </div>

        <div
          className={classes.EventPrimary_description}
          dangerouslySetInnerHTML={{
            __html: singleEvent.description[defaultLanguage],
          }}
          style={{ paddingBottom: "30px" }}
        ></div>

        <div className={`${classes.EventPrimary_countdown} font-500`}>
          {t("eventsCategory.eventsEnded")}
        </div>
      </div>
    </div>
  );
};

export default CompletedEventsWithoutRecording;
