import React from "react";
import { useTranslation } from "react-i18next";
import * as classes from "../DashboardMainPage/DashboardMainPage.module.css";
import Header from "../../../Shared/Header/Header";
import { getImageByKey } from "../../../scripts/getImageByKey";

export default function Settings() {
  const { t } = useTranslation();

  return (
    <div className={classes.DashboardMainPage}>
      <Header
        title={t("adminSideBar.settings")}
        styles={{ textAlign: "center", color: "black" }}
        extraClassname
        color="black"
      />
      <img
        src={getImageByKey("settings")}
        alt=""
        className={classes.DashboardMainPage_welcome}
      />
    </div>
  );
}
