import React, { useEffect, useState } from "react";

import * as classes from "../../../../Shared/Input1/Input1.module.css";
//import * as classes from '../DashboardMainPage/DashboardMainPage.module.css';
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../../contexts/StateHolder";
import { Input1 } from "../../../../Shared/Input1/Input1";
import { getAccountSettings, updateAccountSettings } from "../../../../scripts/dataHandlers";
import Loader from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../../../Shared/Header/Header";
import Button from "../../../../Shared/Button/Button";
import { useForm } from "../../../../Shared/Hooks/form-hook";
import { useCookies } from "react-cookie";

export default function VismaSettings(props) {
    //const { goBackToPrevious, setGoBackToPrevious, user, allClubs, setAllClubs } =
    const { goBackToPrevious, user } = useMyContext();

    const { t } = useTranslation();
    const [cookies] = useCookies("");

    // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform

    const [state, InputHandler] = useForm(
        {
            SUBMERCHANTID: {
                value: "",
                isValid: true,
            },
        },
        false
        // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
    );

    const [requestLoading, setRequestLoading] = useState(false);

    const [userAccountFetched, setUserAccountFetched] = useState(false);

    const [adminSettingSubmerchantId, setAdminSettingSubmerchantId] = useState(null);

    useEffect(() => {
        //When user changes we wil lget new users account settings
        const getClubAdminSettings = async () => {
            const res = await getAccountSettings(
                props.accessedbySuperAdmin && cookies?.supAdOrg ? cookies?.supAdOrg : user?.organizationId,

                user?.userToken
            );

            setAdminSettingSubmerchantId(res?.data?.vismaSubmerchantId);
            setUserAccountFetched(true);
        };

        user && getClubAdminSettings();
    }, [user, props.accessedbySuperAdmin]);

    const formSubmitHandler = async (e) => {
        e.preventDefault();

        setRequestLoading(true);

        let settings = {
            vismaSubmerchantId: state.inputs.SUBMERCHANTID.value,
        };

        try {
            let res = await updateAccountSettings(
                props.accessedbySuperAdmin && cookies?.supAdOrg ? cookies?.supAdOrg : user?.organizationId,

                user?.userToken,
                settings
            );

            if (res?.data?.status === "ok") {
                toast.success(t("adminSettings.updateSuccess"));
            } else {
                toast.error(t("adminSettings.updateFailure"));
            }
            setRequestLoading(false);
        } catch (error) {
            setRequestLoading(false);
        }
    };

    return (
        <>
            {userAccountFetched ? (
                <div className="loginViewContainer">
                    <div className={classes.formPrimary}>
                        <div className={classes.formSecondary}>
                            <form className={classes.formBlogAdmin} onSubmit={formSubmitHandler}>
                                <Header title={t("sideBar.usersSettings")} extraClassname style={{ justifyContent: "center" }} admin={true} />
                                <p
                                    className="font-400"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    Huom! Tärkeä ohje: seuran oikea Submerchant ID -tunnus eli alikauppiastunnus löytyy Visma Payn kauppiasportaalista
                                    alasivulta Submerchant (ENG) / Alikauppiaat (FIN). Siellä on viisinumeroinen tunnus nimellä Submerchant-ID /
                                    Alikauppiastunnus – tämä tulee syöttää tälle SalibandyTV:n sivulle, muuten maksullisuus ei toimi oikein! Älä siis käytä
                                    seuran Visma Pay -kauppiastunnusta vaan alikauppiastunnusta.
                                </p>

                                <Input1
                                    id="SUBMERCHANTID"
                                    placeholder=""
                                    type="text"
                                    element="textarea"
                                    validators=""
                                    label={t("adminSettings.submerchantId")}
                                    errorText=""
                                    onInput={InputHandler}
                                    initialValue={adminSettingSubmerchantId}
                                    initialValid={true}
                                />
                                <Button className={classes.loginFormButton} disabled={!state.isValid || requestLoading} formButton inverse>
                                    {requestLoading ? <FontAwesomeIcon icon="spinner" spin /> : t("adminUsersDashboard.save")}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="display-flex-center" style={{ minHeight: "100vh" }}>
                    <Loader type="TailSpin" color="#dfdfdf" height={50} width={50} />
                </div>
            )}
        </>
    );
}
