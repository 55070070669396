import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";

import {
  changePassword,
  findSubOrganizationSecret,
} from "../../../scripts/dataHandlers";
import { useForm } from "../../../Shared/Hooks/form-hook";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_PASSWORDMATCH,
  VALIDATOR_NONE,
} from "../../../Shared/Validation/Validator";
import * as classes from "../../../Shared/Input1/Input1.module.css";
import { useMyContext } from "../../../contexts/StateHolder";
import { Input1 } from "../../../Shared/Input1/Input1";
import Header from "../../../Shared/Header/Header";
import Button from "../../../Shared/Button/Button";
import * as settings from "../../../config_settings.json";

const ChangePassword = (props) => {
  const { userId } = useParams();

  const history = useHistory();
  // Bring stateholders from context
  const { organizationId, key, chosenMenuOptionProfile, user } = useMyContext();
  const [cookies] = useCookies("");

  // Holds inform message for form
  const [formMessage, setFormMessage] = useState("");

  const [loading, setLoading] = useState(false);

  // Setup translate function
  const { t } = useTranslation();

  // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform
  const [state, InputHandler] = useForm(
    {
      PASSWORD: {
        value: "",
        isValid: false,
      },
      PASSWORDCONFIRM: {
        value: "",
        isValid: false,
      },
      OLDPASSWORD: {
        value: "",
        isValid: props.admin ? true : false,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );

  const formSubmitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();

    // need new api for password change of admin by other admin

    // Do the signUp/login process
    // we will later get key from login api
    let secret;

    if (user?.organizationId * 1 === settings.organization.organizationId * 1) {
      // incase of superAdmin -> super admin is created in salibandy parent org and this will match the above condition
      if (props.accessedbySuperAdmin) {
        // we have secret in cookies for superadmin
        secret = cookies?.supAdSec;
      } else {
        secret = key;
      }
    } else {
      // suborg will not change ->admin will only be shoown other admin of same org

      if (props.accessedbySuperAdmin) {
        // we have secret in cookies for superadmin
        secret = cookies?.supAdSec;
      } else {
        const res2 = await findSubOrganizationSecret(
          settings.organization.organizationId,
          key,
          user?.organizationId
        );
        secret = res2?.secret;

        console.log("res2", res2);
      }
    }
    // as of now when one admin change password of other it just changes admin password but user password remains same
    const response = await changePassword(
      props.accessedbySuperAdmin && cookies?.supAdOrg
        ? cookies?.supAdOrg
        : user?.organizationId,

      secret,
      props?.admin ? userId : user?.userId,
      state.inputs.PASSWORD.value,
      state.inputs.PASSWORDCONFIRM.value,
      state.inputs.OLDPASSWORD.value,
      user?.userToken,
      props?.admin && userId
    );

    console.log(response);
    console.log("state", state);

    response?.data?.status === "ok"
      ? toast.success(t("changePassword.changedSuccessfully"), {
          autoClose: 10000,
          closeOnClick: true,
          draggable: true,

          theme: "dark",
        })
      : response?.data?.message
      ? toast.error(response?.data?.message, {
          autoClose: 10000,
          closeOnClick: true,
          draggable: true,

          theme: "dark",
        })
      : toast.error(t("changePassword.somethingWrongTryAgain"), {
          autoClose: 10000,
          closeOnClick: true,
          draggable: true,

          theme: "dark",
        });

    console.log("state", state);
    setLoading(false);
    setTimeout(() => {
      props?.admin ? history.goBack() : window.location.reload();
    }, 1000);
  };

  return (
    <>
      <div className={`${classes.formPrimary} ${classes.formPrimarySignIn}`}>
        <div className={classes.formSecondary}>
          <form className={classes.formBlogAdmin} onSubmit={formSubmitHandler}>
            {" "}
            <Header
              title={t("profileMenu.changePassword")}
              extraClassname
              style={{ textAlign: "center" }}
              admin={props.admin}
            />
            {!props.admin && (
              <Input1
                id="OLDPASSWORD"
                label={t("changePassword.askForOldPassword")}
                placeholder={t("changePassword.askForOldPassword")}
                type="Password"
                element="input"
                validators={[VALIDATOR_NONE()]}
                errorText={t("changePassword.errorPasswordNotLongEnough", {
                  count: "5",
                })}
                onInput={InputHandler}
                iconName="lock"
              />
            )}
            <Input1
              id="PASSWORD"
              placeholder={t("changePassword.askForNewPassword")}
              label={t("changePassword.askForNewPassword")}
              type="Password"
              element="input"
              validators={[VALIDATOR_MINLENGTH(5)]}
              errorText={t("changePassword.errorPasswordNotLongEnough", {
                count: "5",
              })}
              onInput={InputHandler}
              iconName="lock"
            />
            <Input1
              id="PASSWORDCONFIRM"
              label={t("changePassword.askForConfirmPassword")}
              placeholder={t("changePassword.askForConfirmPassword")}
              type="Password"
              element="input"
              validators={[
                VALIDATOR_PASSWORDMATCH(
                  state.inputs.PASSWORD.value,
                  state.inputs.PASSWORDCONFIRM.value
                ),
              ]}
              errorText={t("changePassword.errorPasswordsDontMatch")}
              onInput={InputHandler}
              iconName="lock"
            />
            <div className="font-200"></div>
            <Button
              className={classes.loginFormButton}
              disabled={!state.isValid || loading}
              formButton
              inverse
            >
              {t("changePassword.save")}
            </Button>
            <div className={classes.profileMessage}>{formMessage}</div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
