import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import ResetPasswordChange from '../components/ResetPassword/ResetPasswordChange';
import ResetAdminPasswordForm from '../components/ResetPassword/ResetAdminPasswordForm';
import Spinner from '../components/Spinner/Spinner';

export default function RequestAdminResetPassword() {
  const location = useLocation();

  const [queryTokenChecked, setQueryTokenChecked] = useState(false);
  const [tokenFromEmail, setTokenFromEmail] = useState(false);

  useEffect(() => {
    const tok1 = new URLSearchParams(location.search).get('token');

    if (tok1) {
      setTokenFromEmail(tok1);
    }
    setQueryTokenChecked(true);
    return () => {
      setTokenFromEmail('');
    };
  }, [location.search]);

  return (
    <div className='loginViewBackground' style={{ flex: 1 }}>
      {!queryTokenChecked ? (
        <Spinner />
      ) : tokenFromEmail ? (
        <ResetPasswordChange tokenFromEmail={tokenFromEmail} />
      ) : (
        <ResetAdminPasswordForm />
      )}
    </div>
  );
}
