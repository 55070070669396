import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import Modal from "react-modal";
import moment from "moment";

import { getAllEvents, getLiveNowGames, getUpcomingEvents, getUpcomingGames } from "../../../scripts/dataHandlers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as classes from "../Events.module.css";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../../Shared/Header/Header";
import { useTranslation } from "react-i18next";

import ReactDatePicker from "../../../Shared/DateRange/ReactDatePicker";
import EventsItem1 from "../Events/EventsItem1/EventsItem1";

import { getImageByKey } from "../../../scripts/getImageByKey";
import { useSearchParams } from "../../../Shared/Hooks/search-hook";
import { useMyContext } from "../../../contexts/StateHolder";

Modal.setAppElement(document.body);

export default function UpcomingEventsCategoryItem({
    settings: { limit, eventType },
    accessedbyAdmin,
    setting,
    categoryIdProps,
    organizationIdProps,
    categoryNameProps,
    extraClassname,
    hideViewAll,
    styles,
    hideCarousel,
    accessedbySuperAdmin,
}) {
    const { t } = useTranslation();

    let { organizationId } = useParams();

    let categoryName = "Upcoming Events";
    let categoryId = "upcomingEvent";

    let orgId = accessedbyAdmin ? organizationIdProps : organizationId;
    console.log("upc orgId", orgId);

    const [allEvents, setAllEvents] = useState(null);
    const [startDateValue, setStartDateValue] = useState(new Date());
    const [endDateValue, setEndDateValue] = useState(new Date());
    const [showDateTimeModal, setShowDateTimeModal] = useState(false);
    const [upcomingFilterApplied, setupcomingFilterApplied] = useState(true);
    const { selectedArea, selectedLevelContext, resetSelectedLevel, resetSelectedArea, setSelectedArea, setSelectedLevelContext } = useMyContext();
    const { searchParam } = useSearchParams(selectedArea, selectedLevelContext, resetSelectedArea, resetSelectedLevel);

    // time ranges from filters
    const [ranges, setRanges] = useState(null);

    const fetchOnlyUpcomingEvents = useCallback(async (oId, catId, searchPar) => {
        // it means theere is no category but contains events from "upcomingEvents or live events", we have to send different api requests
        let res = await getUpcomingGames(oId, limit, null, null, searchPar);

        // console.log(res, 'allevents');
        let s = res?.data?.games.sort((a, b) => a.startTime - b.startTime);

        setAllEvents(s);
    }, []);

    // console.log(categoryName, organizationId, categoryId, eventType, '111');

    useEffect(() => {
        const runFunction = async () => {
            console.log("ranges", ranges);
            let categoryIdLocal;
            let organizatioidLocal;
            if (organizationId && categoryId && categoryId) {
                categoryIdLocal = categoryId;
                organizatioidLocal = organizationId;
            } else if (accessedbyAdmin && organizationIdProps && categoryIdProps) {
                categoryIdLocal = categoryIdProps;
                organizatioidLocal = organizationIdProps;
            }

            console.log("categoryIdLocal", categoryIdLocal);

            let res;

            console.log(Date.parse(startDateValue), Date.parse(endDateValue));

            if (categoryIdLocal === "upcomingEvent") {
                res = await getUpcomingGames(
                    organizatioidLocal,
                    100,

                    ranges?.selection?.startDate && Date.parse(ranges?.selection?.startDate),

                    ranges?.selection?.endDate && Date.parse(ranges?.selection?.endDate),
                    searchParam
                );
                let s1 = res?.data?.games.sort((a, b) => a.startTime - b.startTime);
                setAllEvents(s1);
            } else if (categoryIdLocal === "pastEvent") {
                res = await getUpcomingGames(
                    organizatioidLocal,
                    100,

                    ranges?.selection?.startDate && Date.parse(ranges?.selection?.startDate),

                    ranges?.selection?.endDate && Date.parse(ranges?.selection?.endDate),
                    searchParam,
                    true,
                    // true means it will take from into consideration,
                    true
                );

                let s2 = res?.data?.games.sort((a, b) => b.startTime - a.startTime);
                setAllEvents(s2);
            }
        };
        if (searchParam) {
            runFunction();
        }
    }, [searchParam, JSON.stringify(searchParam, ranges)]);

    useEffect(() => {
        if (accessedbyAdmin) {
            organizationIdProps && categoryIdProps && fetchOnlyUpcomingEvents(organizationIdProps, categoryIdProps, searchParam);
        } else {
            organizationId && categoryId && fetchOnlyUpcomingEvents(organizationId, categoryId, searchParam);
        }
    }, [organizationId, accessedbyAdmin, fetchOnlyUpcomingEvents, categoryIdProps, organizationIdProps]);

    // console.log('organizationId,categoryId', organizationId, categoryId);

    useEffect(() => {
        setSelectedArea([]);
        setSelectedLevelContext([]);

        return () => {
            setSelectedArea([]);
            setSelectedLevelContext([]);
        };
    }, []);

    const removeAppliedFilter = () => {
        setupcomingFilterApplied(true);
        setRanges(null);
        accessedbyAdmin
            ? fetchOnlyUpcomingEvents(organizationIdProps, categoryIdProps, searchParam)
            : fetchOnlyUpcomingEvents(organizationId, categoryId, searchParam);
    };
    const selectionRange = {
        startDate: startDateValue,
        endDate: endDateValue,
        key: "selection",
    };
    const handleSelect = (ranges) => {
        setStartDateValue(ranges.selection.startDate);
        setEndDateValue(ranges.selection.endDate);
        setRanges(ranges);
    };
    const handleCloseDialog = () => {
        console.log(showDateTimeModal);
        setShowDateTimeModal(!showDateTimeModal);
    };

    const handleConfirm = async () => {
        if (startDateValue.getTime() === endDateValue.getTime()) {
            return toast.error(`${t("eventsCategory.errorStartEndSame")}`, {
                autoClose: 10000,
                position: "top-center",
                theme: "dark",
            });
        }

        let res;
        if (accessedbyAdmin ? categoryIdProps : categoryId === "upcomingEvent") {
            console.log("UPC HERE 1");
            // it means theere is no category but contains events from "upcomingEvents", we have to send different api requests

            console.log("searchParam", searchParam);
            res = await getUpcomingGames(orgId, 10, Date.parse(startDateValue), Date.parse(endDateValue), searchParam);
        } else if (accessedbyAdmin ? categoryIdProps : categoryId === "liveEvent") {
            console.log("UPC HERE 2");
            if (accessedbyAdmin) {
                res = await getLiveNowGames(organizationIdProps);
            } else {
                res = await getLiveNowGames(organizationId);
            }
        } else if (accessedbyAdmin ? categoryIdProps : categoryId === "pastEvent") {
            console.log("UPC HERE 3");
            if (accessedbyAdmin) {
                // res = await getLiveNowGames(organizationIdProps);
            } else {
                res = await getUpcomingGames(orgId, 100, Date.parse(startDateValue), Date.parse(endDateValue), searchParam, true, true);
            }
        } else {
            console.log("UPC HERE 4");
            if (accessedbyAdmin) {
                res = await getUpcomingGames(organizationIdProps, categoryIdProps, null, Date.parse(startDateValue), Date.parse(endDateValue), searchParam);
            } else {
                res = await getUpcomingGames(organizationId, categoryId, null, Date.parse(startDateValue), Date.parse(endDateValue), searchParam, true);
            }
        }

        console.log(res);
        if (res.data.status === "ok") {
            setAllEvents(res.data.games);
        }
        setStartDateValue(new Date());
        setEndDateValue(new Date());

        handleCloseDialog();
        setupcomingFilterApplied(false);
    };

    console.log("upc categoryId", categoryId);
    console.log("upc categoryIdProps", categoryIdProps);
    console.log("upc categoryName", categoryName);

    let title = t("eventsCategory.upcomingEventsTitle");

    return (
        <>
            {" "}
            <div className={classes.Events_main} style={{ margin: "0 auto" }}>
                <div className={classes.Events_main_info}>
                    <Header extraClassname={extraClassname} style={styles} title={title} admin={accessedbyAdmin} showTitle={accessedbyAdmin} />

                    <div className={`${classes.Events_main_info_button} font-400 smallcasebold`}>
                        {!upcomingFilterApplied && ranges && (
                            <div>
                                {moment(ranges.selection.startDate).format("L")} -{moment(ranges.selection.endDate).format(" L")}
                                {console.log(ranges, "ranges")}
                            </div>
                        )}
                        {!upcomingFilterApplied && (
                            <button className={classes.Events_main_info_filter_removeFilter} onClick={removeAppliedFilter}>
                                <FontAwesomeIcon icon="times" size="1x" className={classes.removeIcon} />
                            </button>
                        )}
                        <button className={classes.Events_main_info_filter_addFilter} onClick={handleCloseDialog}>
                            <FontAwesomeIcon icon="calendar" size="1x" className={classes.filterIcon} />
                        </button>
                    </div>
                </div>
            </div>
            <Modal isOpen={showDateTimeModal} contentLabel="Select Date" className={"modal"} overlayClassName={"overlay"} onRequestClose={handleCloseDialog}>
                <div className={classes.modalItem}>
                    {" "}
                    <ReactDatePicker selectionRange={selectionRange} handleSelect={handleSelect} />
                    <div className={classes.modalItem_button}>
                        <button className={classes.modalItem_button_confirm} onClick={handleConfirm}>
                            {t("eventsCategory.confirm")}
                        </button>
                        <button onClick={handleCloseDialog} className={classes.modalItem_button_cancel}>
                            {t("eventsCategory.cancel")}
                        </button>
                    </div>
                </div>
            </Modal>
            {allEvents && allEvents?.length > 0 ? (
                <>
                    <EventsItem1
                        allEvents={allEvents}
                        heading={categoryName}
                        organizationId={orgId}
                        // need to set prooperty to true so we can add calendar icon herej
                        hidetitle={true}
                        hideViewAll={hideViewAll}
                        settings={setting}
                        accessedbyAdmin={accessedbyAdmin}
                        styles={styles}
                        hideCarousel={hideCarousel}
                        accessedbySuperAdmin={accessedbySuperAdmin}
                    />
                </>
            ) : (
                <div className={classes.NoDataAvailable}>
                    <img src={getImageByKey("no_data_available")} alt="no_data_available" />
                </div>
            )}
        </>
    );
}
