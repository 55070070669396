import { useMyContext } from "../../contexts/StateHolder";
import { useEffect, useState, useRef, useCallback } from "react";
import settings from "../../config_settings.json";
import { useCookies } from "react-cookie";
import { useMyContextFunctions } from "../../contexts/ContextFunctions";
import { useParams } from "react-router";
import { findSubOrganizationSecret } from "../../scripts/dataHandlers";
import LivePlayerEvent from "./LivePlayerEvent";

const LiveVideoPlayer = (props) => {
    console.log("LiveVideoPlayer props", props);
    // Bring stateholders from context
    const { key, organizationId, liveChannelPrivate } = useMyContext();

    const [channelServiceId, setChannelServiceId] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [organizationID, setOrganizationID] = useState(null);
    const [secretKey, setSecretKey] = useState(null);

    const [cookies] = useCookies("");

    const { orgId } = useParams();

    const logoutTimerIdRef = useRef(null);
    let timer = useRef(null);
    const { checkForLiveStreamHandler } = props;

    const removeVideoPlayer = useCallback(() => {
        // this will check for serviceOrigin again and if the streaming and time has ended it means it will never reach this live video player
        checkForLiveStreamHandler && checkForLiveStreamHandler();
    }, [checkForLiveStreamHandler]);

    // removing video player page after 20 minutes of event time if nothing is being streamed
    useEffect(() => {
        if (props.event) {
            let eventDuration = props?.event?.durationPublic * 1000;
            logoutTimerIdRef.current = Date.now() - eventDuration;
            console.log("logoutTimerIdRef.current", logoutTimerIdRef.current);
            timer.current = setTimeout(() => {
                resetTimer();
                removeVideoPlayer();
            }, logoutTimerIdRef.current);
        }
        return () => {
            resetTimer();
        };
    }, [props.event]);

    // this resets the timer if it exists.
    const resetTimer = () => {
        if (timer.current) {
            clearTimeout(timer.current);
            logoutTimerIdRef.current = null;
            timer.current = null;
        }
    };

    useEffect(() => {
        // In case of live event player ->if the event belongs to the child organization and in the main page we always try to view the stream from parent organization->it might not work in this case ->cause we are passing secret and organiation of parent org instead of child ->here now in case of beta we do receive groupId and we can use this group id to find the organizationId
        if (organizationId) {
            // we get groupId from event object and we need to find organizationId from there

            let configGroupId = settings.organization.groupId * 1;

            if (props?.event) {
                if (props?.event?.groupId * 1 === configGroupId) {
                    // this means the event belong to parent organization
                    setGroupId(settings.organization.groupId);
                    setOrganizationID(organizationId);
                    setSecretKey(key);
                } else {
                    // this event does not belong to parent organization
                    setGroupId(props?.event?.groupId * 1);
                    setOrganizationID(props?.event?.groupId * 1 - 1);

                    const getAllSubOrganization = async () => {
                        let subKey = await findSubOrganizationSecret(organizationId, key, props?.event?.groupId * 1 - 1);
                        console.log("subKey", subKey);

                        setSecretKey(subKey?.secret);
                    };
                    getAllSubOrganization();
                }
                setChannelServiceId(props.channelServiceId || settings.organization.channelServiceId);

                setCompanyId(settings.organization.companyId);
            } else {
                // just assuming we dont get groupId from API  and this will fall if the event does not belong to parent org
                setGroupId(orgId ? orgId * 1 + 1 : settings.organization.groupId);
                setChannelServiceId(props.channelServiceId || settings.organization.channelServiceId);
                // setServiceOriginState(0); // TODO?
                setCompanyId(settings.organization.companyId);
                setOrganizationID(orgId ? orgId : organizationId);
                if (orgId * 1 !== organizationId * 1) {
                    const getAllSubOrganization = async () => {
                        let subKey = await findSubOrganizationSecret(organizationId, key, orgId);

                        setSecretKey(subKey?.secret);
                    };
                    getAllSubOrganization();
                } else {
                    setSecretKey(key);
                }
            }
        }
    }, [organizationId, props.channelServiceId, orgId, key, props.event]);

    return key && channelServiceId && companyId ? (
        <div className={props.showInAdminpage ? "smallContainerprimary" : "maxContainerPrimary"}>
            {/* // live player event is for live player with event -> digital is lil differnt cause that is not an event */}
            <LivePlayerEvent
                companyId={companyId}
                groupId={groupId} // Group of organization
                organizationId={organizationID} // Id of organization
                playerAutoStart={props.playerAutoStart}
                secretKey={secretKey} // Secret key for organization
                channelServiceId={channelServiceId} // channelServiceId for live video
                serviceOriginState={props?.serviceOriginState}
                backRoute={props.backRoute} // Route, where back button takes user. If undefined, it takes automatically to previous route
                //key={assetId} // For react's re-rendering purposes
                userId={liveChannelPrivate ? cookies.userData.userId : 0}
                // to check if the live player is playing live events , we pass as true if it  is playing live events ffor sending different analytics
                eventPlayer={props.eventPlayer}
                eventId={props.eventId}
                // if it is an event we can pass the event here and later take all the teams and organization value to pass to mux data
                event={props?.event}
            />
        </div>
    ) : null;
};

export default LiveVideoPlayer;
