import icareus_event_default from "../images/events_page_default_image.jpeg";
import channelBannerDemo from "../images/channelBannerDemo.jpeg";
import no_data_available from "../images/no_data_available.jpeg";
import no_channels_available from "../images/no_channels_available.svg";
import subOrganization_default_image from "../images/bannerdemo_image1.jpeg";

import comingSoonThumbnail from "../images/comingSoonThumbnail.jpg";
import comingSoonThumbnailSerie from "../images/comingSoonThumbnailSerie.jpg";
import contactus from "../images/defaultImage/contactus.svg";

import paymentMethodBambora from "../images/paymentMethods/payment-options-bambora.png";
import paymentMethodVoucher from "../images/paymentMethods/payment-options-voucher.png";
import logo_organization from "../images/defaultImage/logo_organization.png";

import admin_dashboard from "../images/admin_dashboard.svg";
import admin_users_dashboard from "../images/admin_users_dashboard.svg";

import admin_analytics from "../images/admin_analytics.svg";

import suborgdemobanner from "../images/suborgdemobanner.png";

// import defaultClubLogo from "../images/defaultImage/salibandytv_default_logo.png";
import defaultClubLogo from "../images/defaultImage/logo_organization.png";

import settings from "../images/visma_settings.svg";
import header_navbar from "../images/defaultImage/header_navbar.png";
import salibandytv_logo_navbar_header from "../images/defaultImage/salibandytv_logo_navbar_header.png";
const images = {
  icareus_event_default,
  channelBannerDemo,
  no_data_available,
  no_channels_available,
  subOrganization_default_image,
  comingSoonThumbnail,
  comingSoonThumbnailSerie,
  contactus,
  paymentMethodBambora,
  paymentMethodVoucher,
  logo_organization,
  admin_dashboard,
  admin_users_dashboard,
  admin_analytics,
  suborgdemobanner,
  defaultClubLogo,
  settings,
  header_navbar,
  salibandytv_logo_navbar_header,
};

const getImageByKey = (key) => {
  return images[key];
};

export { getImageByKey };
