import React from "react";
import Button from "../Button/Button";
import { Input1 } from "../Input1/Input1";

export default function Modal({ buttonText1, button1OnClick, buttonText2, button2OnClick, text, childElement }) {
    return (
        <div className={"copy-container active regular "}>
            <div className={"copy-popup active scale-up-center"}>
                <div
                    className="font-600 display-flex-center"
                    style={{
                        flexDirection: "column",
                        paddingTop: "50px",
                    }}
                >
                    <div>{text ? text : "Are you sure you want to delete"}</div>
                    {childElement && childElement}

                    <div
                        className="display-flex-center"
                        style={{
                            gap: "10px",
                            paddingTop: "50px",
                        }}
                    >
                        <Button formButton onClick={button1OnClick} danger>
                            {buttonText1}
                        </Button>
                        <Button formButton inverse onClick={button2OnClick}>
                            {buttonText2}
                        </Button>
                    </div>
                    <br />
                </div>
            </div>
        </div>
    );
}
