import React, { useEffect, useState } from "react";
import { useMyContext } from "../../../contexts/StateHolder";
import { useParams } from "react-router";
import {
  deleteGame,
  findSubOrganizationSecret,
  getSingleGame,
} from "../../../scripts/dataHandlers";
import Loader from "react-loader-spinner";
import LiveVideoPlayer from "../../VideoPlayer/LiveVideoPlayer";
import * as classes from "../../../Shared/Input1/Input1.module.css";

import Header from "../../../Shared/Header/Header";
import { VALIDATOR_REQUIRE } from "../../../Shared/Validation/Validator";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../Shared/Hooks/form-hook";
import { Input1 } from "../../../Shared/Input1/Input1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertDuration, momentDate } from "../../../scripts/utils";
import Button from "../../../Shared/Button/Button";
import moment from "moment";
import * as classes1 from "./AdminEvent.module.css";
import * as settings from "../../../config_settings.json";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useCallback } from "react";
import ScoreBoard from "../../../pages/ScoreBoard";
import useWindowDimensions from "../../WindowDimension";

const ParentScoreBoard = ({
  singleEvent,
  scoreboardEnabled,
  setScoreboardEnabled,
  mobileView,
}) => {
  return (
    <ScoreBoard
      homeTeamName={singleEvent?.homeTeamName}
      awayTeamName={singleEvent?.awayTeamName}
      serviceId={singleEvent?.event?.publishingInfo?.serviceId}
      scoreboardEnabled={scoreboardEnabled}
      setScoreboardEnabled={setScoreboardEnabled}
      mobileView={mobileView}
    />
  );
};
export default function AdminEvent({ accessedbySuperAdmin }) {
  const { singleEventItem, user } = useMyContext();
  const [singleEvent, setSingleEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [cookies] = useCookies([" "]);

  const windowDimension = useWindowDimensions();
  console.log("windowDimension", windowDimension);

  // scoreboard enbled or not

  const [scoreboardEnabled, setScoreboardEnabled] = useState(false);

  const { orgId, eventId } = useParams();
  const { t } = useTranslation();

  const history = useHistory();
  const [isLiveEvent, setIsLiveEvent] = useState(false);

  const checkForLiveEvent = useCallback((event) => {
    let currentTime = Date.now();
    let totalEndTime;
    if (event?.startTimePublic) {
      totalEndTime = event?.startTimePublic + event?.durationPublic * 1000;
    } else {
      totalEndTime = event?.startTime + event?.duration * 1000;
    }
    if (
      currentTime >=
        (event?.startTimePublic ? event?.startTimePublic : event?.startTime) &&
      currentTime < totalEndTime
    ) {
      console.log(currentTime);
      setTimeout(() => {
        setIsLiveEvent(true);
      }, 100);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (orgId && eventId) {
      if (singleEventItem?.event) {
        setSingleEvent(singleEventItem);
        setDefaultLanguage(singleEventItem?.defaultLanguage);
        checkForLiveEvent(singleEventItem);

        setLoading(false);
      } else {
        const getData = async () => {
          const res = await getSingleGame(
            orgId,
            eventId,
            cookies?.userData?.userToken
          );
          setSingleEvent(res?.game);
          setDefaultLanguage(res?.game?.defaultLanguage || "en_US");

          checkForLiveEvent(res?.game?.event);
        };
        cookies?.userData?.userToken && getData();
      }
    }
  }, [singleEventItem, orgId, eventId]);

  // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform
  const [state, InputHandler] = useForm(
    {
      JOUKKUE: {
        value: "",
        isValid: false,
      },
      STREAMING_OSITE: {
        value: "",
        isValid: true,
      },
      STREAM_KEY: {
        value: "",
        isValid: true,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );

  const deleteSingleEvent = async (eventId) => {
    console.log(eventId);
    let secret = await findSubOrganizationSecret(
      settings.organization.organizationId * 1,
      settings.organization.key,
      orgId * 1
    );
    console.log("secret", secret);
    if (secret) {
      console.log(secret);
      const res = await deleteGame(
        orgId,
        eventId,
        cookies?.userData?.userToken
      );

      console.log(res, "delete event");
      if (res?.data?.status === "ok") {
        toast.success(t("addNewEvent.deleteSuccess"));

        setTimeout(() => {
          // this might always not be convenient way to go back
          // history.goBack(); so got to create page
          history.push("/admin/addNewEvent");
        }, 1000);
      } else {
        toast.error("Something Went Wrong.Please try Later");
      }
    }
  };

  return !loading && singleEvent ? (
    <>
      <div className="loginViewContainer">
        <div
          className={`${classes.formPrimary} ${classes.formScoreboard}`}
          style={{
            flexDirection: scoreboardEnabled ? "row" : "column-reverse",
          }}
        >
          <div
            className={classes.formSecondary}
            style={{
              flex: 1,
            }}
          >
            <div className={`${classes.formBlogAdmin} ${classes.FormAdmin}`}>
              <Header
                title={
                  singleEvent?.name[defaultLanguage ? defaultLanguage : "en_US"]
                }
                extraClassname
                admin={true}
                color="black"
              />
              {/* // need to check here whether game is live or not */}
              {isLiveEvent && (
                <div className={classes1.videoScoreBoard}>
                  <LiveVideoPlayer
                    channelServiceId={
                      singleEvent?.event?.publishingInfo?.serviceId
                    }
                    eventPlayer={true}
                    eventId={singleEvent.eventId}
                    showInAdminpage={true}
                    serviceOriginState={1}
                    event={singleEvent}
                  />

                  {/* {windowDimension.width <= 950 && (
                    <div className={classes1.ScoreBoardMobile}>
                      <ParentScoreBoard
                        singleEvent={singleEvent}
                        homeTeamName={singleEvent?.homeTeamName}
                        awayTeamName={singleEvent?.awayTeamName}
                        serviceId={
                          singleEvent?.event?.publishingInfo?.serviceId
                        }
                        scoreboardEnabled={scoreboardEnabled}
                        setScoreboardEnabled={setScoreboardEnabled}
                        mobileView={true}
                      />
                    </div>
                  )} */}
                </div>
              )}

              <div className={`${classes1.EventTimeDescription} smallcasebold`}>
                <div className={`${classes1.EventPrimary_date} font-300`}>
                  <div>
                    <FontAwesomeIcon icon="calendar" />
                  </div>
                  <div>
                    {momentDate(
                      singleEvent?.startTimePublic
                        ? singleEvent?.startTimePublic
                        : singleEvent?.startTime
                    )}
                  </div>
                </div>
                <div className={`${classes1.EventPrimary_time} font-300`}>
                  <div>
                    {" "}
                    <FontAwesomeIcon icon="clock" />
                  </div>
                  <div>
                    {moment(
                      singleEvent?.startTimePublic
                        ? singleEvent?.startTimePublic
                        : singleEvent?.startTime
                    )
                      .locale("fi")
                      .format("LT")}
                  </div>{" "}
                </div>
                <div className={`${classes1.EventPrimary_time} font-300`}>
                  <div>
                    {" "}
                    <FontAwesomeIcon icon="hourglass-start" />
                  </div>
                  <div>
                    {convertDuration(
                      singleEvent?.durationPublic
                        ? singleEvent?.durationPublic
                        : singleEvent?.duration
                    )}
                  </div>{" "}
                </div>
              </div>
              <div className={`${classes1.EventPrimary_time} font-300`}>
                <div>
                  {" "}
                  <FontAwesomeIcon icon="link" />
                </div>

                <Link
                  to={{
                    pathname: `/event/${user?.organizationId * 1}/${
                      singleEvent?.eventId
                    }`,
                  }}
                >
                  {t("adminPastEvent.recordingPage")}
                </Link>
              </div>

              {singleEvent?.event?.recordings?.length > 0 &&
                singleEvent?.event?.recordings?.map((rec) => (
                  <Input1
                    id="STREAMING_OSITE"
                    label={t("adminPastEvent.presetFileUrl")}
                    placeholder={t("loginForm.askForPassword")}
                    type="text"
                    element="input"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText={t("loginForm.wrongPassword")}
                    onInput={InputHandler}
                    initialValue={rec.presetFileUrl}
                    disabled
                    style={{ background: "#ead4d4" }}
                    changeButton={true}
                    button_label={t("addNewEvent.copy")}
                    key={rec?.assetId}
                  />
                ))}

              {isLiveEvent && (
                <>
                  <Input1
                    id="STREAMING_OSITE"
                    label={t("addNewEvent.streamingAddress")}
                    placeholder={t("loginForm.askForPassword")}
                    type="text"
                    element="input"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText={t("loginForm.wrongPassword")}
                    onInput={InputHandler}
                    initialValue={singleEvent?.event?.publishingInfo?.ingestUrl}
                    disabled
                    style={{ background: "#ead4d4" }}
                    changeButton={true}
                    button_label={t("addNewEvent.copy")}
                  />{" "}
                  <Input1
                    id="STREAM_KEY"
                    label={t("addNewEvent.streamKey")}
                    placeholder={t("loginForm.askForPassword")}
                    type="text"
                    element="input"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText={t("loginForm.wrongPassword")}
                    onInput={InputHandler}
                    initialValue={singleEvent?.event?.publishingInfo?.serviceId}
                    disabled
                    style={{ background: "#ead4d4" }}
                    changeButton={true}
                    button_label={t("addNewEvent.copy")}
                  />
                </>
              )}
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {/* // take org from cookies if it is superadmin */}
                <Button
                  to={`/admin/addNewEvent/${
                    accessedbySuperAdmin
                      ? cookies?.supAdOrg
                      : user.organizationId * 1
                  }/${singleEvent?.gameId}`}
                  inverse
                >
                  {t("addNewEvent.edit")}
                </Button>
                <Button
                  onClick={() => deleteSingleEvent(singleEvent?.gameId)}
                  danger
                >
                  {t("addNewEvent.delete")}
                </Button>
              </div>
            </div>
          </div>

          {/* {isLiveEvent && windowDimension.width > 950 && (
            <div
              style={{
                flex: 1,
              }}
              className={classes1.ScoreBoardDesktop}
            >
              <ParentScoreBoard
                singleEvent={singleEvent}
                homeTeamName={singleEvent?.homeTeamName}
                awayTeamName={singleEvent?.awayTeamName}
                serviceId={singleEvent?.event?.publishingInfo?.serviceId}
                scoreboardEnabled={scoreboardEnabled}
                setScoreboardEnabled={setScoreboardEnabled}
              />
            </div>
          )} */}
        </div>
      </div>
    </>
  ) : (
    <div className="display-flex-center">
      {" "}
      <Loader type="TailSpin" color="#161eaf" />{" "}
    </div>
  );
}
