import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./Shared/FontAwesomeIcon.js";

import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useMyContext } from "./contexts/StateHolder";
import ReactGA from "react-ga";
import { useCookies } from "react-cookie";

import settings from "./config_settings.json";
import BottomBar from "./components/Footers/BottomBar";
import styles from "./config_demo.json";

import ChosenCategoryGrid from "./components/ViewAssets/Grids/ChosenCategoryGrid";

import AddNewSuborganization from "./components/AddNewSuborganization/AddNewSuborganization";

import DetailsSerie from "./components/Details/DetailsSerie";

import ContactUs from "./components/ContactUs/ContactUs";

import PastEventsCategoryItem from "./components/Events/EventsCategory/PastEventsCategoryItem";
import UpcomingEventsCategoryItem from "./components/Events/EventsCategory/UpcomingEventsCategoryItem";
import LiveNowEventsCategoryItem from "./components/Events/EventsCategory/LiveNowEventsCategoryItem";

import UpcomingEvent from "./components/Events/Event/UpcomingEvent";
import UpcomingEvents from "./components/Events/EventsCategory/UpcomingEvents";

import LiveNowEvents from "./components/Events/EventsCategory/LiveNowEvents";

import PastEvents from "./components/Events/EventsCategory/PastEvents";

import EventsCategory from "./components/Events/EventsCategory/EventsCategory";

import Banner from "./components/ViewAssets/Sliders/Banner";

import RenderCategoryDropdown from "./components/DropDowns/CategoryDropdown";
import PurchaseFlow from "./components/Packages/PurchaseFlow";
import { useTranslation } from "react-i18next";
import TOS from "./components/TOS/TOS";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import DetailsVideo from "./components/Details/DetailsVideo";
import AssetVideoPlayer from "./components/VideoPlayer/AssetVideoPlayer";
import LoginForm from "./components/Login/LoginForm";
import SignUpForm from "./components/SignUp/SignUpForm";
import UserDetails from "./components/Profile/UserDetails/UserDetails";
import ChangePassword from "./components/Profile/ChangePassword/ChangePassword";
// import LanguageSelect1 from './components/LanguageSelect/LanguageSelect1';

// react date time range css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// toastify
import "react-toastify/dist/ReactToastify.css";
import "react-multi-carousel/lib/styles.css";

import Dashboard from "./components/Admin/Dashboard/Dashboard";
import ListAllSubOrg from "./components/ListAllSubOrg/ListAllSubOrg";
import DashboardMainPage from "./components/Admin/DashboardMainPage/DashboardMainPage";
import CurrentSubscription from "./components/Profile/Subscriptions/CurrentSubscription";

import AdminUsersDashboard from "./components/Admin/AdminUsersDashboard/AdminUsersDashboard";
import AdminUsers from "./components/Admin/AdminUsers/AdminUsers";
import Analytics from "./components/Admin/Analytics/Analytics";
import RenderBanner2 from "./components/ViewAssets/Sliders/RenderBanner1/RenderBanner2";
import GamesAnalytics from "./components/Analytics/GamesAnalytics";
import AboutUs from "./components/AboutUs/AboutUs";
import SearchEvent from "./components/Search/SearchEvent/SearchEvent";
import AddNewEvent from "./components/Admin/NewEvent/AddNewEvent";
import RegisterConfirmation from "./pages/RegisterConfirmation";
import RequestResetPassword from "./pages/RequestResetPassword";
import RequestResetAdminPassword from "./pages/RequestResetAdminPassword";
import Settings from "./components/Admin/Settings/Settings";
import VismaSettings from "./components/Admin/Settings/Visma/VismaSettings";
import ChangeOrganization from "./pages/ChangeOrganization";
import Loader from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import ScoreBoard from "./pages/ScoreBoard";
import AdminEvent from "./components/Admin/AdminEvent/AdminEvent";
import HeaderNavBar from "./components/NavBars/HeaderNavBar/HeaderNavBar";

if (settings.googleTrackingId !== "") {
  // Initialize Google analytics with API-key from .env file // TODO
  ReactGA.initialize(settings.googleTrackingId);

  // we are sending it to icareus as well
  ReactGA.initialize("");
}

function App() {
  // Bring stateholders from context
  const {
    setKey,

    setOrganizationId,

    setLanguage,
    setAllLanguages,
    user,
    setUser,
    userLoggedIn,
  } = useMyContext();

  // organizationSecret
  const location = useLocation();

  const [cookies] = useCookies(["osec"]);
  const { t } = useTranslation();

  const [isAdmin, setIsAdmin] = useState(user?.admin);
  const [authLoaded, setAuthLoaded] = useState(false);

  useEffect(() => {
    async function initProgram() {
      try {
        const getOrganization = settings.organization;

        setOrganizationId(getOrganization.organizationId);

        setKey(getOrganization.key);

        const getLanguage = settings.language;

        setLanguage(getLanguage);
        setAllLanguages(
          Object.keys(settings.languages).map((k) => settings.languages[k])
        );
      } catch (err) {
        console.log(err);
      }
    }

    initProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cookies?.userData?.userToken || userLoggedIn) {
      if (!user?.loggedIn && cookies?.userData?.userToken) {
        console.log(user);
        let modifiedUser = { ...user };

        // we will fill data from cookie if it is not there

        modifiedUser.loggedIn = true;

        modifiedUser.userId = cookies?.userData?.userId;

        modifiedUser.userToken = cookies?.userData?.userToken;

        modifiedUser.admin = cookies?.userData?.admin;
        modifiedUser.firstName = cookies?.userData?.firstName;

        modifiedUser.organizationId = cookies?.userData?.userOrganizationId;
        modifiedUser.userSecret = cookies?.userData?.userSecret;
        modifiedUser.eMail = cookies?.ue;
        modifiedUser.organizationName = cookies?.userData?.userOrganizationName;
        modifiedUser.superAdmin = cookies?.userData?.superAdmin;

        setUser(modifiedUser);
      }

      // getiing package of parent org ->- we need to match these later to check the event packages

      setIsAdmin(true);
      setAuthLoaded(true);
    } else {
      setIsAdmin(false);
      setAuthLoaded(true);
    }
  }, [cookies?.userData?.userToken, userLoggedIn]);

  // UseEffect that will re-trigger on every pagechange, sending current location to Google Analytics
  useEffect(() => {
    if (settings.googleTrackingId !== "") {
      // Report Google Analytics about user entering this page
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [location]);

  return authLoaded ? (
    <>
      <ToastContainer />
      <div className="App regular">
        <HeaderNavBar />
        <div className=" container ">
          <Switch>
            <Route path="/" exact>
              <Banner
                settings={settings.components.frontPageBanner_01}
                styles={styles}
              />

              <div style={{ background: "white", paddingBottom: "20px" }}>
                <div className="main-container">
                  <LiveNowEvents
                    organizationId={settings.organization.organizationId}
                    homepageContainer={true}
                    settings={settings.components.eventsListing}
                  />
                  <UpcomingEvents
                    organizationId={settings.organization.organizationId}
                    settings={settings.components.eventsListing}
                    homepageContainer={true}
                  />

                  <PastEvents
                    organizationId={settings.organization.organizationId}
                    settings={settings.components.eventsListingPast}
                    homepageContainer={true}
                    isPastEvents={true}
                    heading={t("adminSideBar.pastEvents")}
                  />
                </div>
              </div>
            </Route>
            <Route path="/clubs/:organizationName/:subOrganizationId">
              <div style={{ background: "white", paddingBottom: "20px" }}>
                <RenderBanner2 image="suborgdemobanner" />
                <div className="main-container">
                  <LiveNowEvents
                    organizationId={settings.organization.organizationId}
                    homepageContainer={true}
                    settings={settings.components.eventsListing}
                  />
                  <UpcomingEvents
                    organizationId={settings.organization.organizationId}
                    settings={settings.components.eventsListing}
                    homepageContainer={true}
                  />
                  <PastEvents
                    organizationId={settings.organization.organizationId}
                    settings={settings.components.eventsListingPast}
                    homepageContainer={true}
                    isPastEvents={true}
                    heading={t("adminSideBar.pastEvents")}
                  />
                </div>
              </div>
            </Route>
            <Route path="/clubs">
              <ListAllSubOrg setting={settings.components.ListAllSubOrg} />
            </Route>
            <Route
              path={`/${settings.routes.packages}/:orgId/:itemType?/:itemId?`}
              exact
            >
              {!user?.loggedIn && (
                <LoginForm
                  routes={settings.routes}
                  redirect={false}
                  showBgImage={false}
                  redirectRoute={"/"}
                />
              )}
              <PurchaseFlow settings={settings.components.purchaseflow} />
            </Route>
            <Route path={`/${settings.routes.addNewSub}`} exact>
              <AddNewSuborganization />
            </Route>
            {/* // admin accceess */}
            {isAdmin && (
              <Route path={`/${settings.routes.admin}`} exact>
                <Dashboard admin={true}>
                  <DashboardMainPage />
                </Dashboard>
              </Route>
            )}
            {isAdmin && (
              <Route
                path={`/${settings.routes.adminAddNewEvent}/:orgId?/:eventId?`}
                exact
              >
                <Dashboard admin={true}>
                  <AddNewEvent />
                </Dashboard>
              </Route>
            )}
            {isAdmin && (
              <Route path={`/${settings.routes.adminLiveEvents}`} exact>
                <Dashboard admin={true}>
                  <LiveNowEvents
                    organizationId={user?.organizationId}
                    hideViewAll={true}
                    styles={{
                      color: "black",
                    }}
                    // accessedbyAdmin will be true when they are clicked from admin page
                    accessedbyAdmin={true}
                    extraClassname
                    settings={settings.components.adminPageUpcomimgEvents}
                    categoryIdProps={"liveEvent"}
                    categoryNameProps={"liveEvent"}
                  />
                </Dashboard>
              </Route>
            )}
            {/* "events/:categoryName/:organizationId/:categoryId", */}
            {isAdmin && (
              <Route path={`/${settings.routes.adminUpcomingEvents}`} exact>
                <Dashboard admin={true}>
                  <UpcomingEvents
                    organizationId={user?.organizationId}
                    hideViewAll={true}
                    extraClassname
                    styles={{
                      color: "black",
                    }}
                    // accessedbyAdmin will be true when they are clicked from admin page
                    accessedbyAdmin={true}
                    accessedbySuperAdmin={user?.superAdmin ? true : false}
                    settings={settings.components.adminPageUpcomimgEvents}
                    categoryIdProps={"upcomingEvent"}
                    categoryNameProps={"upcomingEvent"}
                  />
                </Dashboard>
              </Route>
            )}
            {/* // past event which needs to be shown in admin panel */}
            {isAdmin && (
              <Route path={`/${settings.routes.adminPastEvents}`} exact>
                <Dashboard admin={true}>
                  <PastEvents
                    organizationId={user?.organizationId}
                    hideViewAll={true}
                    extraClassname
                    styles={{
                      color: "black",
                    }}
                    // accessedbyAdmin will be true when they are clicked from admin page
                    accessedbyAdmin={true}
                    settings={settings.components.adminPagePastEvents}
                    categoryIdProps={"pastEvent"}
                    categoryNameProps={"PastEvent"}
                  />
                </Dashboard>
              </Route>
            )}
            <Route path={`/${settings.routes.event}`} exact>
              <UpcomingEvent routes={settings.routes} />
            </Route>
            <Route path={`/${settings.routes.upcomingEvent}`} exact>
              <UpcomingEvent routes={settings.routes} />
            </Route>
            <Route path={`/${settings.routes.liveNowEvent}`} exact>
              <UpcomingEvent routes={settings.routes} />
            </Route>
            <Route path={`/${settings.routes.pastEvent}`} exact>
              <UpcomingEvent routes={settings.routes} />
            </Route>

            {/* // when single event is clicked by admin from admin panel */}
            {isAdmin && (
              <Route path={`/${settings.routes.adminUpcomingEvent}`} exact>
                <Dashboard admin={true}>
                  <AdminEvent
                    accessedbySuperAdmin={user?.superAdmin ? true : false}
                  />
                </Dashboard>
              </Route>
            )}
            {isAdmin && (
              <Route path={`/${settings.routes.adminLiveNowEvent}`} exact>
                <Dashboard admin={true}>
                  <AdminEvent
                    accessedbySuperAdmin={user?.superAdmin ? true : false}
                  />
                </Dashboard>
              </Route>
            )}
            {isAdmin && (
              <Route path={`/${settings.routes.adminEvent}`} exact>
                <Dashboard admin={true}>
                  <AdminEvent
                    accessedbySuperAdmin={user?.superAdmin ? true : false}
                  />
                </Dashboard>
              </Route>
            )}
            {isAdmin && (
              <Route path={`/${settings.routes.adminUsersDashboard}`} exact>
                <Dashboard admin={true}>
                  <AdminUsersDashboard />
                </Dashboard>
              </Route>
            )}
            {isAdmin && (
              <Route path={`/${settings.routes.settings}`} exact>
                <Dashboard admin={true}>
                  <Settings />
                </Dashboard>
              </Route>
            )}
            {isAdmin && (
              <Route path={`/${settings.routes.settingsVisma}`} exact>
                <Dashboard admin={true}>
                  <VismaSettings
                    accessedbySuperAdmin={user?.superAdmin ? true : false}
                  />
                </Dashboard>
              </Route>
            )}
            {isAdmin && (
              <Route
                path={`/${settings.routes.settingsChangeOrganization}`}
                exact
              >
                {/* <Dashboard admin={true}> */}
                <ChangeOrganization />
                {/* </Dashboard> */}
              </Route>
            )}
            {isAdmin && (
              <Route path={`/${settings.routes.adminUsersList}`} exact>
                <Dashboard admin={true}>
                  <AdminUsers />
                </Dashboard>
              </Route>
            )}
            {isAdmin && (
              <Route path={`/${settings.routes.adminUsersList}/:userId`} exact>
                <Dashboard admin={true}>
                  <UserDetails
                    fields={settings.components.profile.fields}
                    admin={true}
                    accessedbySuperAdmin={user?.superAdmin ? true : false}
                  />
                </Dashboard>
              </Route>
            )}
            {/* adminUsersChangepassword */}
            {/* admin pwd change by other admin */}
            <Route
              path={`/${settings.routes.adminUsersChangepassword}/:userId`}
              exact
            >
              <Dashboard admin={true}>
                <ChangePassword
                  admin={true}
                  accessedbySuperAdmin={user?.superAdmin ? true : false}
                />
              </Dashboard>
            </Route>
            {/* create new admin  */}
            {/* adminUserCreate */}
            {isAdmin && (
              <Route path={`/${settings.routes.adminUserCreate}`}>
                <Dashboard admin={true}>
                  <SignUpForm
                    routes={settings.routes}
                    settings={settings.components.signUp}
                    admin={true}
                    superAdmin={user?.superAdmin ? true : false}
                  />
                </Dashboard>
              </Route>
            )}
            {/* analytics */}
            {isAdmin && (
              <Route path={`/${settings.routes.adminAnalytics}`} exact>
                <Dashboard admin={true}>
                  <Analytics />
                </Dashboard>
              </Route>
            )}
            {/* analytics */}
            {isAdmin && (
              <Route path={`/${settings.routes.adminAnalyticsGames}`} exact>
                <Dashboard style={{ padding: 0, marginTop: 0 }} admin={true}>
                  <GamesAnalytics
                    accessedbySuperAdmin={user?.superAdmin ? true : false}
                  />
                </Dashboard>
              </Route>
            )}

            {/* // user access */}
            <Route path={`/${settings.routes.changePassword}`} exact>
              <Dashboard>
                <ChangePassword />
              </Dashboard>
            </Route>
            <Route path={`/${settings.routes.userDetails}`} exact>
              <Dashboard>
                <UserDetails fields={settings.components.profile.fields} />
              </Dashboard>
            </Route>
            <Route path={`/${settings.routes.subscription}`} exact>
              <Dashboard>
                <CurrentSubscription />
              </Dashboard>
            </Route>

            <Route
              path={`/${settings.routes.svodCategoriesRoute}/:asset`}
              exact
            >
              <RenderCategoryDropdown
                styles={styles}
                route={settings?.routes.svodCategoriesRoute}
              />
              <ChosenCategoryGrid
                styles={styles}
                settings={settings.components.svodChosenCategoryGrid_01}
                imageType={"thumbnailSerie"}
                showDuration={false}
                showReleaseYear={true}
                showPublishedDate={true}
                hideDescription={true}
              />
            </Route>
            <Route path={`/${settings.routes.tos}`}>
              <TOS />
            </Route>
            <Route path={`/${settings.routes.privacyPolicy}`}>
              <PrivacyPolicy />
            </Route>

            <Route path={`/${settings.routes.upcomingEvents}`} exact>
              <div style={{ background: "white" }}>
                <RenderBanner2 image="suborgdemobanner" />
              </div>
              <div style={{ background: "white", paddingBottom: "20px" }}>
                <div className="main-container">
                  <UpcomingEventsCategoryItem
                    settings={settings.components.eventsListing}
                    setting={settings.components.eventsListing}
                    hideViewAll={true}
                    hideCarousel
                  />
                </div>
              </div>
            </Route>

            <Route path={`/${settings.routes.liveNowEvents}`} exact>
              <div style={{ background: "white" }}>
                <RenderBanner2 image="suborgdemobanner" />
              </div>
              <div style={{ background: "white", paddingBottom: "20px" }}>
                <div className="main-container">
                  <LiveNowEventsCategoryItem
                    settings={settings.components.eventsListing}
                    setting={settings.components.eventsListing}
                    hideViewAll={true}
                    hideCarousel
                  />
                </div>
              </div>
            </Route>

            <Route path={`/${settings.routes.pastEvents}`} exact>
              <div style={{ background: "white" }}>
                <RenderBanner2 image="suborgdemobanner" />
              </div>
              <div style={{ background: "white", paddingBottom: "20px" }}>
                <div className="main-container">
                  <PastEventsCategoryItem
                    settings={settings.components.eventsListing}
                    setting={settings.components.eventsListing}
                    hideViewAll={true}
                    hideCarousel
                  />
                </div>
              </div>
            </Route>

            {/* Events main page */}
            <Route path={`/${settings.routes.events}`} exact>
              <div style={{ background: "white", paddingBottom: "20px" }}>
                <div className="main-container">
                  <LiveNowEvents
                    organizationId={settings.organization.organizationId}
                    settings={settings.components.eventsListing}
                  />
                  <UpcomingEvents
                    organizationId={settings.organization.organizationId}
                    settings={settings.components.eventsListing}
                  />
                  <PastEvents
                    organizationId={settings.organization.organizationId}
                    settings={settings.components.eventsListingPast}
                    homepageContainer={true}
                    isPastEvents={true}
                    heading={t("adminSideBar.pastEvents")}
                  />
                </div>
                <div className="main-container">
                  <EventsCategory
                    organizationId={settings.organization.organizationId}
                    settings={settings.components.eventsListing}
                  />
                </div>
              </div>
            </Route>

            <Route path={`/${settings.routes.videoRoute}/:orgId/:asset`} exact>
              <AssetVideoPlayer backRoute={"/"} playerAutoStart={true} />

              <DetailsVideo
                styles={settings.styles}
                routes={settings.routes}
                hideBanner={true}
                hideInfoData={true}
                showPublishedDate={true}
              />
            </Route>
            <Route path={`/${settings.routes.svodSerieRoute}/:asset`} exact>
              <DetailsSerie styles={settings.styles} />
            </Route>
          </Switch>
          {/* ROUTE FOR LOGIN VIEW*/}
          <Route path={`/${settings.routes.login}/:admin?/:superAdmin?`}>
            <LoginForm routes={settings.routes} />
          </Route>
          {/* ROUTE FOR SIGNUP VIEW*/}
          <Route path={`/${settings.routes.signUp}`}>
            <SignUpForm
              routes={settings.routes}
              settings={settings.components.signUp}
            />
          </Route>
          <Route path={`/${settings.routes.contactus}`}>
            <ContactUs />
          </Route>
          {/* ROUTE FOR SEARCH VIEW*/}
          <Route path={`/${settings.routes.search}`}>
            <div
              style={{
                padding: "20px 0 50px 0",
                background: "white",
                flex: 1,
              }}
            >
              <div className="main-container">
                <SearchEvent settings={settings.components.searchEvent} />
              </div>
            </div>
          </Route>
          <Route path={`/${settings.routes.aboutUs}`}>
            <AboutUs />
          </Route>
          <Route path={`/${settings.routes.registerConfirmation}`}>
            <div className="main-container">
              <RegisterConfirmation />
            </div>
          </Route>

          <Route path={`/${settings.routes.requestResetPassword}/:token?`}>
            <div className="main-container" style={{ flex: 1 }}>
              <RequestResetPassword />
            </div>
          </Route>
          <Route path={`/${settings.routes.requestResetAdminPassword}/:token?`}>
            <div className="main-container" style={{ flex: 1 }}>
              <RequestResetAdminPassword />
            </div>
          </Route>
          <Route path={`/score`}>
            <div className="main-container" style={{ flex: 1 }}>
              <ScoreBoard />
            </div>
          </Route>

          <BottomBar routes={settings.routes} />
        </div>
      </div>
    </>
  ) : (
    <div className="display-flex-center">
      {" "}
      <Loader type="TailSpin" color="#161eaf" />{" "}
    </div>
  );
}

export default App;
