import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import { useMyContext } from "../contexts/StateHolder";
import { useHistory } from "react-router-dom";

import { authenticateUser, registerUser, registerUserWithRoles } from "../scripts/dataHandlers";

import * as settings from "../config_settings.json";

// First create the context to hold shared functions
const MyContextFunctions = React.createContext();

// Then create a ContextFunctions wrapper component
// to hold the shared functions that the components need.
const ContextFunctions = (props) => {
    // Bring stateholders from another context
    const { organizationId, setUser, setUserLoggedIn, setGoBackToPrevious, UILoading, setUILoading } = useMyContext();

    const [cookies, setCookie] = useCookies("");
    const history = useHistory();

    const authProcess = async (email, password, role, adminOrgId) => {
        try {
            console.log(email, password, role, adminOrgId);
            setUILoading(true);
            const authResponse = await authenticateUser(
                // we will only get suborgId when their is 2 suborganization with multiple user and user have selected from dropdown
                role?.includes("admin") && adminOrgId ? adminOrgId : settings.organization.organizationId,

                email,
                password,
                role
            );

            if (authResponse.data.status === "error") {
                // Give error message to user
            } else if (authResponse.data.status === "ok") {
                // here we also need to check if their are multiple organization with same email and same password

                if (authResponse?.data?.authentications && authResponse?.data?.authentications.length > 1) {
                    // we will not allow to login user and they must select their organizationId and we will show the dropdown to user of that organizationId
                    setUILoading(false);

                    return authResponse;
                }

                let newUser = {
                    userId: authResponse.data.user_id,
                    userToken: authResponse.data.user_token,
                    firstName: authResponse.data.user_name,
                    eMail: email,
                    loggedIn: true,
                    organizationId: authResponse.data.user_organization_id,
                    userSecret: authResponse.data.user_secret,
                    admin: role?.includes("admin") || role?.includes("Admin") ? true : false,
                    organizationName: authResponse.data.user_organization_name,
                    superAdmin: role === "Affiliate Administrator" ? true : false,
                };

                // Update user state
                setUser(newUser);

                setCookie(
                    "userData",
                    {
                        userId: authResponse.data.user_id,
                        userToken: authResponse.data.user_token,
                        userOrganizationId: authResponse.data.user_organization_id,
                        admin: role?.includes("admin") || role?.includes("Admin") ? true : false,

                        firstName: authResponse.data.user_name,
                        userOrganizationName: authResponse.data.user_organization_name,
                        superAdmin: role === "Affiliate Administrator" ? true : false,
                        userSecret: authResponse.data.user_secret,
                    },
                    {
                        path: "/",
                    }
                );
                setCookie("nodeonClubId", authResponse.data?.nodeonClubId, {
                    path: "/",
                });
                setCookie("ue", email, {
                    path: "/",
                });

                setUserLoggedIn(true);
            }

            setUILoading(false);

            return authResponse;
        } catch (err) {
            console.log(err);
            console.log(cookies);
            setUILoading(false);
        }
    };

    const registerProcess = async (inputs, role, token, adminOrganizationId) => {
        console.log(inputs, role, token, adminOrganizationId);
        try {
            setUILoading(true);

            // admin account will be created on child account but other normal users will be created on parent account
            const response = await registerUserWithRoles(
                role?.includes("admin") || role?.includes("Admin") ? adminOrganizationId : organizationId,
                inputs,
                role,

                token
            );
            // const response = await registerUserWithRoles(organizationId, inputs);
            // const response = await registerUser(organizationId, inputs);

            // 'ismaccount administrator'
            setUILoading(false);

            return response;
        } catch (err) {
            console.log(err);
            setUILoading(false);
        }
    };
    const redirectUserToLogin = async (loginRoute) => {
        const currentPath = window.location.pathname;
        console.log(currentPath);

        // If user isnt already on loginRoute
        if (currentPath !== loginRoute) {
            console.log(currentPath);
            // Set current route in context
            setGoBackToPrevious(true);

            // TODO: This forces a user that is not logged in to the login page.
            // When the user presses back in the browser they arrive on this page
            // and then they are again pushed to the login page. This loop should
            // be broken so they can get back to the page from where they arrived here.
            // For task https://www.goodday.work/t/svqs3l

            // Push user to defined loginRoute
            history.push(`/${loginRoute}`);
        }
    };

    return (
        <MyContextFunctions.Provider
            value={{
                authProcess,

                registerProcess,
                redirectUserToLogin,
            }}
        >
            {props.children}
        </MyContextFunctions.Provider>
    );
};

export const useMyContextFunctions = () => useContext(MyContextFunctions);

export default ContextFunctions;
