import React, { useState } from "react";

import * as settings from "../../../config_settings.json";
import "./AdminSideBar1.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdminNavBar from "../../NavBars/AdminNavBar/AdminNavBar";
import { MdLiveTv, MdSettings, MdPayment } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function AdminSideBar({ styles }) {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div
      className={`${
        sidebarOpen ? "side-bar" : " side-bar collapse"
      } protrebleextrabold`}
    >
      <div
        className="logo-name-wrapper font-600"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <div className="logo-name">
          <span className="logo-name__name">{t("adminSideBar.dashboard")}</span>
        </div>
        <button className="logo-name__button">
          {!sidebarOpen ? (
            <FontAwesomeIcon
              icon="chevron-right"
              className="logo-name__icon font-700"
            />
          ) : (
            <FontAwesomeIcon
              icon="chevron-left"
              className="logo-name__icon font-700"
            />
          )}
        </button>
      </div>

      <ul className="features-list">
        <AdminNavBar
          icon="gamepad"
          name={t("adminSideBar.games")}
          extraClassname
          route={`${settings.routes.admin}`}
          styles={{ fontWeight: "800" }}
        />

        <AdminNavBar
          route={`${settings.routes.adminAddNewEvent}`}
          name={t("adminSideBar.addNewGame")}
          icon="plus"
        />
        {/* admin/:categoryName/:organizationId/:categoryId */}
        {/* // we will get organizatioid of admin from user api  */}
        <AdminNavBar
          route={settings.routes.adminUpcomingEvents}
          name={t("eventsCategory.upcomingEventsTitle")}
          icon={"arrow-right"}
        />
        <AdminNavBar
          route={settings.routes.adminLiveEvents}
          name={t("eventsCategory.liveNowEventsTitle")}
          icon="lock"
          reactIcon={<MdLiveTv />}
        />
        <AdminNavBar
          route={settings.routes.adminPastEvents}
          name={t("adminSideBar.pastEvents")}
          icon="history"
        />

        <AdminNavBar
          route={settings.routes.adminUsersDashboard}
          name={t("adminSideBar.users")}
          extraClassname
          styles={{ fontWeight: "800", marginTop: "10px" }}
          icon="user"
        />

        <AdminNavBar
          route={settings.routes.adminUsersList}
          name={t("adminSideBar.adminUsers")}
          icon="user-circle"
        />
        <AdminNavBar
          route={settings.routes.adminUserCreate}
          name={t("adminSideBar.addNewAdmin")}
          icon="user-plus"
        />
        {/* // settings starts here */}
        {/* <AdminNavBar
                    route={settings.routes.settings}
                    name={t("adminSideBar.settings")}
                    extraClassname
                    styles={{ fontWeight: "800", marginTop: "10px" }}
                    reactIcon={<MdSettings />}
                    // the below icon is obselete
                    icon="user"
                /> */}
        {/* <AdminNavBar route={settings.routes.settingsVisma} name={t("adminSideBar.vismaSubID")} icon="user-circle" reactIcon={<MdPayment />} /> */}

        {/* // setting ends here */}
        <AdminNavBar
          route={settings.routes.adminAnalytics}
          name={t("adminSideBar.analytics")}
          extraClassname
          styles={{ fontWeight: "800", marginTop: "10px" }}
          icon={"chart-area"}
        />
        <AdminNavBar
          route={settings.routes.adminAnalyticsGames}
          name={t("adminSideBar.gamesAnalytics")}
          icon={"chart-pie"}
        />
      </ul>
    </div>
  );
}
