import React, { useEffect, useState } from "react";
import LiveVideoPlayer from "../../VideoPlayer/LiveVideoPlayer";
import * as classes from "../Event/UpcomingEvent.module.css";
import EventsRoomDescription from "../Event/EventsRoomDescription";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../Shared/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { getSingleEvent } from "../../../scripts/dataHandlers";

export default function LiveEvents({ routes, ticketAccessGranted, ticketAccessRequired, singleEvent, defaultLanguage, setTicketAccessGranted }) {
    const { t } = useTranslation();

    const [serviceOriginState, setServiceOriginState] = useState(0);
    const [checkingOriginState, setCheckingOriginState] = useState(false);

    const { orgId, eventId } = useParams();
    console.log(orgId, eventId);

    const history = useHistory();

    useEffect(() => {
        if (history) {
            // FOR AVOIDING RE-RENDER HACK
            if (history.action === "PUSH") {
                window.location.reload();
            }
        }
    }, []);

    /* Mikko requirement for salibandy don't even load the player, if there is no ingest, have Play button that user can press to try again, do not autoload stream again */

    /* So we should show the player at all if there is no stream so we should check event?.serviceOriginState ->if this is 0 it means no streaming is talking place ->1 means streaming is going on  */

    useEffect(() => {
        if (singleEvent?.eventId) {
            setServiceOriginState(singleEvent?.serviceOriginState);
            // !ticketAccessGranted && checkForVideoAccess();
        }
    }, [singleEvent?.eventId, singleEvent?.serviceOriginState]);

    const checkForLiveStreamHandler = async () => {
        // we will send the api request again to check for origin status
        setCheckingOriginState(true);

        const res = await getSingleEvent(orgId, eventId);
        console.log("res", res);

        if (res?.status === "ok") {
            setServiceOriginState(res?.event?.serviceOriginState);
        } else {
            // no streaming is taking place .our server might be down or whatever reason api does not give us the data
            setServiceOriginState(0);
        }

        setTimeout(() => {
            // giving timeout of 1s so user does not press it constantly
            setCheckingOriginState(false);
        }, 1000);
    };

    return (
        <>
            {ticketAccessGranted && !ticketAccessRequired && singleEvent?.eventId && (
                <>
                    {serviceOriginState === 1 ? (
                        <div className={classes.SecondaryContainer}>
                            <LiveVideoPlayer
                                channelServiceId={singleEvent?.isMultiroom ? singleEvent.rooms[0].serviceId : singleEvent.serviceId}
                                eventPlayer={true}
                                eventId={singleEvent.eventId}
                                event={singleEvent}
                                serviceOriginState={serviceOriginState}
                                setServiceOriginState={setServiceOriginState}
                                checkForLiveStreamHandler={checkForLiveStreamHandler}
                            />
                        </div>
                    ) : (
                        // No streaming

                        <div style={{ height: "50vh", background: "black", flexDirection: "column", gap: "20px" }} className="display-flex-center">
                            {!checkingOriginState && (
                                <div style={{ color: "white" }} className="font-600">
                                    {t("videoPlayer.notStreamingMessage")}
                                </div>
                            )}
                            <Button onClick={checkForLiveStreamHandler}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {checkingOriginState ? (
                                        <>
                                            <FontAwesomeIcon icon="spinner" spin />
                                            <div style={{ marginLeft: "10px" }}> {t("shared.loading")}</div>
                                        </>
                                    ) : (
                                        <>
                                            {" "}
                                            <FontAwesomeIcon icon="redo" />
                                            <div style={{ marginLeft: "10px" }}> {t("videoPlayer.reloadStream")}</div>
                                        </>
                                    )}
                                </div>
                            </Button>
                        </div>
                    )}
                </>
            )}
            <EventsRoomDescription
                liveEvent={true}
                routes={routes}
                ticketAccessGranted={ticketAccessGranted}
                singleEvent={singleEvent}
                ticketAccess={ticketAccessRequired}
                defaultLanguage={defaultLanguage}
                setTicketAccessGranted={setTicketAccessGranted}
                styles={{ color: "black" }}
            />
        </>
    );
}
