import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import LiveEvents from "../LiveEvents/LiveEvents";
import CompletedEventsWithoutRecording from "./CompletedEventWithoutRecording";
import CompletedEventsWithRecording from "./CompletedEventWithRecording";
import { useParams } from "react-router-dom";

const CompletedEvents = (props) => {
    const [compleLoading, setCompleLoading] = useState(true);
    const [recordingAssetId, setRecordingAssetId] = useState(null);
    const [isLiveEvents, setIsLiveEvents] = useState(false);

    const { recordingId } = useParams();

    const { routes, singleEvent, ticketAccessRequired, ticketAccessGranted, setTicketAccessGranted, defaultLanguage } = props;
    console.log(props);

    useEffect(() => {
        if (singleEvent) {
            if (singleEvent?.rooms?.length > 0 || !singleEvent?.isMultiroom) {
                // if a multiroom
                if (singleEvent?.isMultiroom) {
                    setRecordingAssetId(recordingId ? recordingId : singleEvent?.rooms[0].recordings[0]?.assetId);
                } else {
                    // not multiroom

                    if (singleEvent?.recordings) {
                        setRecordingAssetId(recordingId ? recordingId : singleEvent?.recordings[0]?.assetId);
                    }
                }
                setIsLiveEvents(false);
            }
            let currentTime = Date.now();
            // time is second, Dat.now is ms so multiply by 1000
            let totalEndTime;

            if (singleEvent?.startTimePublic) {
                totalEndTime = singleEvent?.startTimePublic + singleEvent?.durationPublic * 1000;
            } else {
                totalEndTime = singleEvent?.startTime + singleEvent?.duration * 1000;
            }

            if ((currentTime >= singleEvent.startTimePublic ? singleEvent.startTimePublic : singleEvent.startTime) && currentTime < totalEndTime) {
                setIsLiveEvents(true);
            }

            setCompleLoading(false);
        }
    }, [singleEvent, recordingId]);

    if (singleEvent?.eventId && !compleLoading) {
        if (isLiveEvents)
            return (
                <LiveEvents
                    routes={routes}
                    ticketAccessRequired={ticketAccessRequired}
                    ticketAccessGranted={ticketAccessGranted}
                    singleEvent={singleEvent}
                    defaultLanguage={defaultLanguage}
                    setTicketAccessGranted={setTicketAccessGranted}
                />
            );

        return recordingAssetId ? (
            <CompletedEventsWithRecording
                routes={routes}
                ticketAccessRequired={ticketAccessRequired}
                ticketAccessGranted={ticketAccessGranted}
                singleEvent={singleEvent}
                defaultLanguage={defaultLanguage}
                setTicketAccessGranted={setTicketAccessGranted}
                recordingAssetId={recordingAssetId}
                isLiveEvents={isLiveEvents}
                setRecordingAssetId={setRecordingAssetId}
            />
        ) : (
            <CompletedEventsWithoutRecording routes={routes} singleEvent={singleEvent} defaultLanguage={defaultLanguage} />
        );
    } else {
        return <Loader />;
    }
};

export default CompletedEvents;
