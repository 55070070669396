import React, { useEffect, useState } from "react";

import { getLiveNowGames } from "../../../scripts/dataHandlers";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import LiveNowEventsCategoryItem from "./LiveNowEventsCategoryItem";
import { useMyContext } from "../../../contexts/StateHolder";
import Loader from "react-loader-spinner";
import { useSearchParams } from "../../../Shared/Hooks/search-hook";
import { useCookies } from "react-cookie";
import EventsItem1 from "../Events/EventsItem1/EventsItem1";

export default function LiveNowEvents(props) {
  const { subOrganizationId } = useParams();
  const [cookies] = useCookies([" "]);

  const { t } = useTranslation();

  const {
    selectedArea,
    selectedLevelContext,
    resetSelectedLevel,
    resetSelectedArea,
    setSelectedArea,
    setResetSelectedArea,
    setSelectedLevelContext,
  } = useMyContext();

  const [allLiveNowEvents, setAllLiveNowEvents] = useState(null);
  const { searchParam } = useSearchParams(
    selectedArea,
    selectedLevelContext,
    resetSelectedArea,
    resetSelectedLevel
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const runFunction = async () => {
      try {
        const res1 = await getLiveNowGames(
          props.accessedbyAdmin && cookies?.supAdOrg
            ? cookies?.supAdOrg
            : subOrganizationId
            ? subOrganizationId
            : props.organizationId
        );

        if (res1?.data?.status === "ok") {
          setAllLiveNowEvents(res1?.data?.games);
        }
      } catch (error) {
        console.log(error);
      }
    };

    props.organizationId && runFunction();
  }, [props.organizationId, props.limit, subOrganizationId]);

  useEffect(() => {
    if (subOrganizationId) {
      // it means we are in child organization and will remove any filter if exists
      setSelectedLevelContext([]);
      setSelectedArea([]);
      setResetSelectedArea(true);
    }
  }, [subOrganizationId]);

  useEffect(() => {
    // selectedArea and selectedSeason will be the list of in mumber ->dont know yet if we should stringify as a dependencies

    const runFunction = async () => {
      try {
        if (searchParam) {
          setLoading(true);

          const res2 = await getLiveNowGames(
            props.accessedbyAdmin && cookies?.supAdOrg
              ? cookies?.supAdOrg
              : subOrganizationId
              ? subOrganizationId
              : props.organizationId,
            100,

            searchParam
          );

          if (res2?.data?.status === "ok") {
            setAllLiveNowEvents(res2?.data?.games);
          }
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (searchParam) {
      runFunction();
    }
  }, [JSON.stringify(searchParam), searchParam]);

  return (
    // If it includes props(upcomingEventCategory) , it means it wont have any category id or name and we have to fetch filter data and route accordingly
    !loading ? (
      allLiveNowEvents && !props.accessedbyAdmin ? (
        <EventsItem1
          allEvents={allLiveNowEvents}
          organizationId={
            subOrganizationId ? subOrganizationId : props.organizationId
          }
          heading={t("eventsCategory.liveNowEventsTitle")}
          extraClassname={props.extraClassname}
          hideViewAll={props.hideViewAll}
          accessedbyAdmin={props.accessedbyAdmin}
          settings={props.settings}
          viewAllRoute={`/liveNowEvents/${
            subOrganizationId ? subOrganizationId : props.organizationId
          }`}
        />
      ) : (
        allLiveNowEvents &&
        props.accessedbyAdmin && (
          <LiveNowEventsCategoryItem
            heading={t("eventsCategory.liveNowEventsTitle")}
            extraClassname={props.extraClassname}
            hideViewAll={props.hideViewAll}
            styles={props.styles}
            accessedbyAdmin={props.accessedbyAdmin}
            settings={props.settings}
            setting={props.settings}
            categoryIdProps={props.categoryIdProps}
            categoryNameProps={props.categoryNameProps}
            organizationIdProps={
              cookies?.supAdOrg
                ? cookies?.supAdOrg
                : subOrganizationId
                ? subOrganizationId
                : props.organizationId
            }
            viewAllRoute={`/liveNowEvents/${
              cookies?.supAdOrg
                ? cookies?.supAdOrg
                : subOrganizationId
                ? subOrganizationId
                : props.organizationId
            }`}
          />
        )
      )
    ) : (
      <div className="display-flex-center">
        {" "}
        <Loader type="TailSpin" color="#161eaf" />{" "}
      </div>
    )
  );
}
