import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronDown } from "react-icons/fi";

import { FiChevronUp } from "react-icons/fi";
import { GrUserAdmin, GrUser } from "react-icons/gr";
import { Link } from "react-router-dom";

// Renders contentRatings of chosen item
const LoginDropDown = (props) => {
  const { t } = useTranslation();

  const [viewDropdown, setViewDropdown] = useState(false);
  const [allLoginList, setAllLoginList] = useState([
    {
      name: t("shared.user"),
      route: "/login",
      icon: (
        <GrUser
          className="font-600 customIconLogin"
          style={{ width: "30px", maxWidth: "30px", minWidth: "30px" }}
        />
      ),
    },
    {
      name: t("shared.admin"),

      route: "/login/admin",
      icon: (
        <GrUserAdmin
          className="font-600 customIconLogin"
          style={{ width: "30px", maxWidth: "30px", minWidth: "30px" }}
        />
      ),
    },
  ]);

  // Use ref to make sure react renders properly when clicking outside of box
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      // Set loginForm to false, if clicked on outside of element
      function handleClickOutside(event) {
        if (event.target.className === "navBarBTN categories selected") {
          // Do nothing as button's onClick effect will deal with toggle (clicked button)
        } else if (ref.current && !ref.current.contains(event.target)) {
          // Hide dropdown
          setViewDropdown(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [isActive, setActive] = useState(false);

  const toggleClass = (e) => {
    e.preventDefault();
    setActive(!isActive);
  };

  return (
    <div
      className="containerprofile font-300 profileContainerItem"
      onClick={toggleClass}
    >
      <div className="half smallcasebold">
        <label
          className={"profile-dropdown profile-dropdown1 profileContainerItem"}
        >
          <input
            type="checkbox"
            onChange={toggleClass}
            checked={isActive}
            style={{ display: "none" }}
          />
          {/* <FiLogIn className='font-600' /> */}
          <img
            src="https://img.icons8.com/ios/344/login-rounded-right.png"
            alt="login"
          />

          <span>{t("login.loginButton")}</span>
          <label className="label1">
            {isActive ? (
              <FiChevronUp className="font-600" />
            ) : (
              <FiChevronDown className="font-600" />
            )}
          </label>
          <ul>
            {allLoginList.map((el, i) => {
              return (
                <li key={el?.name}>
                  <Link to={el?.route} className="font-400">
                    {el?.icon}

                    <div> {el?.name}</div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </label>
      </div>
    </div>
  );
};

export default LoginDropDown;
