import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Button from "../../../Shared/Button/Button";

import * as classes from "./UpcomingEvent.module.css";
import { useMyContext } from "../../../contexts/StateHolder";

const BuyTickets = ({ routes, singleEvent }) => {
  const { isLocationAccessGranted } = useMyContext();
  const history = useHistory();
  const { t } = useTranslation();

  const buyNewTicket = () => {
    console.log("singleEvent", singleEvent);
    // we need to send also organizationId to this packages

    return history.push(
      `/${routes.packages}/${singleEvent?.organizationId * 1}/event/${
        singleEvent.eventId
      }`
    );
  };

  return (
    <>
      <div className={classes.buyTicket}>
        <div className={classes.buyTickets_info_button}>
          {
            <>
              <Button
                onClick={buyNewTicket}
                disabled={!isLocationAccessGranted}
              >
                {t("eventsCategory.buyTickets")}
              </Button>
              {!isLocationAccessGranted && (
                <p className="font-300 regular">
                  Tapahtuma ei ole katsottavissa sijainnissasi
                </p>
              )}
            </>
          }
        </div>
      </div>
    </>
  );
};

export default BuyTickets;
