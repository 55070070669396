import React from "react";
import * as classes from "./Header.module.css";
// import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {
  // FaChevronLeft,
  FaSearch,
} from "react-icons/fa";

export default function Header({
  showTitle,
  extraClassname,
  title,
  styles,
  admin,
  showSearchBox,
  searchFieldInput,
  setSearchFieldInput,
  searchFormHandler,
  // showBackButton,
  color,
  hideSearchIcon,
}) {
  // const history = useHistory();
  const { t } = useTranslation();

  // console.log(showTitle, extraClassname, title, styles);

  return (
    <div
      style={{ ...styles, color: color && color }}
      className={`${classes.HeaderTitle} ${
        extraClassname ? "font-700" : "font-500"
      } ${admin && classes.HeaderTitleAdmin} protrebleheavy`}
    >
      <div>{showTitle !== false ? title : null}</div>
      {showSearchBox && (
        <div className={classes.searchContainer_primary}>
          <div className={classes.searchContainer_primary_back}>
            <form
              id={classes.searchForm}
              onSubmit={(e) => searchFormHandler(e)}
            >
              <div className={classes.searchRow}>
                <div className={`${classes.searchColumn} font-300`}>
                  <input
                    className={`${classes.searchField} font-300 regular`}
                    type="text"
                    value={searchFieldInput}
                    onChange={(e) => setSearchFieldInput(e.target.value)}
                    placeholder={t("search.searchByName")}
                  />
                  {!hideSearchIcon && (
                    <FaSearch
                      className={`${classes.FaIcon} ${classes.FaIconSearch}`}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
