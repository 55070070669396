import { useTranslation } from "react-i18next";
import * as classes from "./NavBar.module.css";

import { useState } from "react";
import { useMyContext } from "../../contexts/StateHolder";
import { CgClose, CgMenu } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import { getImageByKey } from "../../scripts/getImageByKey";

const NavBar = ({
    links,

    styles,
    linksMobileLogin,
    linksMobileSearch,
    linksMobileLanguageSelect,
    linksMobileProfile,
    primaryLinks,
}) => {
    const { t } = useTranslation();

    const { isMenu, setisMenu, closeHamMenu, isResponsiveclose, setResponsiveclose, user } = useMyContext();
    // for main navigation mobile menu
    const toggleClass = () => {
        setisMenu(isMenu === false ? true : false);
        setResponsiveclose(isResponsiveclose === false ? true : false);
    };

    let boxClass = ["main-menu menu-right menuq1 scale-up-center  "];
    if (isMenu) {
        boxClass.push("menuq2");
    } else {
        boxClass.push("");
    }

    // for dropdown navigation mobile menu
    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
    const toggleSubmenu = () => {
        setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };
    let boxClassSubMenu = ["sub__menus "];
    if (isMenuSubMenu) {
        boxClassSubMenu.push("sub__menus__Active");
    } else {
        boxClassSubMenu.push("");
    }

    return (
        <div className={classes.navBar} style={styles?.navBar}>
            <div className={classes.navBar_Primary}>
                <NavLink to="/" exact activeClassName="no-class">
                    <img
                        className="navBarSiteLogo"
                        src={getImageByKey("logo_organization")}
                        title={t("Back to Home")}
                        alt="SiteName"
                        style={styles?.navBar}
                        onClick={closeHamMenu}
                    ></img>
                </NavLink>

                <div style={{ display: "flex", width: "100%" }}>{primaryLinks}</div>
            </div>

            <nav className={`${classes.navBarRow} main-nav `}>
                {/* // small screen action button */}
                <div className="action">
                    {isResponsiveclose === true ? (
                        <>
                            <span className="menubar__button " style={{ display: "none" }} onClick={toggleClass}>
                                {" "}
                                <CgClose />
                            </span>
                        </>
                    ) : (
                        <>
                            <span className="menubar__button" style={{ display: "none" }} onClick={toggleClass}>
                                {" "}
                                <CgMenu />
                            </span>
                        </>
                    )}
                    {/* // search will only appear when all links are not opened in navabar */}
                    {!isResponsiveclose && <div className={"navBarSiteLogoMobile"}>{linksMobileSearch}</div>}
                </div>

                <ul className={` ${classes.navBarRow_primary} ${boxClass.join(" ")}`}>{links}</ul>

                {/* Logo small screen */}
                <div className={"navBarSiteLogoMobile"}>
                    <NavLink to="/" exact activeClassName="no-class">
                        <img
                            src={getImageByKey("logo_organization")}
                            title={t("navBar.backToHome")}
                            alt="SiteName"
                            style={styles?.navBar}
                            onClick={closeHamMenu}
                            className="navBarSiteLogoMobile-img"
                        ></img>
                    </NavLink>
                </div>
                {user.loggedIn && <div className="navBarSiteLogoMobile">{linksMobileProfile}</div>}
                {linksMobileLogin && <div className={"navBarSiteLogoMobile"}>{linksMobileLogin}</div>}
            </nav>
        </div>
    );
};

export default NavBar;
