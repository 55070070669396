import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

import Header from "../../Shared/Header/Header";
import { useMyContext } from "../../contexts/StateHolder";
import { Input1 } from "../../Shared/Input1/Input1";
import { countries } from "../../scripts/countries";
import { useForm } from "../../Shared/Hooks/form-hook";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_PASSWORDMATCH,
  VALIDATOR_PHONE,
} from "../../Shared/Validation/Validator";

import * as classes from "../../Shared/Input1/Input1.module.css";
import Button from "../../Shared/Button/Button";
import { createNewClub } from "../../scripts/dataHandlers";
import * as settings from "../../config_settings.json";
import { createAdminUserToken } from "../../scripts/utils";
import { useMyContextFunctions } from "../../contexts/ContextFunctions";

export default function AddNewSuborganization() {
  const { t } = useTranslation();

  const [accountLoading, setAccountLoading] = useState(false);
  const [countryList, setCountryList] = useState(null);

  const history = useHistory();

  // Bring functions from context
  const { registerProcess } = useMyContextFunctions();

  // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform
  let [state, InputHandler] = useForm(
    {
      CLUB_NAME: {
        CLUB_NAME: "",
        isValid: false,
      },

      COUNTRY: {
        COUNTRY: "",
        isValid: false,
      },
      POSTAL_CODE: {
        POSTAL_CODE: "",
        isValid: false,
      },
      CITY_TOWN: {
        CITY_TOWN: "",
        isValid: false,
      },
      PHONE_NUMBER: {
        PHONE_NUMBER: "",
        isValid: false,
      },
      CLUB_EMAIL: {
        CLUB_EMAIL: "",
        isValid: false,
      },

      EMAIL: {
        value: "",
        isValid: false,
      },

      PASSWORD: {
        value: "",
        isValid: false,
      },
      PASSWORDCONFIRM: {
        value: "",
        isValid: false,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );

  useEffect(() => {
    let y1 = [];

    countries.forEach((el) => {
      let g = { value: el.id, label: t(`countries.${el.name}`) };
      y1.push(g);
    });
    let f = [];

    let d = countries.map((el) => {
      f.push({ [el.name]: el.name });
    });

    console.log("f111", f);

    setCountryList(y1);
  }, []);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setAccountLoading(true);
    console.log(state);

    let phoneNumber = state.inputs.PHONE_NUMBER.value;

    if (phoneNumber && phoneNumber.includes("34") && phoneNumber > 10) {
      // this only checks in the starting hand

      phoneNumber = phoneNumber.toString().replace("34", "0");
    }

    const res = await createNewClub(
      settings.organization.organizationId,
      state.inputs?.CLUB_NAME?.value,
      state.inputs?.COUNTRY?.value,
      state.inputs?.COUNTRY?.value,
      state.inputs?.POSTAL_CODE?.value,
      state.inputs?.CITY_TOWN?.value,
      phoneNumber,
      state.inputs?.CLUB_EMAIL?.value,
      settings.organization.key
    );

    console.log("res", res);

    if (res?.status === "ok") {
      // we create admin if account creation successfully
      let secret = res?.key;
      let orgId = res?.organizationId;
      let token2 = createAdminUserToken(orgId, secret);

      const res1 = await registerProcess(
        state.inputs,
        "ismaccount administrator",

        token2,

        orgId
      );

      toast.success(t("addNewSubOrganization.clubsSuccess"));
      setAccountLoading(false);
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } else {
      setAccountLoading(false);

      return toast.error(t("addNewSubOrganization.accountCreationFailed"));
    }
  };

  const colorStyles = {
    placeholder: (styles, state) => ({
      ...styles,
      color: "#00acde",
    }),
    control: (styles, state) => ({
      ...styles,
      borderRadius: "1rem",
      height: "50px",
      minHeight: "50px",
      maxHeight: "50px",
      backgroundColor: "white",
      borderColor: "#00acde",
    }),
  };

  return (
    <div className="loginViewContainer">
      {accountLoading && (
        <div className={accountLoading ? "copy-container active regular" : ""}>
          <div className={accountLoading ? "copy-popup active regular" : ""}>
            <div
              className="font-600"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="display-flex-center">
                {" "}
                <Loader type="TailSpin" color="#161eaf" />
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "10px 0 20px 0",
                  padding: "0 10px",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>{t("addNewSubOrganization.creatingClub")}</div>
                <div> {t("addNewSubOrganization.creatingClubMessage1")}</div>
                <div> {t("addNewSubOrganization.creatingClubMessage2")}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`${classes.formPrimary} ${classes.formPrimary2}`}>
        <div className={classes.formSecondary}>
          {true ? (
            <form
              className={classes.formBlogAdmin}
              onSubmit={formSubmitHandler}
            >
              <Header
                title={t("addNewSubOrganization.title")}
                extraClassname
                style={{ justifyContent: "center" }}
              />
              <Input1
                id="CLUB_NAME"
                type="text"
                element="input"
                validators={[VALIDATOR_REQUIRE()]}
                label={t("addNewSubOrganization.clubName")}
                errorText={t("addNewSubOrganization.invalidClubName")}
                placeholder={t("addNewSubOrganization.clubName")}
                onInput={InputHandler}
                colorStyles={colorStyles}
              />
              <Input1
                id="COUNTRY"
                type="text"
                element="select_dropdown"
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("userDetails.askForCountry")}
                placeholder={t("userDetails.inputPlaceholderCountry")}
                label={t("userDetails.inputPlaceholderCountry")}
                onInput={InputHandler}
                selectData={countryList}
                colorStyles={colorStyles}
              />
              <Input1
                id="POSTAL_CODE"
                label={t("addNewSubOrganization.postalCode")}
                errorText={t("addNewSubOrganization.invalidpostalCode")}
                type="text"
                element="input"
                validators={[VALIDATOR_REQUIRE()]}
                onInput={InputHandler}
              />{" "}
              <Input1
                id="CITY_TOWN"
                label={t("addNewSubOrganization.cityTown")}
                errorText={t("addNewSubOrganization.invalidcityTown")}
                type="text"
                element="input"
                validators={[VALIDATOR_REQUIRE()]}
                onInput={InputHandler}

                //
              />{" "}
              <Input1
                id="PHONE_NUMBER"
                label={t("addNewSubOrganization.phoneNumber")}
                errorText={t("addNewSubOrganization.invalidphoneNumber")}
                type="number"
                element="input"
                validators={[VALIDATOR_PHONE(true)]}
                onInput={InputHandler}
                //
              />{" "}
              {/* +34612345678 */}
              <Input1
                id="CLUB_EMAIL"
                label={t("addNewSubOrganization.email")}
                errorText={t("addNewSubOrganization.invalidemail")}
                placeholder={t("loginForm.askForPassword")}
                type="text"
                element="input"
                validators={[VALIDATOR_EMAIL()]}
                onInput={InputHandler}
                iconName="user"
              />{" "}
              <Header
                title={t("addNewSubOrganization.contactPerInfo")}
                extraClassname
                style={{ justifyContent: "center" }}
              />
              {/* <Input1
                id='FIRST_NAME'
                placeholder={t('loginForm.askForEmail')}
                type='text'
                element='input'
                validators={[VALIDATOR_REQUIRE()]}
                label={t('addNewSubOrganization.firstName')}
                errorText={t('addNewSubOrganization.invalidfirstName')}
                onInput={InputHandler}
                // initialValue='enter'
              />
              <Input1
                id='LAST_NAME'
                placeholder={t('loginForm.askForEmail')}
                type='text'
                element='input'
                validators={[VALIDATOR_REQUIRE()]}
                label={t('addNewSubOrganization.lastName')}
                errorText={t('addNewSubOrganization.invalidlastName')}
                onInput={InputHandler}
              /> */}
              {/* <Input1
                id='REGION2'
                label='REGION'
                placeholder={t('userDetails.inputPlaceholderCountry')}
                type='text'
                selectData={countries.map((country) => country.name)}
                element='select'
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t('userDetails.askForCountry')}
                onInput={InputHandler}
              /> */}
              {/* <Input1
                id='STREET_ADDRESS_USER'
                placeholder={t('signUpForm.country')}
                type='text'
                element='input'
                validators={[VALIDATOR_REQUIRE()]}
                label={t('addNewSubOrganization.streetAddress')}
                errorText={t('addNewSubOrganization.invalidstreetAddress')}
                onInput={InputHandler}
              />{' '}
              <Input1
                id='POSTAL_CODE_USER'
                placeholder={t('loginForm.askForPassword')}
                type='text'
                element='input'
                validators={[VALIDATOR_REQUIRE()]}
                label={t('addNewSubOrganization.postalCode')}
                errorText={t('addNewSubOrganization.invalidpostalCode')}
                onInput={InputHandler}
              />{' '}
              <Input1
                id='CITY_TOWN_USER'
                placeholder={t('loginForm.askForPassword')}
                type='text'
                element='input'
                validators={[VALIDATOR_REQUIRE()]}
                label={t('addNewSubOrganization.cityTown')}
                errorText={t('addNewSubOrganization.invalidcityTown')}
                onInput={InputHandler}

                //
              />{' '}
              <Input1
                id='PHONE_NUMBER_USER'
                placeholder={t('loginForm.askForPassword')}
                type='text'
                element='input'
                validators={[VALIDATOR_REQUIRE()]}
                label={t('addNewSubOrganization.phoneNumber')}
                errorText={t('addNewSubOrganization.invalidphoneNumber')}
                onInput={InputHandler}
                //
              />{' '} */}
              <Input1
                id="EMAIL"
                placeholder={t("loginForm.askForPassword")}
                type="text"
                element="input"
                validators={[VALIDATOR_EMAIL()]}
                label={t("addNewSubOrganization.emailPerson")}
                errorText={t("addNewSubOrganization.invalidemail")}
                onInput={InputHandler}
                iconName="user"
              />{" "}
              <p
                className="font-100 smallcasebold"
                style={{
                  textAlign: "center",
                  marginTop: "-20px",
                  color: "white",
                }}
              >
                {t("addNewSubOrganization.accountCreationWithSameEmail")}
              </p>
              <Input1
                id="PASSWORD"
                label={t("signUpForm.password")}
                placeholder={t("signUpForm.askForPassword")}
                type="Password"
                element="input"
                validators={[VALIDATOR_MINLENGTH(5)]}
                errorText={t("signUpForm.errorPasswordNotLongEnough", {
                  count: "5",
                })}
                onInput={InputHandler}
                iconName="lock"
              />
              <Input1
                id="PASSWORDCONFIRM"
                label={t("signUpForm.passwordConfirm")}
                placeholder={t("signUpForm.askForConfirmPassword")}
                type="Password"
                element="input"
                validators={[
                  VALIDATOR_PASSWORDMATCH(
                    state.inputs?.PASSWORD.value,
                    state.inputs?.PASSWORDCONFIRM.value
                  ),
                ]}
                errorText={t("signUpForm.errorPasswordsDontMatch")}
                onInput={InputHandler}
                iconName="lock"
              />
              <Button
                className={classes.loginFormButton}
                disabled={!state.isValid || accountLoading}
                formButton
                inverse
              >
                {/* {t('loginForm.loginButton')} */}
                {accountLoading
                  ? t("addNewSubOrganization.creating")
                  : t("addNewSubOrganization.create")}
              </Button>
            </form>
          ) : (
            <div className="display-flex-center">
              {" "}
              <Loader type="TailSpin" color="#161eaf" />{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
