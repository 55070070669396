import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../../../Shared/Header/Header";
import { useForm } from "../../../../Shared/Hooks/form-hook";
import { Input1 } from "../../../../Shared/Input1/Input1";

import * as classes from "../../../../Shared/Input1/Input1.module.css";
import { VALIDATOR_REQUIRE } from "../../../../Shared/Validation/Validator";
import Button from "../../../../Shared/Button/Button";
import { useMyContext } from "../../../../contexts/StateHolder";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function ChangeOrganizationItem({ allOrganizationList }) {
    const { t } = useTranslation();
    const [cookies, setCookie] = useCookies("");
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const { setSuperAdminSelectedOrganization, user, setUser } = useMyContext();
    const [currentSelectedOrg, setCurrentSelectedOrg] = useState(null);

    let [state, InputHandler] = useForm(
        {
            ORGANIZATION: {
                value: "",
                isValid: false,
            },
        },
        false
    );

    useEffect(() => {
        if (cookies?.supAdSec && cookies?.supAdOrg && allOrganizationList?.length > 0) {
            setLoading(true);
            let curVal = `${cookies?.supAdOrg}:${cookies?.supAdSec}`;

            let curOrg = allOrganizationList.find((el) => el?.value === curVal);
            setCurrentSelectedOrg(curOrg);
            setTimeout(() => {
                setLoading(false);
            }, 100);
        }
    }, [cookies, allOrganizationList]);

    console.log(currentSelectedOrg?.label, currentSelectedOrg?.value);

    const formSubmitHandler = (e) => {
        e.preventDefault();
        console.log(state);
        // it includes both organizationId and secret

        // saving in cookie so we can use later if needed

        let x = state.inputs.ORGANIZATION.value.split(":");
        setSuperAdminSelectedOrganization(x[0]);

        setCookie("supAdOrg", x[0], {
            path: "/",
        });
        setCookie("supAdSec", x[1], {
            path: "/",
        });
        let modifiedUser = { ...user };

        // we also need to change nodeonCLubId when super admin selects other club

        setCookie("nodeonClubId", x[2], {
            path: "/",
        });
        let orgName = allOrganizationList.find((el) => el?.value === state.inputs.ORGANIZATION.value)?.label;

        modifiedUser.organizationName = orgName;

        let cookieUser = { ...user };
        console.log(user);
        cookieUser.userOrganizationId = user?.organizationId;
        cookieUser.userOrganizationName = orgName;

        setUser(modifiedUser);
        setCookie("userData", cookieUser, {
            path: "/",
        });

        toast.success(`Organization Switched`, {
            autoClose: 1000,
            position: "top-center",
            closeOnClick: true,
            draggable: true,
            theme: "dark",
        });

        // lets redirect user to admin panel

        setTimeout(() => {
            history.push("/admin");
        }, 1000);
    };

    return (
        <div className="loginViewContainer">
            {!loading && (
                <div className={classes.formPrimary}>
                    <div className={classes.formSecondary}>
                        <form className={classes.formBlog} onSubmit={formSubmitHandler} style={{ padding: "20px 50px 50px 50px" }}>
                            <Header title={`${t("changeOrganization.changeOrganization")}`} extraClassname style={{ justifyContent: "center" }} />
                            <Input1
                                id="ORGANIZATION"
                                type="text"
                                element="select_dropdown"
                                validators={[VALIDATOR_REQUIRE()]}
                                label={t("addNewSubOrganization.clubName")}
                                // label="Organization Name"
                                errorText={t("addNewSubOrganization.invalidClubName")}
                                placeholder={t("addNewSubOrganization.clubName")}
                                onInput={InputHandler}
                                selectData={allOrganizationList}
                                defaultValue={{
                                    label: currentSelectedOrg?.label ? currentSelectedOrg?.label : "",

                                    value: currentSelectedOrg?.value ? currentSelectedOrg?.value : "",
                                }}
                                initialValue={currentSelectedOrg?.value ? currentSelectedOrg?.value : ""}
                                initialValid={currentSelectedOrg?.label ? true : false}
                            />

                            <Button className={classes.loginFormButton} disabled={!state.isValid} formButton inverse>
                                {t("adminUsersDashboard.save")}
                            </Button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}
