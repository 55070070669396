import React, { useState } from "react";
import { useForm } from "../../Shared/Hooks/form-hook";
import { Input1 } from "../../Shared/Input1/Input1";
import * as classes from "../../Shared/Input1/Input1.module.css";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_REQUIRE,
} from "../../Shared/Validation/Validator";
import Button from "../../Shared/Button/Button";
import { useTranslation } from "react-i18next";
import { resetPasswordToken } from "../../scripts/utils";

import * as settings from "../../config_settings.json";
import { requestResetPassword } from "../../scripts/dataHandlers";
import Header from "../../Shared/Header/Header";

export default function ResetPasswordForm() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resetEmailSuccess, setResetEmailSuccess] = useState(false);
  const { t } = useTranslation();

  const [state, InputHandler] = useForm(
    {
      EMAIL: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const token1 = resetPasswordToken(
      state.inputs.EMAIL.value,
      settings.organization.organizationId,

      "IsmSubscriber",
      settings.organization.key
    );

    const res = await requestResetPassword(
      state.inputs.EMAIL.value,
      settings.organization.organizationId,

      "IsmSubscriber",
      token1
    );
    if (res?.data?.status === "ok") {
      setResetEmailSuccess(true);
    } else if (res?.data?.status === "error") {
      setError(`${t("resetPassword.emailNotExistInSystem")}`);
    } else {
      // this basically means our servers are down
      setError(`${t("shared.systemError")}`);
    }
    setLoading(false);
  };
  return resetEmailSuccess ? (
    <>
      <div className={`${classes.formPrimary} ${classes.formPrimarySignIn}`}>
        <div className={classes.formSecondary}>
          <div className={`${classes.formBlog} `}>
            <Header title={t("resetPassword.resetPassword")} extraClassname />
            <p
              className="font-200 smallcasebold "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {t("resetPassword.resetPasswordCheckEmail")}
            </p>

            <Button
              className={classes.loginFormButton}
              formButton
              inverse
              to="/"
            >
              {t("packages.ReceiptMoveToFront")}
            </Button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className={`${classes.formPrimary} ${classes.formPrimarySignIn}`}>
      <div className={classes.formSecondary}>
        <form className={`${classes.formBlog} `} onSubmit={formSubmitHandler}>
          <Header title={t("resetPassword.resetPassword")} extraClassname />

          {error && !loading && (
            <p
              className="font-300 smallcasebold "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ab0303",
              }}
            >
              {error}
            </p>
          )}

          <Input1
            id="EMAIL"
            label={t("signUpBar.inputPlaceholderEmail")}
            placeholder={t("Enter your email here")}
            type="text"
            element="input"
            validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE()]}
            errorText={t("signUpBar.errorIncorrectEmailAddress")}
            onInput={InputHandler}
            iconName="envelope"
          />

          <p
            className="font-200 smallcasebold "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {t("resetPassword.enterVerifiedEmail")}
          </p>

          <Button
            className={classes.loginFormButton}
            disabled={!state.isValid || loading}
            formButton
            inverse
          >
            {loading
              ? t("shared.loading")
              : t("resetPassword.requestResetPasswordLink")}
          </Button>
        </form>
      </div>
    </div>
  );
}
