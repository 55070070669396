import React from 'react';
import AdminSideBar from '../AdminSideBar/AdminSideBar';
import SideBar from '../SideBar/SideBar';
import * as classes from './Dashboard.module.css';

export default function Dashboard({ children, admin, style }) {
  // Bring stateholders from context

  return (
    <div className={classes.Dashboard}>
      <div className={classes.DashboardSideBar}>
        {admin ? <AdminSideBar /> : <SideBar />}
      </div>

      <div className={classes.DashboardContent} style={style}>
        {' '}
        {children}
      </div>
    </div>
  );
}
