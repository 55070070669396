import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../contexts/StateHolder";
import PackageComponent from "../PackageComponent/PackageComponent";
import * as classes from "./PackagesView.module.css";
import { useForm } from "../../../Shared/Hooks/form-hook";
import { VALIDATOR_EMAIL, VALIDATOR_PASSWORDMATCH, VALIDATOR_REQUIRE } from "../../../Shared/Validation/Validator";
import { Input } from "../../../Shared/Input/Input";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import * as settings from "../../../config_settings.json";

//prints title and all the packages
const PackagesView = (props) => {
    const { buyButtonAction, profileData, orgId } = props;
    const { packages, user } = useMyContext();
    const [cookies, setCookie] = useCookies(["ue"]);

    const { t } = useTranslation();

    const [state, InputHandler] = useForm(
        {
            EMAIL: {
                value: "",
                isValid: false,
            },
        },
        false
        // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
    );
    console.log(cookies.ue, "cookie ", user);
    return (
        <div className={classes.packagesView}>
            <div className={classes.packageHelp}>
                <div className={classes.packageHelpTitle}>{t("packages.PackageHelpTitle")}</div>
                <div className={classes.packageHelpText}>
                    {t("packages.PackageHelpText")}
                    <br />
                    <br />
                    {t("packages.PackageHelpText2")}
                    <br />
                    <br />
                    {!user?.loggedIn ? t("packages.PackageLoginForAccess") : t("packages.PackageHelpEmail")}
                    <Link to={`/${settings.routes.subscription}`} className={classes.profilePageLink}>
                        {t("packages.PackageProfileLink")}
                    </Link>{" "}
                    sivulta.
                    {user?.loggedIn && user.eMail && (
                        <>
                            <div className={classes.packageHelp_input}>
                                <Input
                                    id="EMAIL"
                                    label="EMAIL"
                                    placeholder={"ENTER YOUR EMAIL"}
                                    type="text"
                                    element="input"
                                    validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
                                    // errorText={'please enter valid email'}
                                    onInput={InputHandler}
                                    iconName="envelope"
                                    style={{
                                        margin: "0",
                                    }}
                                    initialValue={user?.eMail}
                                    initialValid={user?.eMail ? true : false}
                                    disabled={true}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div key="packages" className={classes.packages}>
                {Object.values(packages).map((value) => (
                    <PackageComponent
                        key={value.id}
                        pkg={value}
                        buyButtonAction={buyButtonAction}
                        userPackages={profileData.buyerProducts ? profileData.buyerProducts : []}
                        isValid={state.inputs.EMAIL.isValid}
                        email={state.inputs.EMAIL.value}
                        orgId={orgId}
                    />
                ))}
            </div>
        </div>
    );
};

export default PackagesView;
