import * as classes from "./TOS.module.css";

const TOS = (props) => {
  //const { t } = useTranslation();

  return (
    <div
      className={`${classes.tosContainer} font-300`}
      style={props?.styles?.tosContaine}
    >
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        Condiciones de uso de Netscouters TV{" "}
      </div>
      <br />
      <hr />
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Netscouters TV proporciona un servicio de suscripción que permite a
        nuestros suscriptores acceder a partidos en vivo y grabados de
        Netscouters TVonline, así como a otros videos ("contenido del servicio
        de Netscouters TVonline") transmitidos a través de Internet. El servicio
        Netscouters TVonline lo proporciona Icareus Media Services S.L., una
        sociedad finlandesa de responsabilidad limitada. Estos Términos de uso
        rigen su uso de nuestro servicio. Tal como se utiliza en estos Términos
        de uso, "servicio Netscouters TVonline", "nuestro servicio" o "el
        servicio" significa el servicio subcontratado/proporcionado por Icareus
        Media Services S.L. para descubrir y ver el servicio Netscouters
        TVonline, incluidas todas las características y funcionalidades, el
        sitio web y las interfaces de usuario, así como todo el contenido y el
        software asociado con nuestro servicio.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Contáctenos al Correo electrónico: information@netscouters.com
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Dirección: Calle Madrid 2, 28400 Madrid España
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400 `}>Suscripción</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Su suscripción continuará mes a mes, semanal o por servicio hasta que
        finalice. Para utilizar Netscouters TVonline, debe tener acceso a
        Internet y un dispositivo preparado para el servicio Netscouters
        TVonline, y proporcionar un método de pago actual, válido y aceptado,
        que puede actualizar periódicamente ("Método de pago"). A menos que
        cancele su suscripción antes de la próxima fecha de facturación, nos
        autoriza a cargar su próxima tarifa de suscripción a su Método de pago
        (consulte "Cancelación" a continuación). Puede encontrar detalles
        específicos sobre su suscripción al servicio Netscouters TVonline
        visitando nuestro sitio web y haciendo clic en el enlace "Perfil"
        disponible en la parte superior de las páginas del sitio web del
        servicio Netscouters TVonline.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-500 `}>Facturación</div>
      <div className={`${classes.tosTitle} font-400 `}>
        Ciclo de facturación{" "}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Las tarifas de suscripción o pago por evento del servicio de TV de
        Netscouters y cualquier otro cargo en el que pueda incurrir en relación
        con el uso del servicio, como impuestos y posibles tarifas de
        transacción, se cobrarán mensualmente, o per-servicio contratado
        dependiendo de su suscripción a su Método de pago en el día calendario
        correspondiente al comienzo de la parte de pago de su suscripción. En
        algunos casos, su fecha de pago puede cambiar, por ejemplo, si su Método
        de pago no se liquidó correctamente o si su suscripción comenzó en un
        día que no está incluido en un mes determinado. Visite nuestro sitio web
        y haga clic en el enlace "Mis suscripciones" en la página "Perfil" para
        ver su próxima fecha de pago.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400 `}>Métodos de pago </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Paypal es el facilitador de pago del servicio Netscouters TVonline.
        Paypal es un facilitador de pago autorizado por la Autoridad
        internacional. El proceso de pago se realiza en el servicio de pago en
        línea de Paypal y se muestra como el pago recibido en el listado de la
        cuenta bancaria y en la factura. Pagar con Paypal es seguro. Toda la
        información se intercambia a través de conexiones seguras.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        El intercambio ocurre entre el cliente en línea y el servicio
        Netscouters TVonline. El servicio Netscouters TVonline es responsable de
        todas las obligaciones relacionadas con el comercio. Lea más sobre
        Paypal: https://www.paypal.com
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Con Paypal puede pagar su pedido mediante una tarjeta de pago
        (crédito/débito). Se admiten los siguientes métodos de pago: tarjetas de
        pago Visa, Visa Débito, Visa Electron, MasterCard y Débito MasterCard.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Cancelación</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Puede cancelar su suscripción al servicio Netscouters TVonline en
        cualquier momento y seguirá teniendo acceso al servicio Netscouters
        TVonline hasta el final de su período de facturación actual. En la
        medida permitida por la ley aplicable, los pagos no son reembolsables y
        no proporcionamos reembolsos ni créditos por períodos de suscripción de
        meses parciales o contenido del servicio Netscouters TVonline no visto.
        Para cancelar, vaya a la página "Su cuenta" y siga las instrucciones de
        cancelación. Si cancela su membresía, su cuenta se cerrará
        automáticamente al final de su período de facturación actual.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Para ver cuándo se cerrará su cuenta, haga clic en "Mi suscripción" en
        la página "Perfil".{" "}
      </p>{" "}
      <br />
      <p className={`${classes.tosMainTitle_p} `}></p>Cambios en los Planes de
      Precios y Servicios. Podemos cambiar nuestros planes de servicio y el
      precio de nuestro servicio de vez en cuando; sin embargo, cualquier cambio
      de precio o cambio en nuestros planes de servicio se aplicará a usted no
      antes de los 30 días posteriores a la notificación. <br /> <br />
      <div className={`${classes.tosTitle} font-400`}>
        Netscouters TV Servicio en línea
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Debe tener 18 años de edad, o la mayoría de edad en su provincia,
        territorio o país, para convertirse en miembro del servicio Netscouters
        TVonline. Los menores de edad sólo podrán utilizar el servicio bajo la
        supervisión de un adulto.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        El servicio Netscouters TVonline y cualquier contenido visto a través
        del servicio son para su uso personal y no comercial únicamente. Durante
        su suscripción al servicio de Netscouters TVonline, le otorgamos una
        licencia limitada, no exclusiva e intransferible para acceder al
        servicio de Netscouters TVonline y ver el contenido del servicio de
        Netscouters TVonline. Excepto por la licencia limitada anterior, no se
        le transferirá ningún derecho, título o interés.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Usted acepta no utilizar el servicio para actuaciones públicas.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Puede ver el contenido del servicio Netscouters TVonline principalmente
        dentro del país en el que ha establecido su cuenta y solo en las
        ubicaciones geográficas donde ofrecemos nuestro servicio.{" "}
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Usted acepta utilizar el servicio Netscouters TVonline, incluidas todas
        las características y funcionalidades asociadas al mismo, de conformidad
        con todas las leyes, normas y reglamentos aplicables, u otras
        restricciones sobre el uso del servicio o el contenido del mismo. Usted
        acepta no archivar, reproducir, distribuir, modificar, exhibir,
        realizar, publicar, otorgar licencias, crear trabajos derivados, ofrecer
        para la venta o usar (salvo que se autorice explícitamente en estos
        Términos de uso) el contenido y la información contenida u obtenida de a
        través del servicio Netscouters TVonline.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        También acepta no: eludir, eliminar, alterar, desactivar, degradar o
        frustrar ninguna de las protecciones de contenido en el servicio
        Netscouters TVonline; utilizar cualquier robot, u otro medio
        automatizado para acceder al servicio Netscouters TVonline; descompilar,
        realizar ingeniería inversa o desensamblar cualquier software u otros
        productos o procesos accesibles a través del servicio Netscouters
        TVonline; insertar cualquier código o producto o manipular el contenido
        del servicio Netscouters TVonline de cualquier forma; o utilizar
        cualquier método de minería, recopilación o extracción de datos. Además,
        acepta no cargar, publicar, enviar por correo electrónico o enviar o
        transmitir de otro modo ningún material diseñado para interrumpir,
        destruir o limitar la funcionalidad de cualquier software o hardware
        informático o equipo de telecomunicaciones asociado con el servicio
        Netscouters TVonline, incluidos los virus de software. o cualquier otro
        código, archivo o programa informático. Podemos rescindir o restringir
        su uso de nuestro servicio si viola estos Términos de uso o participa en
        un uso ilegal o fraudulento del servicio.{" "}
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        La calidad de visualización del contenido del servicio Netscouters
        TVonline puede variar de un dispositivo a otro y puede verse afectada
        por una variedad de factores, como su ubicación, el ancho de banda
        disponible y/o la velocidad de su conexión a Internet. Usted es
        responsable de todos los cargos de acceso a Internet. Consulte con su
        proveedor de Internet para obtener información sobre los posibles cargos
        por uso de datos de Internet. El tiempo que lleva comenzar a ver el
        contenido del servicio Netscouters TVonline variará según una serie de
        factores, que incluyen su ubicación, el ancho de banda disponible en ese
        momento, el contenido que ha seleccionado y la configuración de su
        dispositivo listo para el servicio Netscouters TVonline.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Contraseñas y acceso a la cuenta
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        El suscriptor que creó la cuenta del servicio Netscouters TVonline y
        cuyo Método de pago se cobra (el "Propietario de la cuenta") tiene
        acceso y control sobre la cuenta del servicio Netscouters TVonline y los
        dispositivos listos para el servicio Netscouters TVonline que se
        utilizan para acceder a nuestro servicio. Para mantener el control sobre
        la cuenta y evitar que alguien acceda a la cuenta (lo que incluiría
        información sobre el historial de visualización de la cuenta), el
        propietario de la cuenta debe mantener el control sobre los dispositivos
        listos para el servicio Netscouters TVonline que se utilizan para
        acceder al servicio y no revelar la contraseña ni los detalles del
        Método de pago asociado con la cuenta a nadie. Usted es responsable de
        actualizar y mantener la precisión de la información que nos proporciona
        en relación con su cuenta. Podemos cancelar su cuenta o poner su cuenta
        en espera para protegerlo a usted, a Netscouters o a nuestros socios del
        robo de identidad u otra actividad fraudulenta.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>Ley que rige </div>
      <p className={`${classes.tosMainTitle_p}`}>
        Estos Términos de uso se regirán e interpretarán de conformidad con las
        leyes de España. Estos términos no limitarán ningún derecho de
        protección al consumidor al que pueda tener derecho en virtud de las
        leyes obligatorias de su país de residencia.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>Atención al cliente</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Para obtener más información sobre nuestro servicio y sus funciones, o
        si necesita ayuda con su cuenta, comuníquese con nuestro Servicio de
        atención al cliente en information@netscouters.com. En caso de conflicto
        entre estos Términos de uso y la información proporcionada por el
        Servicio de atención al cliente u otras partes. de nuestro sitio web,
        estos Términos de uso prevalecerán.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>Supervivencia</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Si alguna disposición o disposiciones de estos Términos de uso se
        consideraran inválidas, ilegales o inaplicables, la validez, legalidad y
        aplicabilidad de las disposiciones restantes permanecerán en pleno vigor
        y efecto.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Cambios en los Términos de uso{" "}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Netscouters puede, de vez en cuando, cambiar estos Términos de uso. Le
        notificaremos al menos 30 días antes de que se le apliquen estos nuevos
        Términos de uso.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        Comunicaciones Electrónicas{" "}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Le enviaremos información relacionada con su cuenta (p. ej.,
        autorizaciones de pago, facturas, cambios de contraseña o Método de
        pago, mensajes de confirmación, avisos) únicamente en forma electrónica,
        por ejemplo, a través de correos electrónicos a su dirección .
      </p>
      <br />
    </div>
  );
};

export default TOS;
