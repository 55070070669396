import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useMyContextFunctions } from "../../contexts/ContextFunctions";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "../../Shared/Hooks/form-hook";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_PASSWORDMATCH,
  VALIDATOR_EMAIL,
  VALIDATOR_REQUIRE,
} from "../../Shared/Validation/Validator";
import * as classes from "../../Shared/Input1/Input1.module.css";
import { countries } from "../../scripts/countries";
import { ToastContainer, toast } from "react-toastify";
import { Input1 } from "../../Shared/Input1/Input1";
import Header from "../../Shared/Header/Header";
import Button from "../../Shared/Button/Button";
import { useMyContext } from "../../contexts/StateHolder";
import { findSubOrganizationSecret } from "../../scripts/dataHandlers";

import * as settingConfig from "../../config_settings.json";
import { createAdminUserToken, createToken } from "../../scripts/utils";

// Renders contentRatings of chosen item

// () => {
//   const [startDate, setStartDate] = useState(new Date());
//   return (
// <DatePicker
//   selected={startDate}
//   onChange={(date) => setStartDate(date)}
//   showTimeSelect
//   timeFormat='HH:mm'
//   timeIntervals={5}
//   timeCaption='time'
//   dateFormat='MMMM d, yyyy h:mm aa'
//   locale='fi'
// />
//   );
// };
const SignUpForm = (props) => {
  const { fields, redirectRoute } = props.settings;

  const { user, UILoading, setUILoading } = useMyContext();

  const [cookies] = useCookies("");

  const [infoMessage, setInfoMessage] = useState("");

  // Bring functions from context
  const { authProcess, registerProcess } = useMyContextFunctions();

  const { t } = useTranslation();

  const history = useHistory();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform
  const [state, InputHandler] = useForm(
    {
      EMAIL: {
        value: "",
        isValid: false,
      },
      PASSWORD: {
        value: "",
        isValid: false,
      },
      PASSWORDCONFIRM: {
        value: "",
        isValid: false,
      },
      FIRSTNAME: {
        value: "",
        isValid: fields.firstName ? false : true,
      },
      LASTNAME: {
        value: "",
        isValid: fields.lastName ? false : true,
      },
      PHONE: {
        value: "",
        isValid: fields.phone ? false : true,
      },
      CITY: {
        value: "",
        isValid: fields.city ? false : true,
      },
      ADDRESS: {
        value: "",
        isValid: fields.address ? false : true,
      },
      COUNTRY: {
        value: "",
        isValid: fields.country ? false : true,
      },
      DATEOFBIRTH: {
        value: "",
        isValid: fields.dataOfBirth ? false : true,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    console.log(props);

    // return;

    let token;

    setUILoading(true);

    if (props.admin) {
      // we need to check if it is superAdmin . If it is superAdmin we need to take info like organizationId and secret from cookies not userObject
      if (props.superAdmin) {
        // secret and org is in cookies
        token = createAdminUserToken(cookies?.supAdOrg, cookies?.supAdSec);
      } else {
        // we need to register a new admin for that child account ->api needs a token to create an admin
        console.log(user);
        console.log(settingConfig.organization.organizationId);

        let key;
        // we need to find secret of child organizationId
        const res = await findSubOrganizationSecret(
          settingConfig.organization.organizationId,
          settingConfig.organization.key,
          user?.organizationId
        );
        if (res?.secret) {
          key = res?.secret;
        }

        token = createAdminUserToken(user?.organizationId, key);

        console.log("res", res);
      }
    }

    try {
      //**** REGISTERATION *****/ // TODO: Can registeration do authentication at the same time and return the token?

      // Do the signUp/login process
      const response = await registerProcess(
        state.inputs,
        props.admin && "ismaccount administrator",
        props.admin && token,

        props.superAdmin
          ? cookies?.supAdOrg
          : props.admin && user?.organizationId
      );

      if (response.data.status === "error") {
        if (props.admin) {
          if (response?.data?.message.includes("already used")) {
            return toast.error("Admin Already Exists", {
              autoClose: 10000,
              position: "top-center",
              closeOnClick: true,
              draggable: true,

              theme: "colored",
            });
          }
        }
        console.log(response.data);
        setInfoMessage(response.message);
        return toast.error(response.data.message, {
          autoClose: 10000,
          position: "top-center",
          closeOnClick: true,
          draggable: true,

          theme: "colored",
        });
      } else if (response.data.status === "ok" && !props.admin) {
        //****AUTHENTICATION *****/

        // If everything was fine on registering, continue to authentication
        const authResponse = await authProcess(
          state.inputs.EMAIL.value,
          state.inputs.PASSWORD.value
        );

        if (authResponse.data.status === "ok") {
          // Change user to redirect route (if given to component configs) or to home route
          history.replace(redirectRoute || props.routes.home);
        } else {
          setInfoMessage(authResponse.data.message);
          console.log(infoMessage);
        }
      } else if (response.data.status === "ok" && props.admin) {
        toast.success("Admin Created Successfully");

        setTimeout(() => {
          history.push(`/${settingConfig.routes.adminUsersList}`);
        }, 1000);
      } else {
        // this means our server has died so need to give appropriate messages

        toast.error(`${t("shared.systemError")}`, {
          autoClose: 3000,
          position: "top-center",
          closeOnClick: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={classes.signUpFormContainer}
      // style={props.styles.signUpFormContainer}
    >
      <div className={`${classes.formPrimary} ${classes.formPrimarySignIn}`}>
        <div className={classes.formSecondary}>
          <form
            className={props.admin ? classes.formBlogAdmin : classes.formBlog}
            onSubmit={formSubmitHandler}
          >
            <Header
              title={
                props.admin
                  ? t("signUpForm.createNewAdmin")
                  : t("signUpForm.title")
              }
              extraClassname
              admin={props.admin}
              showBackButton={true}
            />

            <Input1
              id="EMAIL"
              label={t("signUpBar.inputPlaceholderEmail")}
              placeholder={t("signUpForm.inputPlaceholderEmail")}
              type="text"
              element="input"
              validators={[VALIDATOR_EMAIL()]}
              errorText={t("signUpBar.errorIncorrectEmailAddress")}
              onInput={InputHandler}
              iconName="envelope"
              initialValue={cookies?.userData?.eMail || ""}
              initialValid={cookies?.userData?.eMail ? true : false}
            />

            {fields.firstName ? (
              <Input1
                id="FIRSTNAME"
                label="FIRST NAME"
                placeholder={t("signUpForm.firstName")}
                type="text"
                element="input"
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("signUpForm.askForFirstName")}
                onInput={InputHandler}
                iconName="user"
              />
            ) : null}

            {fields.lastName ? (
              <Input1
                id="LASTNAME"
                label="LAST NAME"
                placeholder={t("signUpForm.lastName")}
                type="text"
                element="input"
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("signUpForm.askForLastName")}
                onInput={InputHandler}
                iconName="user"
              />
            ) : null}

            {fields.phone ? (
              <Input1
                id="PHONE"
                label="PHONE"
                placeholder={t("signUpForm.phone")}
                type="text"
                element="input"
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("signUpForm.askForPhone")}
                onInput={InputHandler}
                iconName="phone"
              />
            ) : null}

            {fields.address ? (
              <Input1
                id="ADDRESS"
                label="ADDRESS"
                placeholder={t("signUpForm.address")}
                type="text"
                element="input"
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("signUpForm.askForAddress")}
                onInput={InputHandler}
                iconName="map-marked"
              />
            ) : null}

            {fields.city ? (
              <Input1
                id="CITY"
                label="CITY"
                placeholder={t("signUpForm.city")}
                type="text"
                element="input"
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("signUpForm.askForCity")}
                onInput={InputHandler}
                iconName="city"
              />
            ) : null}

            {fields.country ? (
              <Input1
                id="COUNTRY"
                label="COUNTRY"
                placeholder={t("signUpForm.country")}
                type="text"
                selectData={countries.map((country) => country.name)}
                element="select"
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("signUpForm.askForCountry")}
                onInput={InputHandler}
                iconName="globe"
              />
            ) : null}

            {fields.dateOfBirth ? (
              <Input1
                id="DATEOFBIRTH"
                label="DATEOFBIRTH"
                placeholder={t("signUpForm.dateOfBirth")}
                type="text"
                element="input"
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("signUpForm.askForDateOfBirth")}
                onInput={InputHandler}
                iconName="birthday-cake"
              />
            ) : null}

            <Input1
              id="PASSWORD"
              label={t("signUpForm.password")}
              placeholder={t("signUpForm.askForPassword")}
              type="Password"
              element="input"
              validators={[VALIDATOR_MINLENGTH(5)]}
              errorText={t("signUpForm.errorPasswordNotLongEnough", {
                count: "5",
              })}
              onInput={InputHandler}
              iconName="lock"
            />

            <Input1
              id="PASSWORDCONFIRM"
              label={t("signUpForm.passwordConfirm")}
              placeholder={t("signUpForm.askForConfirmPassword")}
              type="Password"
              element="input"
              validators={[
                VALIDATOR_PASSWORDMATCH(
                  state.inputs.PASSWORD.value,
                  state.inputs.PASSWORDCONFIRM.value
                ),
              ]}
              errorText={t("signUpForm.errorPasswordsDontMatch")}
              onInput={InputHandler}
              iconName="lock"
            />

            {!props.admin && (
              <div className={`${classes.loginRow} font-100`}>
                <label
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    onChange={() => setIsTermsAccepted((prev) => !prev)}
                    checked={isTermsAccepted}
                  />
                  <Link to={`${props.routes.tos}`}>
                    {t("signUpForm.acceptPrivacy")}
                  </Link>
                </label>
              </div>
            )}

            <Button
              className={classes.loginFormButton}
              disabled={!state.isValid || UILoading || !isTermsAccepted}
              formButton
              inverse
            >
              {UILoading ? t("shared.loading") : t("signUpForm.submitButton")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
