import { useMyContext } from "../../../contexts/StateHolder";
import { useTranslation } from "react-i18next";

import Subscription from "./Subscription/Subscription";
import * as classes from "../../../Shared/Input1/Input1.module.css";
import * as classes2 from "./CurrentSubscription.module.css";
import Header from "../../../Shared/Header/Header";
import { useCallback, useEffect } from "react";
import { getUserTickets, getUser, getPaymentMethods, getPackages } from "../../../scripts/dataHandlers";
import { useCookies } from "react-cookie";
import * as settings from "../../../config_settings.json";

const CurrentSubscription = (props) => {
    // Get user from main profile component
    const { user, setUser, language, paymentMethods, setPaymentMethods, setProfilePackages } = useMyContext();
    const [cookies] = useCookies("");

    const { t } = useTranslation();

    // sending an api requet to find the package type for parent organization
    useEffect(() => {
        async function getPaymentMethodsData() {
            try {
                const responseData = await getPaymentMethods(settings.organization.organizationId);

                if (responseData.status === "ok" && typeof responseData.paymentMethods !== "undefined") {
                    setPaymentMethods(responseData.paymentMethods);
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (paymentMethods.length === 0) {
            getPaymentMethodsData();
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        async function getUserData() {
            try {
                const response = await getUser(cookies?.userData?.userToken, settings.organization.organizationId);
                console.log("products:", response.data);

                if (response?.data?.buyerProducts) {
                    user.buyerProducts = response.data.buyerProducts;
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (user && !user?.buyerProducts) {
            getUserData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    //THIS EFFECT WILL NOW FETCH TICKETS AND SAVE RESPONSE TO USER
    useEffect(() => {
        async function getUserTicketData() {
            try {
                const response = await getUserTickets(settings.organization.organizationId, cookies?.userData?.userToken, language);
                console.log(response.data.data);

                if (response?.data?.data) {
                    //setTicketPurchaseHistory(response.data.data);
                    user.ticketProducts = response.data.data;
                    setUser({ ...user });
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (user && !user?.ticketProducts) {
            getUserTicketData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    const getPackagesData = useCallback(async () => {
        try {
            const responseData = await getPackages(settings.organization.organizationId, language, 0);

            console.log(`packagesData`, responseData);

            if (responseData.status === "ok" && typeof responseData.packages !== "undefined") {
                console.log("set progilePack");
                setProfilePackages(responseData.packages);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        getPackagesData();
    }, []);
    console.log("user", user);

    // If user has any buyerProducts, show contents of subscriptions view. Otherwise show empty placeholder ("You have no subscriptions / products")
    return (
        <div className={classes.formPrimary}>
            <div className={classes.formSecondary}>
                <form className={classes.formBlogAdmin} style={{ gap: "1rem" }}>
                    <Header title={`${t("subscriptions.subTitle")}`} extraClassname style={{ textAlign: "center" }} />

                    {/* SUBSCRIPTIONS */}
                    <Header title={`${t("subscriptions.productPurchases")}`} />
                    {user.buyerProducts?.length > 0 ? (
                        user.buyerProducts.map((product, i) => <Subscription product={product} count={i} />)
                    ) : (
                        <div className={classes2.item}>
                            <div className={`${classes2.itemName} font-400`}>{t("subscriptions.youHaveNoProducts")}</div>
                        </div>
                    )}

                    {/* TICKET PURCHASES */}

                    <Header title={`${t("subscriptions.ticketPurchases")}`} />
                    {user.ticketProducts?.length > 0 ? (
                        user.ticketProducts.map((product, i) => <Subscription product={product} count={i} />)
                    ) : (
                        <div className={classes2.item}>
                            <div className={`${classes2.itemName} font-400`}>{t("subscriptions.youHaveNoTickets")}</div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default CurrentSubscription;
