import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getImageByKey } from "../../../scripts/getImageByKey";
import LoginDropDown from "../../Navigation/LoginDropDown";
import NavBarButton from "../NavBarButton";
import classes from "./HeaderNavBar.module.css";
import * as settings from "../../../config_settings.json";
import NavBar from "../NavBar";
import { useMyContext } from "../../../contexts/StateHolder";
import LoginBTN from "../../Login/LoginBTN";
import { useTranslation } from "react-i18next";
import * as classes1 from "../NavBar.module.css";
import { NavLink } from "react-router-dom";
import LanguageSelect1 from "../../LanguageSelect/LanguageSelect1";

const HeaderNavBar = () => {
  const { user, isResponsiveclose } = useMyContext();
  const { t } = useTranslation();

  return (
    <div>
      <NavBar
        links={
          <>
            <NavBarButton
              route={settings.routes.events}
              name={t("navBar.events")}
            />
            <NavBarButton route={`clubs`} name={t("navBar.clubs")} />

            {/* <NavBarButton route={settings.routes.aboutUs} name={t("footer.aboutUs")} /> */}

            <NavBarButton
              route={settings.routes.contactus}
              name={t("footer.contactUs")}
            />
            <NavBarButton
              route={settings.routes.search}
              icon="FaSearch"
              name={t("navBar.search")}
            />
            <LanguageSelect1 />
          </>
        }
        primaryLinks={
          <div className={`${classes1.navRight} `}>
            {/* // show only in bigger screen */}
            {!isResponsiveclose && (
              <>
                {user?.loggedIn ? (
                  <LoginBTN routes={settings.routes} inverse />
                ) : (
                  <LoginDropDown />
                )}
                {/* // register routes */}
                {!user?.loggedIn && (
                  <LoginBTN routes={settings.routes} signup />
                )}
              </>
            )}
          </div>
        }
        // small screen
        linksMobileLogin={
          !user?.loggedIn && (
            <div className={classes.navRight}>
              <LoginDropDown />
            </div>
          )
        }
        // small screen

        linksMobileSearch={
          <div className={classes.navRight}>
            <NavBarButton route={settings.routes.search} icon="FaSearch" />
          </div>
        }
        // small screen
        linksMobileProfile={
          <div className={classes.navRight}>
            {<LoginBTN routes={settings.routes} />}
          </div>
        }
      />
    </div>
  );
};

export default HeaderNavBar;
