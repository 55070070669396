import * as classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = (props) => {
  return (
    <div className={`${classes.tosContainer} font-300`}>
      <ol>
        <div
          className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
        >
          Privacy Policy
        </div>
        <br />
        <hr />
        <div className={`${classes.tosMainTitle_p} `}>
          {" "}
          <br />
          Netscouters es un proyecto que nace para dar a todos los jugadores del
          deporte base la oportunidad de dar a conocer su (propio) talento
          técnico sin condicionamientos ni barreras de condición social,
          nacionalidad o lugar de nacimiento. Introduciendo datos básicos, y con
          la ayuda de vídeos participarán en la evaluación general realizada
          para la comunidad y por las estadísticas automáticas proporcionadas
          por .A.I..
          <br /> <br />
          Todos tendrán la oportunidad de presentarse directamente a clubs de
          primer nivel que participan en nuestra iniciativa, compartiendo la
          pasión común por los deportes más extendidos y practicados en el
          mundo.
          <br />
          <br />
          Con respecto al uso de los servicios por parte de sus usuarios,
          Netscouters, de conformidad con la legislación
          italiana/española/europea vigente (RGPD 2016 y modificaciones
          posteriores), informa que los datos que llegarán a su posesión serán
          tratados de la siguiente manera:
        </div>
        <br />
        <br />
        <div className={`${classes.tosTitle} font-500 `}>
          Titular y encargado del tratamiento
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          El titular y encargado del tratamiento de los datos facilitados por
          los usuarios es la empresa NETSCOUTERS INTERNATIONAL S.L. domicilio en
          Madrid C/ Madrid 2- 28400 Madrid CIF: B87650867
          <br />
          <br />
        </div>
        <br />
        <div className={`${classes.tosTitle} font-500 `}>
          Finalidad del tratamiento de los datos recabados
          <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Netscouters recopila datos para ofrecer mejores servicios a todos sus
          usuarios y facilitar el uso del sitio y el potencial conectado al
          mismo, tratando de eliminar gradualmente cualquier obstáculo que
          dificulte su uso (cualquier problema lingüístico, selección de
          contenido en función de rangos de edad, etc.) y para mejorar las
          funciones.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Legitimación <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Su consentimiento para recibir nuestros servicios para participar en
          la plataforma netscouters.
          <br />
          <br />
        </div>
        <div className={`${classes.tosTitle} font-500 `}>
          Modalidades de tratamiento y uso de los datos recopilados <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Netscouters ofrece un servicio destinado a la señalación de menores de
          edad y, por tanto, los datos de los mismos sólo podrán ser tratados
          previo y expreso consentimiento de uno de los padres, del tutor legal
          o cualquier autorización del mismo hacia terceros. Los datos de la
          cuenta para acceder a la aplicación y la información del jugador, se
          visualizarán inmediatamente después del registro para permitir el uso
          inmediato del servicio, pero se confirmarán solo después de recibir el
          documento de liberación enviado en el momento del registro. Después de
          8 días desde el momento de usar la aplicación, si no se recibe el
          documento de liberación, la cuenta se suspenderá en espera del
          documento en sí. En ausencia de este previo consentimiento expreso, la
          persona o el menor no podrá acceder al sitio y tampoco se podrá
          visualizar su ficha y a los servicios ofrecidos en el mismo. Dicho
          esto, los datos recabados son exclusivamente los facilitados por el
          usuario y serán tratados de las siguientes formas:
          <br />
          <br />
        </div>
        <div className={`${classes.tosTitle} font-500 `}>
          Datos proporcionados por el usuario <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Los servicios prestados por los usuarios de Netscouters requieren
          obligatoriamente la creación de una cuenta en el sitio: El proceso de
          alta del jugador puede ser hecho por el club de pertenecía debidamente
          autorizado por el tutor a través de nuestro formulario , facilitando
          los datos personales propios y del menor, así como su dirección de
          correo electrónico. Una vez que se complete el registro, se enviará
          automáticamente un correo electrónico a la dirección del padre, tutor
          o persona autorizada indicada en el procedimiento de registro. Después
          de crear la cuenta, se le pedirá que ingrese los datos personales del
          usuario/netscouters y del menor (por parte del club), como nombre,
          apellido, año de nacimiento, peso y altura, una foto (y otros datos
          técnicos) ; esta información adicional es necesaria para completar el
          registro y lograr correctamente los objetivos finales, para introducir
          al joven en un equipo de primer nivel. El perfil del menor será
          visible públicamente solo para los usuarios que se registren para un
          equipo en particular y no para todos los equipos que participan en la
          iniciativa.
          <br />
          <br />
        </div>
        <div className={`${classes.tosTitle} font-500 `}>
          Datos recabados como consecuencia de la utilización de los servicios
          por parte del usuario <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Netscouters se reserva el derecho de recopilar información sobre los
          servicios que usa y cómo los usas (por ejemplo, verificar cuando
          visita un sitio web anunciado en Netscouters o monitorear cuando
          interactúa con los anuncios y el contenido del sitio).
          <br />
          <br />
          El tratamiento de los datos recabados lo realiza Netscouters mediante
          una actividad de recogida, registro, organización, conservación,
          tratamiento, modificación, selección, extracción, comparación, uso,
          interconexión, bloqueo, comunicación, cancelación y destrucción,
          conforme a lo dispuesto en la la legislación nacional
          italiana/española/europea actualmente en vigor.
          <br />
          <br />
          Las operaciones normalmente serán realizadas por Netscouters con la
          ayuda de herramientas electrónicas o automatizadas.
          <br />
          <br />
          Netscouters utiliza los datos recopilados para administrar, proteger y
          mejorar la funcionalidad del sitio y el servicio ofrecido, para
          proteger a sus usuarios y brindarles contenido personalizado
          <br />
          <br />
          En cualquier caso, Netscouters siempre solicitará el consentimiento
          del padre o tutor para utilizar los datos relativos a la persona que
          se registre para fines distintos a los establecidos en las presentes
          normas de protección de la privacidad.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Transparencia
          <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Los usuarios registrados de Netscouters podrán:
          <br />
          <br />
          Ver y modificar los datos relacionados con su cuenta visible en el
          sitio en cualquier momento; configurar el navegador para que bloquee
          todas las cookies, incluidas las asociadas a los servicios ofrecidos,
          incluso si ello pudiera impedir el funcionamiento óptimo del servicio.
          <br />
          <br />
          Los usuarios registrados en Netscouters a través de un club (sujeto a
          autorización) dependerán del mismo club para los cambios relativos a
          su perfil. No obstante, pueden configurar el navegador para que
          bloquee todas las cookies, incluidas las asociadas a los servicios
          ofrecidos, incluso si esto pudiera impedir el funcionamiento óptimo
          del servicio.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Información que el usuario comparte
          <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Se recuerda al usuario que la información compartida en el sitio de
          manera pública podría ser utilizada por terceros, incluidos los
          motores de búsqueda. En todo caso, el usuario siempre podrá retirar
          los contenidos previamente compartidos.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Acceso a datos personales y modificaciones
          <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Cuando un usuario utiliza el servicio ofrecido por Netscouters,
          siempre podrá acceder a sus datos personales introducidos al crear la
          cuenta y, en su caso, actualizarlos o eliminarlos, excepto en el caso
          en que los datos sean introducidos por el club (sujeto a autorización)
          que será responsable de su actualización o posible eliminación o si
          deben ser conservados por Netscouters para fines comerciales o legales
          legítimos en cumplimiento de las disposiciones sobre el tratamiento y
          uso de los mismos. En caso de actualización de datos personales, se le
          podrá solicitar al usuario que verifique su identidad antes de
          proceder con los cambios.
          <br />
          <br />
          Netscouters pondrá en marcha todas las actividades necesarias para
          gestionar el servicio ofrecido con el fin de proteger la difusión y/o
          destrucción accidental o maliciosa de la información proporcionada por
          los usuarios. Por este motivo, después de eliminar su información
          personal, Netscouters no eliminará de inmediato las copias restantes
          de sus servidores activos y sistemas de respaldo. Por el contrario, en
          caso de cancelación del usuario del sitio por cualquier motivo,
          Netscouters se sentirá libre de eliminar todos los datos personales
          relacionados con el mismo.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Difusión e intercambio de datos <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Netscouters no proporciona datos personales y otra información
          relacionada con sus usuarios a empresas, organizaciones y terceros, a
          menos que ocurra una de las siguientes situaciones:
          <br />
          <br />
        </div>
        <div className={`${classes.tosTitle} font-500 `}>
          Consentimiento del usuario <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Netscouters se reserva el derecho de proporcionar datos personales de
          sujetos que hayan completado el procedimiento de creación de cuenta a
          empresas, organizaciones y terceros solo con el consentimiento previo
          del usuario, que se proporciona al registrarse.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Gestión de cuentas por administradores de dominio <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          En caso de que Netscouters decida gestionar las cuentas de los
          usuarios a través de un administrador de dominio, este último tendrá
          acceso a los datos relativos a las cuentas gestionadas (incluida la
          dirección de correo electrónico y otros datos). En tales casos, el
          administrador del dominio puede:
          <br />
          <br />
          Ver estadísticas relacionadas con la cuenta del usuario; cambiar la
          contraseña de la cuenta del usuario, en el caso de que exista la
          sospecha de que terceros no relacionados han realizado intentos de
          entrometerse en la cuenta correspondiente, notificando al usuario del
          cambio exitoso; suspender o cerrar la cuenta en caso de incumplimiento
          por parte del usuario de las condiciones de uso y acceso al servicio
          (Términos de uso); acceder y/o conservar la información almacenada en
          la cuenta del usuario; recibir información sobre su cuenta para
          cumplir con una ley o reglamento, o para cumplir con solicitudes que
          surjan de un proceso legal en curso o de otra manera de una fuente
          gubernamental o estatal, si es legítima y fundada; limitar su
          capacidad para eliminar o cambiar la información o la configuración de
          privacidad.
          <br />
          <br />
          Para más información, Netscouters se remite a la política de
          privacidad del administrador del dominio
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Razones legales
          <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Netscouters proporcionará información personal sobre sus usuarios a
          empresas, organizaciones e individuos externos si cree de buena fe que
          el acceso, uso, protección o divulgación de dicha información es
          razonablemente necesario para:
          <br />
          <br />
          Cumplir con las leyes o regulaciones aplicables, procedimientos
          legales pendientes o solicitudes de agencias gubernamentales o
          estatales; aplicar las condiciones vigentes para el acceso y disfrute
          del servicio ofrecido, incluidas las investigaciones sobre posibles
          infracciones: detectar, prevenir o gestionar de otro modo actividades
          fraudulentas o problemas de carácter técnico o de seguridad. proteger
          los derechos, la propiedad o la seguridad de Netscouters, sus usuarios
          y el público, dentro de los límites previstos por la ley. En caso de
          que Netscouters participe en una fusión, adquisición o venta de
          acciones, la empresa continuará garantizando la confidencialidad de la
          información personal y posiblemente notificará a los usuarios
          interesados sobre la transferencia de su información personal o la
          aplicación de reglas nuevas y diferentes sobre privacidad.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Seguridad de la información proporcionada
          <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Netscouters tomará todas las precauciones necesarias para proteger su
          sitio y sus usuarios registrados del acceso no autorizado a la
          información que posee, así como de cualquier intento de alterar,
          divulgar o destruir dicha información sin autorización.
          <br />
          <br />
        </div>
        <div className={`${classes.tosTitle} font-500 `}>
          Ampliación de la protección <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Las reglas de privacidad son válidas para todos los servicios
          ofrecidos por Netscouters, incluidos los ofrecidos en otros sitios
          (por ejemplo, servicios de publicidad).
          <br />
          <br />
          Estas políticas de privacidad no se aplican a los servicios ofrecidos
          por otras empresas o personas, incluidos los productos o sitios que
          pueden aparecer en los resultados de búsqueda y que pueden contener
          referencias a las actividades y servicios ofrecidos por Netscouters;
          ni para otros sitios a los que se refieren los servicios ofrecidos por
          Netscouters o para los sitios y temas que anuncian la actividad de
          Netscouters.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Aplicación de las reglas <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Netscouters verifica el cumplimiento de las normas de privacidad
          identificadas de forma regular y periódica. Si Netscouters recibe
          quejas formales por escrito, será responsabilidad de la empresa
          contactar a las personas que las presentaron para tratar de resolver
          los problemas planteados.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Cambios
          <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Estas normas de privacidad pueden estar sujetas a cambios ocasionales
          que, sin embargo, no pueden reducir el nivel de protección garantizado
          a los usuarios por la legislación vigente. Los cambios se publicarán
          en esta página y, si son de gran importancia, se destacarán
          adecuadamente para que los usuarios puedan conocerlos. Las versiones
          anteriores de la política de privacidad seguirán archivadas para su
          consulta.
          <br />
          <br />
        </div>

        <div className={`${classes.tosTitle} font-500 `}>
          Destinatarios de las normas <br />
          <br />
        </div>
        <div className={`${classes.tosMainTitle_p} `}>
          Debido al tipo de servicio ofrecido, también destinado a menores de
          edad, Netscouters reitera que es indispensable que la creación de la
          cuenta y, en consecuencia, el otorgamiento de la relativa autorización
          para el tratamiento de datos sea realizado por una persona mayor de
          edad en posesión de los requisitos legales necesarios (padres o
          tutores o autorizados por los mismos) que formarán parte del contrato
          estipulado con Netscouters y, en consecuencia, destinatario de las
          disposiciones contenidas en este documento y en las condiciones
          generales de acceso y uso del servicio ( Términos de uso); y, como
          tal, responsable de cualquier conducta que lleve a cabo el menor bajo
          su tutela.
          <br />
          <br />
        </div>

        <br />
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
