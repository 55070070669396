import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Shared/Button/Button";
import { useHistory } from "react-router-dom";
import colors from "../scripts/colors";
import { useTranslation } from "react-i18next";

export default function RegisterConfirmation() {
  const history = useHistory();

  const { t } = useTranslation();

  const homePageHandler = () => {
    history.replace("/");
  };
  return (
    <div
      className="font-500"
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        margin: "50px 0",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="font-900"
        style={{ textAlign: "center", color: colors.brand }}
      >
        {t("registerConfirmation.thanks")}
      </div>

      <FontAwesomeIcon
        icon="check"
        className="font-900"
        style={{ display: "flex", alignSelf: "center", color: colors.brand }}
      />
      <div>{t("registerConfirmation.text1")}</div>
      <div>{t("registerConfirmation.text2")}</div>
      <div>{t("registerConfirmation.text3")}</div>
      <div>{t("registerConfirmation.text4")}</div>

      <Button onClick={homePageHandler}>
        {t("registerConfirmation.homepage")}
      </Button>
    </div>
  );
}
