import React, { useEffect, useState } from "react";
import {
  VALIDATOR_NONE,
  VALIDATOR_NUMBER,
  VALIDATOR_REQUIRE,
} from "../../../Shared/Validation/Validator";

import * as classes from "../../../Shared/Input1/Input1.module.css";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../contexts/StateHolder";
import { useForm } from "../../../Shared/Hooks/form-hook";
import { Input1 } from "../../../Shared/Input1/Input1";
import Header from "../../../Shared/Header/Header";
import { useParams } from "react-router-dom";
import {
  createNewGame,
  createTicketAccess,
  getSingleGame,
  updateSingleGame,
  getAccountSettings,
} from "../../../scripts/dataHandlers";
import Loader from "react-loader-spinner";
import { addZeroes } from "../../../scripts/utils";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../Shared/Button/Button";

import { useCookies } from "react-cookie";

export default function AddNewEvent() {
  const { goBackToPrevious, user } = useMyContext();

  const [cookies] = useCookies([""]);

  const { t } = useTranslation();
  const history = useHistory();

  const { orgId, eventId } = useParams();

  // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform

  const [state, InputHandler] = useForm(
    {
      GAME_NAME: {
        value: "",
        isValid: false,
      },
      // GAME TYPE BASICALLY MEANS WHETHER IT IS PRACTISE OR tournamet game
      GAME_TYPE: {
        value: "",
        isValid: false,
      },
      CLUB_NAME: {
        value: "",
        isValid: true,
      },
      // OPPONENT IS BASICALLY GETTING GAMES BASED ON TEAM ID ->TEAM ID IS SELECTED AFTER GETTING CLUB-ID FROM CLUB_NAME PARAMS
      OPPONENT: {
        value: "",
        isValid: false,
      },
      HOME_TEAM_LOGO_URL: {
        value: "",
        isValid: true,
      },
      AWAY_TEAM_LOGO_URL: {
        value: "",
        isValid: true,
      },
      TEAM_NAME: {
        value: "",
        isValid: false,
      },
      TOURNAMENT_NAME: {
        value: "",
        isValid: true,
      },
      DESCRIPTION: {
        value: "",
        isValid: false,
      },
      START_TIME: {
        value: "",
        isValid: true,
      },
      DURATION: {
        value: "",
        isValid: true,
      },

      TICKET: {
        value: "",
        isValid: false,
      },
      PRICE: {
        value: "",
        isValid: true,
      },

      TICKET_QUANTITY: {
        value: 1,
        isValid: true,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );

  const [editEventItem, setEditEventItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);

  const [gameStartTime, setGameStartTime] = useState(null);

  // getting event duration on edit page
  const [editGameDurationValue, seteditGameDurationValue] = useState(null);

  const [userClubName, setUserClubName] = useState(null);

  const [adminSettingSubmerchantId, setAdminSettingSubmerchantId] =
    useState(null);

  const GAME_TYPE_LIST = [
    { value: "p_game", label: `${t("addNewEvent.practiseGame")}` },
    { value: "t_game", label: `${t("addNewEvent.tournament")}` },
  ];

  useEffect(() => {
    if (orgId && eventId) {
      setInitLoading(true);
      // we will send an api request to get event info data

      // edit event start here

      const fetchData = async () => {
        // const res = await getSingleEvent(orgId, eventId);
        const res = await getSingleGame(
          orgId,
          eventId,
          cookies?.userData?.userToken
        );
        console.log("resSingle", res);
        setEditEventItem(res?.game);
        // we also set the edit duration here
        let currentEventDuration = res?.game?.event?.durationPublic / 60;

        if (currentEventDuration) {
          currentEventDuration = durationDefaultValues.find(
            (el) => el?.valueInMinutes === currentEventDuration
          );
          seteditGameDurationValue(currentEventDuration);
        }
        console.log("currentEventDuration", currentEventDuration);

        setInitLoading(false);
      };
      fetchData();
    } else {
      setEditEventItem(null);
      setInitLoading(true);
      // settimeout is for saking sure state change happens when user moves from edit -> add

      setTimeout(() => {
        setInitLoading(false);
      }, 1);
    }
  }, [orgId, eventId]);

  console.log("cookies", cookies);

  useEffect(() => {
    if (cookies?.userData?.userOrganizationName) {
      setUserClubName(cookies?.userData?.userOrganizationName);
    }
  }, [cookies?.userData?.userOrganizationName]);

  useEffect(() => {
    if (state.inputs.TICKET.value === "free") {
      setLoading(true);

      state.inputs.TICKET_QUANTITY.isValid = true;

      // fucking  react stuff to change state. there might be a better way to do this
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [state?.inputs?.TICKET?.value]);

  const durationDefaultValues = [
    { value: 1, label: `30 ${t("addNewGame.minutes")}`, valueInMinutes: 30 },
    { value: 2, label: `45 ${t("addNewGame.minutes")}`, valueInMinutes: 45 },
    { value: 3, label: `1 ${t("addNewGame.hour")}`, valueInMinutes: 60 },
    {
      value: 4,
      label: `1 ${t("addNewGame.hour")} 30 ${t("addNewGame.minutes")}`,
      valueInMinutes: 90,
    },
    { value: 5, label: `2 ${t("addNewGame.hours")}`, valueInMinutes: 120 },
    {
      value: 6,
      label: `2 ${t("addNewGame.hours")} 30 ${t("addNewGame.minutes")}`,
      valueInMinutes: 150,
    },
    { value: 7, label: `3 ${t("addNewGame.hours")}`, valueInMinutes: 180 },
  ];

  const ticketDefaultValues = [
    { value: "free", label: `${t("addNewEvent.free")}`, price: 0 },
    { value: "lite", label: `${t("addNewEvent.lite")}`, price: 3.9 },
    { value: "normal", label: `${t("addNewEvent.normal")}`, price: 5.9 },
    { value: "normal2", label: `${t("addNewEvent.normal2")}`, price: 6.9 },
    { value: "premium", label: `${t("addNewEvent.premium")}`, price: 8.9 },
  ];
  const ticketDefaultValue = [
    { value: "free", label: `${t("addNewEvent.free")}` },
    { value: "paid", label: `${t("addNewEvent.paid")}` },
  ];

  const getTicketValue = (event) => {
    return getTicket(event)[0]?.value;
  };

  const getTicketLabel = (event) => {
    if (!event) return;

    getTicket(event);
    return getTicket(event)[0]?.label;
  };

  const getTicket = (event) => {
    let price =
      typeof event === "number"
        ? 0
        : event?.accessControls[0].accessControlData.price;
    return ticketDefaultValues.filter((ticket) => ticket.price === price);
  };

  const getTicketPrice = (value) => {
    return ticketDefaultValues.filter((ticket) => ticket.value === value)[0]
      .price;
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    let d = state.inputs.DURATION.value;

    let durationInMinutes = 60; // default: one hour

    switch (d) {
      case 1:
        durationInMinutes = 30;
        break;
      case 2:
        durationInMinutes = 45;
        break;
      case 3:
        durationInMinutes = 60;
        break;
      case 4:
        durationInMinutes = 90;
        break;
      case 5:
        durationInMinutes = 120;
        break;
      case 6:
        durationInMinutes = 150;
        break;
      case 7:
        durationInMinutes = 180;
        break;
      default:
    }
    let durationInSeconds = durationInMinutes * 60;

    let durPublic = durationInSeconds;
    let dur = durPublic + 20 * 60; // 20 minutes extra for the time prior to public start time

    let event = {
      name: {
        en_US: state.inputs.GAME_NAME.value,
      },
      description: {
        en_US: state.inputs.DESCRIPTION.value,
      },
      defaultLanguage: "en_US", // dont now if we need to ask user for these
      startTimePublic: state.inputs.START_TIME.value.getTime(),
      startTime: state.inputs.START_TIME.value.getTime() - 20 * 60 * 1000, // 20 minutes prior to public start time
      durationPublic: durPublic,
      duration: dur,
      homeTeamId: state.inputs.TEAM_NAME.value * 1 || "",
      homeTeamName: state.inputs.TEAM_NAME.value,

      awayTeamId: state.inputs.OPPONENT.value * 1 || "",
      awayTeamName: state.inputs.OPPONENT.value,
      isOfficial: false, // true if season game

      // no idea about the below list
      publishingRecording: true,
      recordingDownloadable: false,
      displayScores: false,

      // externalId is gameId which we dont need here
      // pass empty string here (expected by)
      externalId: "",

      // we are passing random levelId andthinking it will not match with any levelid from nodeon
      levelId: "",

      levelName: "",

      seriesName: state.inputs.TOURNAMENT_NAME?.value
        ? state.inputs.TOURNAMENT_NAME?.value
        : "",

      seasonId: new Date().getFullYear(),
      seasonName: new Date().getFullYear(),

      // we also need to send hometeamlogourl and awayteamlogourl

      homeTeamLogoUrl: state.inputs.HOME_TEAM_LOGO_URL.value,
      awayTeamLogoUrl: state.inputs.AWAY_TEAM_LOGO_URL.value,
      isTournamentGame: state.inputs.GAME_TYPE.value === "t_game" && true,
    };
    console.log("event", event);

    let newEvent = {
      game: event,
      // cookies?.supAdOrg  IS FOR SUPER ADMIN STUFF(so if there is super admin we have to take org from cookies instead of user Object)
      organizationId: cookies?.supAdOrg
        ? cookies?.supAdOrg
        : user?.organizationId || cookies?.userData?.userOrganizationId,

      packageId: 0,
    };

    if (editEventItem && orgId && eventId) {
      newEvent.gameId = eventId;
    }

    setRequestLoading(true);
    console.log(state, event);

    // we also need to check for ticket Acccess control and that is some other api called createTicketAccess

    try {
      let res;

      if (editEventItem && orgId && eventId) {
        res = await updateSingleGame(newEvent, cookies?.userData?.userToken);
      } else {
        res = await createNewGame(newEvent, cookies?.userData?.userToken);
      }
      console.log(res, "created Game");

      if (res?.data?.status === "ok") {
        if (!editEventItem) {
          // we will always create new and TICKET.value must be yes
          if (state.inputs.TICKET.value !== "free") {
            let ticketObject = {
              // cookies?.supAdOrg  IS FOR SUPER ADMIN STUFF(so if there is super admin we have to take org from cookies instead of user Object)
              organizationId: cookies?.supAdOrg
                ? cookies?.supAdOrg
                : user?.organizationId || cookies?.userData?.userOrganizationId,

              // eventId: res?.data?.game?.eventId,
              // always pass gameId cause they dont work properly with eventId for now
              gameId: res?.data?.game?.gameId,
              accessControl: {
                accessControlType: 6,
                accessControlData: {
                  price: addZeroes(state.inputs.PRICE.value),
                  tax: 10,
                  validity: 1000,
                  maximumQuantity: Math.round(
                    state.inputs.TICKET_QUANTITY.value
                  ),
                  availableTo:
                    state.inputs.START_TIME.value.getTime() +
                    30 * 24 * 60 * 60 * 1000,
                },
              },
            };
            try {
              const res11 = await createTicketAccess(
                ticketObject,
                cookies?.userData?.userToken
              );

              console.log("res11", res11);
            } catch (error) {}
          }
        }

        let localTimeout = 1000;

        toast.success(
          editEventItem
            ? t("addNewEvent.editedSuccess")
            : t("addNewEvent.createSuccess")
        );

        setTimeout(() => {
          setRequestLoading(false);

          history.push(
            `/admin/upcomingEvent/${
              cookies?.supAdOrg
                ? cookies?.supAdOrg
                : user?.organizationId * 1 ||
                  cookies?.userData?.userOrganizationId * 1
            }/${res?.data?.game?.gameId}`
          );
        }, localTimeout);
      } else {
        console.log(res.response);
        setRequestLoading(false);
        if (
          res?.response?.status === 400 &&
          res?.response?.data?.message === "no-channels-available"
        ) {
          return toast.error(t("addNewEvent.noFreeChannels"));
        } else {
          return toast.error(t("addNewEvent.somethingWrong"));
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  console.log(state.inputs.TICKET.value, "new");
  return (
    <>
      {!initLoading ? (
        <div className="loginViewContainer">
          <div className={`${classes.formPrimary} ${classes.formPrimary2}`}>
            <div className={classes.formSecondary}>
              <form
                className={classes.formBlogAdmin}
                onSubmit={formSubmitHandler}
              >
                <Header
                  title={
                    editEventItem
                      ? t("addNewEvent.editGame")
                      : t("addNewEvent.addNewGame")
                  }
                  extraClassname
                  style={{ justifyContent: !editEventItem && "center" }}
                  admin={editEventItem ? true : false}
                />
                <p
                  className={`font-300 regular`}
                  style={{
                    color: "black",
                  }}
                >
                  {t("addNewGame.newGameInfo")}
                </p>
                <Input1
                  id="CLUB_NAME"
                  type="text"
                  element="input"
                  validators={[VALIDATOR_REQUIRE()]}
                  label={t("addNewSubOrganization.clubName")}
                  placeholder={t("addNewSubOrganization.clubName")}
                  errorText={t("addNewSubOrganization.invalidClubName")}
                  onInput={InputHandler}
                  defaultValue={{
                    label: userClubName,
                    value: "",
                  }}
                  initialValue={userClubName}
                  initialValid={true}
                  disabled
                />
                <Input1
                  id="GAME_TYPE"
                  type="text"
                  element="select_dropdown"
                  validators={[VALIDATOR_REQUIRE()]}
                  // label={t('addNewSubOrganization.clubName')}
                  label={t("addNewSubOrganization.gameType")}
                  placeholder={t("addNewSubOrganization.gameType")}
                  errorText={t("addNewSubOrganization.invalidGameType")}
                  onInput={InputHandler}
                  selectData={GAME_TYPE_LIST}
                  // first check whether it is tounament game or not
                  defaultValue={{
                    label:
                      (editEventItem?.isTournamentGame && `Tournament Game`) ||
                      (editEventItem?.isOfficial &&
                        `${t("addNewEvent.seasonGame")}`) ||
                      (editEventItem &&
                        !editEventItem?.isOfficial &&
                        `${t("addNewEvent.practiseGame")}`) ||
                      "",
                    value: editEventItem?.homeTeamId || "",
                  }}
                  initialValue={
                    (editEventItem?.isTournamentGame && "t_game") ||
                    (editEventItem?.isOfficial && "s_game") ||
                    (editEventItem && !editEventItem?.isOfficial && "p_game") ||
                    ""
                  }
                  initialValid={
                    editEventItem?.isTournamentGame ||
                    editEventItem?.isOfficial ||
                    (editEventItem && !editEventItem.isOfficial)
                      ? true
                      : false
                  }
                  disabled={
                    typeof editEventItem?.isOfficial === "undefined"
                      ? false
                      : true
                  }
                />
                <Input1
                  id="TEAM_NAME"
                  type="text"
                  element={"input"}
                  validators={[VALIDATOR_REQUIRE()]}
                  // label={t('addNewSubOrganization.clubName')}
                  label={t("addNewSubOrganization.teamName")}
                  placeholder={t("addNewSubOrganization.teamName")}
                  errorText={t("addNewSubOrganization.invalidTeamName")}
                  onInput={InputHandler}
                  defaultValue={{
                    label: editEventItem?.homeTeamName || "",
                    value: editEventItem?.homeTeamId || "",
                  }}
                  // also need to check if these are select_dropdown or input component
                  initialValue={editEventItem?.homeTeamName}
                  initialValid={
                    editEventItem?.homeTeamName || editEventItem?.homeTeamId
                      ? true
                      : false
                  }
                />
                <Input1
                  id="OPPONENT"
                  type="text"
                  element={"input"}
                  validators={[VALIDATOR_REQUIRE()]}
                  // label={t('addNewSubOrganization.clubName')}

                  errorText={t("addNewSubOrganization.invalidOpponent")}
                  label={t("addNewSubOrganization.opponent")}
                  placeholder={t("addNewSubOrganization.opponent")}
                  onInput={InputHandler}
                  defaultValue={{
                    label: editEventItem?.awayTeamName || "",
                    value: editEventItem?.awayTeamId || "",
                  }}
                  // also need to check if these are select_dropdown or input component
                  initialValue={
                    editEventItem?.awayTeamName && editEventItem?.awayTeamName
                  }
                  initialValid={
                    editEventItem?.awayTeamName || editEventItem?.awayTeamId
                      ? true
                      : false
                  }
                />
                {(state?.inputs?.GAME_TYPE.value === "t_game" ||
                  editEventItem?.isTournamentGame) && (
                  <>
                    <Input1
                      id="TOURNAMENT_NAME"
                      type="text"
                      element={"input"}
                      validators={[VALIDATOR_NONE()]}
                      placeholder={t("addNewEvent.tournamentName")}
                      label={t("addNewEvent.tournamentName")}
                      onInput={InputHandler}
                      initialValue={
                        editEventItem?.seriesName && editEventItem?.seriesName
                      }
                      initialValid={true}
                    />
                    <p
                      className="font-100 protrebleheavy"
                      style={{
                        padding: "0rem 0rem 0.2rem 1rem",
                      }}
                    >
                      {t("addNewEvent.logoUrlFormat")}{" "}
                      https://source.unsplash.com/random/?Cryptocurrency&1
                    </p>

                    <Input1
                      id="HOME_TEAM_LOGO_URL"
                      type="text"
                      element={"input"}
                      validators={[VALIDATOR_NONE()]}
                      placeholder={t("addNewEvent.homeTeamLogoURL")}
                      label={t("addNewEvent.homeTeamLogoURL")}
                      onInput={InputHandler}
                      initialValue={
                        editEventItem?.homeTeamLogoUrl &&
                        editEventItem?.homeTeamLogoUrl
                      }
                      initialValid={true}
                    />
                    <Input1
                      id="AWAY_TEAM_LOGO_URL"
                      type="text"
                      element={"input"}
                      validators={[VALIDATOR_NONE()]}
                      placeholder={t("addNewEvent.awayTeamLogoURL")}
                      label={t("addNewEvent.awayTeamLogoURL")}
                      onInput={InputHandler}
                      initialValue={
                        editEventItem?.awayTeamLogoUrl &&
                        editEventItem?.awayTeamLogoUrl
                      }
                      initialValid={true}
                    />
                  </>
                )}
                {loading ? (
                  <>
                    <div className="display-flex-center">
                      {" "}
                      <Loader type="TailSpin" color="#161eaf" />{" "}
                    </div>
                  </>
                ) : (
                  <Input1
                    id="START_TIME"
                    placeholder={t("loginForm.askForPassword")}
                    type="date_time"
                    element="input"
                    validators={[VALIDATOR_REQUIRE()]}
                    label={t("addNewGame.startTime")}
                    errorText={t("addNewGame.invalidstartTime")}
                    onInput={InputHandler}
                    required={true}
                    // NOTE: You cannot currently edit start times or duration of an existing event! If the start times need to be changed, you must first delete the existing event and then create a new one with the new times.
                    disabled={
                      !state?.inputs?.GAME_TYPE.value ||
                      editEventItem?.event?.duration
                    }
                    initialValue={
                      (gameStartTime && new Date(gameStartTime)) ||
                      (editEventItem?.startTime &&
                        new Date(editEventItem?.startTime))
                    }
                    initialValid={
                      (gameStartTime && new Date(gameStartTime)) ||
                      (editEventItem?.startTime &&
                        new Date(editEventItem?.startTime))
                        ? true
                        : false
                    }
                  />
                )}
                <Input1
                  id="GAME_NAME"
                  label={t("addNewGame.gameName")}
                  errorText={t("addNewGame.invalidGameName")}
                  placeholder={t("loginForm.askForEmail")}
                  type="text"
                  element="input"
                  validators={[VALIDATOR_REQUIRE()]}
                  onInput={InputHandler}
                  initialValue={
                    editEventItem
                      ? editEventItem?.event?.name[
                          editEventItem?.event?.eventLanguages
                        ]
                      : ""
                  }
                  initialValid={
                    editEventItem?.event?.name[
                      editEventItem?.event?.eventLanguages
                    ]
                      ? true
                      : false
                  }
                />
                <Input1
                  id="DESCRIPTION"
                  placeholder={t("loginForm.askForPassword")}
                  type="text"
                  element="textarea"
                  validators={[VALIDATOR_REQUIRE()]}
                  label={t("addNewGame.description")}
                  errorText={t("addNewGame.invalidDescription")}
                  onInput={InputHandler}
                  initialValue={
                    editEventItem
                      ? editEventItem?.event?.description[
                          editEventItem?.event?.eventLanguages
                        ]
                      : ""
                  }
                  initialValid={
                    editEventItem?.event?.description[
                      editEventItem?.event?.eventLanguages
                    ]
                      ? true
                      : false
                  }
                />
                {loading ? (
                  <>
                    <div className="display-flex-center">
                      <Loader type="TailSpin" color="#161eaf" />
                    </div>
                  </>
                ) : (
                  <Input1
                    id="DURATION"
                    placeholder={t("addNewGame.duration")}
                    type="duration_picker"
                    element="select_dropdown"
                    validators={[VALIDATOR_REQUIRE()]}
                    label={t("addNewGame.duration")}
                    labelInfo={t("addNewGame.durationInfo")}
                    errorText={t("addNewGame.invalidduration")}
                    onInput={InputHandler}
                    required={true}
                    selectData={durationDefaultValues}
                    defaultValue={{
                      label:
                        editEventItem && editGameDurationValue
                          ? editGameDurationValue?.label
                          : `2 ${t("addNewGame.hours")}`,
                      value:
                        editEventItem && editGameDurationValue
                          ? editGameDurationValue?.value
                          : 5,
                    }}
                    disabled={editEventItem && editGameDurationValue}
                    initialValue={
                      editEventItem && editGameDurationValue
                        ? editGameDurationValue?.value
                        : 5
                    }
                    // initial valid is true cause we have selected 2 hours as default value
                    initialValid={true}
                  />
                )}
                {/* // access comtrol */}
                {/* WILL BE REWRITTEN */}
                <Input1
                  id="TICKET"
                  label={t("addNewEvent.ticketRequired")}
                  placeholder={t("addNewEvent.ticketRequired")}
                  type="text"
                  element="select_dropdown"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText={t("addNewGame.invalidPrice")}
                  onInput={InputHandler}
                  selectData={ticketDefaultValue}
                  defaultValue={{
                    label: editEventItem?.event
                      ? editEventItem?.event?.accessControls[0]?.typeId === 6
                        ? `${t("addNewEvent.paid")}`
                        : `${t("addNewEvent.free")}`
                      : "",
                    value: editEventItem?.event
                      ? editEventItem?.event?.accessControls[0]?.typeId === 6
                        ? "paid"
                        : "free"
                      : "",
                  }}
                  initialValid={editEventItem?.event ? true : false}
                  initialValue={
                    editEventItem?.event?.accessControls[0]?.typeId === 6
                      ? "paid"
                      : "free"
                  }
                  disabled={
                    typeof editEventItem?.event === "undefined" ? false : true
                  }
                />{" "}
                {state.inputs.TICKET.value === "paid" && (
                  <Input1
                    id="PRICE"
                    label={`${t("packages.price")} €`}
                    placeholder={t("loginForm.askForPassword")}
                    type="number"
                    element="input"
                    validators={[
                      state.inputs.TICKET.value === `free`
                        ? VALIDATOR_NONE
                        : VALIDATOR_NUMBER(),
                    ]}
                    errorText={t("addNewEvent.taxError")}
                    onInput={InputHandler}
                    initialValue={
                      editEventItem?.event?.accessControls?.length > 0 &&
                      editEventItem?.event?.accessControls[0].accessControlData
                        .price
                        ? editEventItem?.event?.accessControls[0]
                            .accessControlData.price
                        : "0.00"
                    }
                    initialValid={
                      editEventItem?.event?.accessControls?.length > 0 &&
                      editEventItem?.event?.accessControls[0].accessControlData
                        .price
                        ? true
                        : false
                    }
                    disabled={
                      typeof editEventItem?.event === "undefined" ? false : true
                    }
                  />
                )}
                <p
                  className={`font-300 regular`}
                  style={{
                    color: "black",
                  }}
                >
                  {editEventItem && t("addNewEvent.durationEditHelp")}
                </p>
                <Button
                  className={classes.loginFormButton}
                  disabled={!state.isValid || requestLoading}
                  formButton
                  inverse
                >
                  {requestLoading ? (
                    <FontAwesomeIcon icon="spinner" spin />
                  ) : editEventItem ? (
                    t("addNewEvent.editGame")
                  ) : (
                    t("addNewEvent.addNewGame")
                  )}
                </Button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="display-flex-center" style={{ minHeight: "100vh" }}>
          <Loader type="TailSpin" color="#dfdfdf" height={50} width={50} />
        </div>
      )}
    </>
  );
}
