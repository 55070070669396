import { useEffect, useState } from 'react';

export const useSearchParams = (
  selectedArea,
  selectedLevelContext,
  resetSelectedArea,
  resetSelectedLevel
) => {
  const [searchParam, setSearchParam] = useState(null);
  useEffect(() => {
    const runFunction = async () => {
      let searchParams;

      // console.log(selectedArea, selectedLevelContext);

      if (
        selectedArea?.length > 0 &&
        (selectedLevelContext?.length === 0 || !selectedLevelContext)
      ) {
        // console.log(1);
        searchParams = `&areaId=${selectedArea}`;
      } else if (
        (selectedArea?.length === 0 || !selectedArea) &&
        selectedLevelContext?.length > 0
      ) {
        // console.log(2);

        searchParams = `&levelId=${selectedLevelContext}`;
      } else if (selectedArea?.length > 0 && selectedLevelContext?.length > 0) {
        // console.log(3);

        searchParams = `&levelId=${selectedLevelContext}&areaId=${selectedArea}`;
      } else if (resetSelectedArea || resetSelectedLevel) {
        // console.log(4);
        searchParams = '&';
      }

      setSearchParam(searchParams);
    };

    if (
      selectedArea?.length > 0 ||
      selectedLevelContext?.length > 0 ||
      resetSelectedLevel ||
      resetSelectedArea
    ) {
      runFunction();
    }
  }, [
    JSON.stringify(selectedArea),
    JSON.stringify(selectedLevelContext),
    selectedArea,
    selectedLevelContext,
  ]);

  return { searchParam };
};
