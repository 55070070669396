import React from "react";
import { getImageByKey } from "../../../scripts/getImageByKey";
import Header from "../../../Shared/Header/Header";
import * as classes from "./DashboardMainPage.module.css";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../contexts/StateHolder";

export default function DashboardMainPage() {
  const { t } = useTranslation();
  const { user } = useMyContext();
  return (
    <div className={classes.DashboardMainPage}>
      <Header
        title={` ${user?.organizationName}  ${t("login.adminDashboard")} `}
        styles={{ textAlign: "center", color: "black" }}
        extraClassname
        color="black"
      />
      <img
        src={getImageByKey("admin_dashboard")}
        alt=""
        className={classes.DashboardMainPage_welcome}
      />
    </div>
  );
}
