import React from "react";
import Header from "../../../Shared/Header/Header";
import * as classes from "./UpcomingEvent.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertDuration, momentDate } from "../../../scripts/utils";
import moment from "moment";

import { useTranslation } from "react-i18next";
import BuyTickets from "./BuyTickets";

export default function EventsRoomDescription({
  ticketAccessGranted,
  singleEvent,
  ticketAccess,
  liveEvent,
  defaultLanguage,
  setTicketAccessGranted,
  routes,
  futureEvents,
  styles,
  hideBuyButton,
}) {
  const { t } = useTranslation();

  // if access is granted we show room info else event info -

  const singleItem = singleEvent;

  return (
    <div className={`${classes.EventTertairy} smallcasebold font-300`}>
      <div className={classes.EventPrimary}>
        <Header
          extraClassname={true}
          title={
            singleEvent?.publicName[defaultLanguage] ||
            singleEvent?.name[defaultLanguage]
          }
          styles={styles}
          color={"black"}
        />
        {ticketAccess && !ticketAccessGranted && (
          <>
            {liveEvent && (
              <div className={`${classes.EventPrimary_countdown} font-600`}>
                {t("eventsCategory.eventStarted")}
              </div>
            )}
          </>
        )}

        <div className={classes.EventTimeDescription}>
          <div className={`${classes.EventPrimary_date} font-300`}>
            <div>
              <FontAwesomeIcon icon="calendar" />
            </div>
            <div>
              {momentDate(
                singleItem?.startTimePublic
                  ? singleItem?.startTimePublic
                  : singleItem?.startTime
              )}
            </div>
          </div>
          <div className={`${classes.EventPrimary_time} font-300`}>
            <div>
              {" "}
              <FontAwesomeIcon icon="clock" />
            </div>
            <div>
              {moment(
                singleItem?.startTimePublic
                  ? singleItem?.startTimePublic
                  : singleItem?.startTime
              )
                .locale("fi")
                .format("LT")}
            </div>{" "}
          </div>
          {convertDuration(
            singleItem?.durationPublic
              ? singleItem?.durationPublic
              : singleItem?.duration
          ) !== "0 s" && (
            <div className={`${classes.EventPrimary_time} font-300`}>
              <div>
                {" "}
                <FontAwesomeIcon icon="hourglass-start" />
              </div>
              <div>
                {convertDuration(
                  singleItem?.durationPublic
                    ? singleItem?.durationPublic
                    : singleItem?.duration
                )}
              </div>{" "}
            </div>
          )}
        </div>

        {ticketAccess && !ticketAccessGranted && (
          <BuyTickets
            routes={routes}
            hideBuyButton={hideBuyButton}
            futureEvents={futureEvents}
            singleEvent={singleEvent}
            setTicketAccessGranted={setTicketAccessGranted}
          />
        )}

        <div
          className={`${classes.EventPrimary_description} font-300 regular`}
          dangerouslySetInnerHTML={{
            __html: singleItem?.description[defaultLanguage],
          }}
        ></div>
      </div>
    </div>
  );
}
