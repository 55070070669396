import { useHistory } from "react-router-dom";
import * as classes from "./EventsItem1.module.css";
import { Loader } from "react-loader-spinner";

import Carousel from "react-multi-carousel";
import moment from "moment";
import { useCookies } from "react-cookie";
import useWindowDimensions from "../../../WindowDimension";
import { getImageByKey } from "../../../../scripts/getImageByKey";
import { momentDate } from "../../../../scripts/utils";
import Header from "../../../../Shared/Header/Header";
import ItemLink from "../../../../Shared/ItemLink/ItemLink";
import { getSingleEvent } from "../../../../scripts/dataHandlers";
import { useMyContext } from "../../../../contexts/StateHolder";

import colors from "../../../../scripts/colors";
import { useTranslation } from "react-i18next";

export default function EventsItem1({
  allEvents,
  organizationId,

  hideViewAll,
  extraClassname,
  heading,
  hidetitle,
  accessedbyAdmin,
  settings,
  hideCarousel,
  viewAllRoute,
  accessedbySuperAdmin,
  upcomingEvent,
}) {
  const { slickSettings } = settings;
  const { setSingleEventItem } = useMyContext();
  const [cookies] = useCookies([""]);

  const { t } = useTranslation();

  const history = useHistory();

  const clickhandler = async (el) => {
    if (accessedbyAdmin) {
      // if accessed by super admin wee have to take organizationId from cookie

      return history.push(
        `/admin/event/${
          accessedbySuperAdmin ? cookies?.supAdOrg : organizationId
        }/${el.gameId}`
      );
    } else {
      const res = await getSingleEvent(organizationId, el.eventId);
      console.log("res", res);

      if (res?.status === "ok") {
        setSingleEventItem(res?.event);
      }
      // cause recordings are not played if parent org is passed
      history.push(`/event/${res?.event?.organizationId * 1}/${el.eventId}`);
    }
  };
  let className1 = "";
  const windowDimension = useWindowDimensions();

  if (allEvents?.length > 0 && slickSettings) {
    let arr = Object.keys(slickSettings.responsive).map(
      (k) => slickSettings.responsive[k]
    );
    let arr1 = [...arr];

    let j = [...arr1].find(
      (el) =>
        el.breakpoint.min <= windowDimension.width &&
        el.breakpoint.max >= windowDimension.width
    );

    if (allEvents?.length === j?.items) {
      className1 = "addSeecondary";
    } else {
      className1 = "";
    }
  }

  const SHOW_ONLY_DATE = true;

  // NOTE;
  // el?.homeClubImageUrl  , el?.awayClubImageUrl ->THESE ARE THE URL FOR MAAJOKEET TOURNAMENT GAMES AND if they exist we show them first

  const data =
    allEvents?.length > 0 &&
    allEvents.map((el, i) => {
      return (
        <div
          key={el?.eventId ? el?.eventId : el + i}
          className={classes.eventsItem1_card}
          onClick={() => clickhandler(el)}
        >
          <div
            style={{
              flex: 1,
              width: "100%",
              background: colors.brand,
              borderTop: `3px solid ${colors.primary}`,
              borderBottom: `3px solid ${colors.primary}`,
              borderRadius: "50px",
              overflow: "hidden",
            }}
          >
            <div className={classes.eventsItem1_primary}>
              {" "}
              <div className={classes.eventsItem1_heading}>
                {el?.seriesName && (
                  <>
                    <div className={classes.eventsItem1_heading_name}>
                      {el?.seriesName}
                    </div>
                    <div>|</div>
                  </>
                )}
                <div className={classes.eventsItem1_heading_date}>
                  {el.startTimePublic
                    ? momentDate(el.startTimePublic, SHOW_ONLY_DATE)
                    : momentDate(el.startTime, SHOW_ONLY_DATE)}
                </div>
              </div>
              {/*  HERE homeTeamLogoUrl and awayTeamLogoUrl should be given proiority as they are inserted by user */}
              <div
                className={classes.eventsItem1_image}
                style={{
                  height: "80px",
                }}
              >
                <img
                  src={
                    el?.homeTeamLogoUrl
                      ? el?.homeTeamLogoUrl
                      : el?.homeClubImage
                      ? el?.homeClubImage
                      : getImageByKey("defaultClubLogo")
                  }
                  alt="Home Team"
                  style={{
                    borderRadius: el?.homeClubImage
                      ? "50px"
                      : el?.homeTeamLogoUrl
                      ? "50px"
                      : "0px",
                    // width: "80px",
                    // height: "80px",
                  }}
                  // if bullshit imageURL given by user
                  onError={(e) =>
                    (e.target.src = getImageByKey("defaultClubLogo"))
                  }
                />

                <img
                  src={
                    el?.awayTeamLogoUrl
                      ? el?.awayTeamLogoUrl
                      : el?.awayClubImage
                      ? el?.awayClubImage
                      : getImageByKey("defaultClubLogo")
                  }
                  alt="Away Team"
                  style={{
                    borderRadius: el?.awayClubImage
                      ? "50px"
                      : el?.awayTeamLogoUrl
                      ? "50px"
                      : "0px",
                    // width: "80px",
                    // height: "80px",
                  }}
                  onError={(e) =>
                    (e.target.src = getImageByKey("defaultClubLogo"))
                  }
                />
              </div>
            </div>
            <div
              style={{
                background: colors.white,
                overflow: "hidden",
                borderBottomRightRadius: "50px",
                borderBottomLeftRadius: "50px",
              }}
            >
              {" "}
              <div
                className={classes.eventsItem1_secondary}
                style={{
                  borderBottomLeftRadius: "50px",
                  borderBottomRightRadius: "50px",
                  width: "100%",
                  height: "50px",
                  color: colors.primary,
                  backgroundColor: colors.brand,
                }}
              >
                <div className={classes.eventsItem1_text}>
                  {el.name["en_US"] && el.name.en_US.replace(/<[^>]*>?/gm, "")}
                </div>
              </div>
              <div
                className={classes.eventsItem1_secondary}
                style={{
                  borderRadius: "50px",
                  width: "100%",
                  background: colors.white,
                  color: colors.primary,

                  height: "50px",
                }}
              >
                <div className={classes.eventsItem1_text}>
                  {t("eventsItem.time")}{" "}
                  {moment(
                    el?.startTimePublic ? el?.startTimePublic : el?.startTime
                  )
                    .locale("fi")
                    .format("LT")}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

  return allEvents ? (
    <div className={`${className1} smallcasebold`}>
      {heading && (
        <div className={classes.Events_main_info}>
          {!hidetitle && (
            <Header
              extraClassname={extraClassname}
              title={heading}
              showTitle={true}
              admin={accessedbyAdmin}
            />
          )}

          {!hideViewAll && allEvents.length > 0 && (
            <ItemLink route={viewAllRoute} link={true} />
          )}
        </div>
      )}

      {allEvents.length > 0 ? (
        <div
          className={`${classes.eventsItem1} ${
            accessedbyAdmin && classes.eventsItem2
          } eventsItemCarousel`}
        >
          {accessedbyAdmin ? (
            allEvents.map((el, i) => {
              return (
                <div
                  key={el?.eventId ? el?.eventId : el + i}
                  className={classes.eventsItem1_card_admin}
                  onClick={() => clickhandler(el)}
                >
                  <div className={classes.eventsItem1_primary_admin}>
                    {" "}
                    <div className={classes.eventsItem1_heading}>
                      {el?.seriesName && (
                        <>
                          <div className={classes.eventsItem1_heading_name}>
                            {el?.seriesName}
                          </div>
                          <div>|</div>
                        </>
                      )}
                      <div className={classes.eventsItem1_heading_date}>
                        {el.startTimePublic
                          ? momentDate(el.startTimePublic, SHOW_ONLY_DATE)
                          : momentDate(el.startTime, SHOW_ONLY_DATE)}
                      </div>
                    </div>
                    <div
                      className={classes.eventsItem1_image}
                      style={{
                        height: "60px",
                      }}
                    >
                      <img
                        src={
                          el?.homeTeamLogoUrl
                            ? el?.homeTeamLogoUrl
                            : el?.homeClubImage
                            ? el?.homeClubImage
                            : getImageByKey("defaultClubLogo")
                        }
                        style={{
                          borderRadius: el?.homeClubImage
                            ? "50px"
                            : el?.homeTeamLogoUrl
                            ? "50px"
                            : "0px",
                          width: "60px",
                          // height: "60px",
                        }}
                        alt="Home Team"
                        // if bullshit imageURL given by user
                        onError={(e) =>
                          (e.target.src = getImageByKey("defaultClubLogo"))
                        }
                      />
                      <img
                        src={
                          el?.awayTeamLogoUrl
                            ? el?.awayTeamLogoUrl
                            : el?.awayClubImage
                            ? el?.awayClubImage
                            : getImageByKey("defaultClubLogo")
                        }
                        style={{
                          borderRadius: el?.homeClubImage
                            ? "50px"
                            : el?.homeTeamLogoUrl
                            ? "50px"
                            : "0px",
                          width: "60px",
                          // height: "60px",
                        }}
                        alt="Away Team"
                        onError={(e) =>
                          (e.target.src = getImageByKey("defaultClubLogo"))
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.eventsItem1_secondary}>
                    <div className={classes.eventsItem1_text}>
                      {el.name["en_US"] &&
                        el.name.en_US.replace(/<[^>]*>?/gm, "")}
                    </div>
                  </div>
                </div>
              );
            })
          ) : hideCarousel ? (
            <div className={classes.eventsItem1_hideCarousel}> {data}</div>
          ) : (
            <Carousel {...slickSettings}>{data}</Carousel>
          )}
        </div>
      ) : (
        <div className={classes.NoDataAvailable}>
          <img
            src={getImageByKey("no_data_available")}
            alt="no_data_available"
          />
        </div>
      )}
    </div>
  ) : (
    <div className="display-flex-center">
      {" "}
      <Loader type="TailSpin" color="#161eaf" />{" "}
    </div>
  );
}
