import { useMyContext } from "../../contexts/StateHolder";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import "./LoginBTN.css";
import { CgLogOut } from "react-icons/cg";
import { GrUserAdmin, GrUser } from "react-icons/gr";
import { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../scripts/colors";

const Login = (props) => {
  const { user, setUser, setUserLoggedIn } = useMyContext();

  const history = useHistory();

  const { t } = useTranslation();

  const [cookies, removeCookie] = useCookies("");

  const logOut = () => {
    console.log(cookies?.userData);
    setTimeout(() => {
      if (cookies?.userData) {
        removeCookie("userData", 1, {
          path: "/",
          expires: new Date(Date.now()),
        });
        if (cookies?.ue) {
          removeCookie("ue", 1, { path: "/", expires: new Date(Date.now()) });
        }
        if (cookies?.nodeonClubId) {
          removeCookie("nodeonClubId", 1, {
            path: "/",
            expires: new Date(Date.now()),
          });
        }
        if (cookies?.tiAcc) {
          removeCookie("tiAcc", 1, {
            path: "/",
            expires: new Date(Date.now()),
          });
        }
        if (cookies?.supAdOrg) {
          removeCookie("supAdOrg", 1, {
            path: "/",
            expires: new Date(Date.now()),
          });
        }
        if (cookies?.supAdSec) {
          removeCookie("supAdSec", 1, {
            path: "/",
            expires: new Date(Date.now()),
          });
        }
        if (cookies?.redirectUrl) {
          removeCookie("redirectUrl", 1, {
            path: "/",
            expires: new Date(Date.now()),
          });
        }
      }

      setUser({});
      console.log(cookies);
      window.location.reload();
    }, 200);

    setUserLoggedIn(false);

    history.push(`/${props.routes.home}`);
  };

  const clickProfile = () => {
    history.push(`/${props.routes.userDetails}`);
  };

  const [isActive, setActive] = useState(false);

  const toggleClass = (e) => {
    e.preventDefault();
    setActive(!isActive);
  };
  // console.log(isActive);

  console.log(user);

  return (
    <div className={`loginContainer ${user?.loggedIn && "profileContainer"}`}>
      {user?.loggedIn && (
        <div className="containerprofile font-400" onClick={toggleClass}>
          <div className="half smallcasebold">
            <label className={"profile-dropdown"}>
              <input
                type="checkbox"
                onChange={toggleClass}
                checked={isActive}
                style={{ display: "none" }}
              />
              <img
                src="https://cdn4.iconfinder.com/data/icons/office-thick-outline/36/office-14-1024.png"
                alt="j"
              />
              {/* // if super admin then we show organization name instead */}
              <span>
                {user?.superAdmin ? user?.organizationName : user?.firstName}
              </span>
              <label>
                {isActive ? (
                  <FiChevronUp className="font-600" />
                ) : (
                  <FiChevronDown className="font-600" />
                )}
              </label>
              <ul>
                {!user?.superAdmin && (
                  <li>
                    <Link
                      to={`/${props.routes.userDetails}`}
                      className="font-400"
                    >
                      <GrUser
                        className="font-600 customIconLogin"
                        style={{ marginRight: "5px" }}
                      />

                      <div> {t("login.profileButton")}</div>
                    </Link>
                  </li>
                )}
                {user?.admin && (
                  <li>
                    <Link to={`/${props.routes.admin}`} className="font-400">
                      <GrUserAdmin
                        className="font-600 customIconLogin"
                        style={{
                          marginRight: "5px",
                        }}
                        color="red !important"
                      />
                      <div> {t("login.adminDashboard")}</div>
                    </Link>
                  </li>
                )}
                {user?.admin && user?.superAdmin && (
                  <li>
                    <Link
                      to={`/${props.routes.settingsChangeOrganization}`}
                      className="font-400"
                    >
                      <FontAwesomeIcon
                        icon="exchange-alt"
                        className="font-400 customIconLogin"
                        style={{ marginRight: "5px" }}
                      />
                      <div> {t("changeOrganization.changeOrganization")}</div>
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to={`/${props.routes.home}`}
                    onClick={logOut}
                    className="font-400"
                  >
                    <CgLogOut
                      className="font-600 customIconLogin"
                      style={{ marginRight: "5px" }}
                    />

                    <div> {t("login.logoutButton")}</div>
                  </Link>
                </li>
              </ul>
            </label>
          </div>
        </div>
      )}

      {!user.loggedIn && (
        <div
          className={`${
            props.smallScreen
              ? "smallScreenLoginBTN"
              : props.inverse
              ? "loginBTN"
              : "singupBTN"
          } smallcasebold`}
          onClick={() => {
            user.loggedIn
              ? logOut()
              : props.signup
              ? history.push(`/${props.routes.signUp}`)
              : history.push(`/${props.routes.login}`);
          }}
        >
          {props.signup ? t("login.signupButton") : t("login.loginButton")}
        </div>
      )}
    </div>
  );
};

export default Login;
