import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import isURL from "validator/lib/isURL";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import SelectedPackage from "../SelectedPackage/SelectedPackage";
import VoucherView from "../VoucherView/VoucherView";
import { getPackages, getPaymentUrl } from "../../../scripts/dataHandlers";
import { useMyContext } from "../../../contexts/StateHolder";
import * as settings from "../../../config_settings.json";
import * as classes from "./PaymentMethodsView.module.css";
import Loader from "react-loader-spinner";

//Does a lot. Shows either paymentMethods or voucher based on users possible payment methods
//if user selected voucher then the voucher view will handle those changes
const PaymentMethodsView = (props) => {
    const { paymentMethods, buyPackageSuccess } = props;

    const {
        user,
        organizationId,
        selectedPackage,
        userEmail,

        ticketQuantity,
        setTicketQuantity,
        loading,
        setLoading,
        packageTargetOrganization,
        profilePackages,
        language,
        setProfilePackages,
    } = useMyContext();

    const [cookies] = useCookies("ea");

    const history = useHistory();

    const [voucherView, setVoucherView] = useState(null);
    const [paymentMethodError, setPaymentMethodError] = useState(null);

    const { t } = useTranslation();
    const { itemType, itemId, orgId } = useParams();
    console.log(`itemType`, itemType, itemId, orgId);

    const buyPackage = async (methodId, packageId) => {
        console.log(packageTargetOrganization);
        console.log(orgId);
        console.log(packageId);
        console.log(settings?.organization?.packages?.inssiDivari?.packageId);
        setLoading(true);
        let handlerUrl = window.location.protocol + "//" + window.location.hostname;
        handlerUrl += window.location.hostname.includes("localhost") ? ":" + window.location.port : "";
        handlerUrl += props.settings.returnUrlPath;
        handlerUrl = handlerUrl + `${organizationId}/${itemId}`;
        console.log(`cookies`, cookies);

        try {
            //
            console.log("profilePackages", profilePackages);
            let profilePackageValue = profilePackages;
            if (profilePackageValue?.length === 0) {
                // we have to fetch from backend
                try {
                    const responseData = await getPackages(settings.organization.organizationId, language, 0);

                    console.log(`packagesData`, responseData);

                    if (responseData.status === "ok" && typeof responseData.packages !== "undefined") {
                        console.log("set progilePack");
                        setProfilePackages(responseData.packages);
                        profilePackageValue = responseData.packages;
                    }
                } catch (err) {
                    console.log(err);
                }
            }
            console.log("profilePackageValue", profilePackageValue, packageId);

            let ticketQuan = profilePackageValue.find((el) => el?.id === packageId);
            // if there is ticketQuant ->it means current packge in not of type ticket

            const paymentUrl = await getPaymentUrl(
                cookies?.userData?.userToken,
                packageId === settings?.organization?.packages?.inssiDivari?.packageId ? packageTargetOrganization : orgId,
                packageId,
                methodId,
                cookies?.ue,
                handlerUrl,
                ticketQuan ? 0 : ticketQuantity
            );

            // handlerUrl is return URl in sucess
            console.log("PP: ", paymentUrl);
            setTicketQuantity(1);
            setLoading(false);

            if (paymentUrl.status === "ok" && typeof paymentUrl.redirectUrl !== "undefined" && isURL(paymentUrl.redirectUrl)) {
                buyPackageSuccess(paymentUrl.redirectUrl);
            } else {
                let errorCode = 19;
                if (paymentUrl.status === "error" && typeof paymentUrl.errorCode !== "undefined") {
                    errorCode = paymentUrl.errorCode;
                }
                setPaymentMethodError(t("packages.PurchaseErrorCode" + errorCode));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const buyFunction = (methodId, methodKey) => {
        //if user is not logged in.
        //This should never happen cause paymentmethods are disabled when user is not logged in
        // if (!user.loggedIn) {
        //   console.log("Can't buy because needs to log in");
        //   return;
        // }
        //if no selected package then no buy either.
        //This really shouldn't happen either. You shouldn't be able to come here without selecting package
        if (Object.keys(selectedPackage).length === 0) {
            console.log("Can't buy without package");
            return;
        }
        if (selectedPackage.id === settings?.organization?.packages?.inssiDivari?.packageId && !(packageTargetOrganization > 0)) {
            console.log("target club is needed, but this should be checked earlier");
            return;
        }
        if (methodKey === "voucher") {
            setVoucherView("active");
        } else {
            buyPackage(methodId, selectedPackage.id);
        }
    };

    const hideVoucher = () => {
        setVoucherView(null);
    };

    const moveToReceipt = () => {
        setVoucherView(null);
        history.push(`/event/${organizationId}/${itemId}?voucheractivated=true`);
        //changePageState("receipt");
        console.log("move to receipt view");
    };

    console.log("user", user);

    return (
        <div className={classes.payment}>
            <div className={classes.paymentTitle}>{t("packages.PackagePayment")}</div>
            <SelectedPackage />

            {selectedPackage && (
                <div className={classes.paymentMethodsContainer}>
                    <div key="title" className={classes.paymentMethodsTitle}>
                        {t("packages.PackagePaymentMethods")}
                    </div>
                    <div className={classes.paymentMethodHelp}>{t("packages.PackagePaymentHelp")}</div>
                    {loading ? (
                        <div className="display-flex-center">
                            <Loader type="TailSpin" color="#ab0303" />{" "}
                        </div>
                    ) : (
                        <>
                            {paymentMethodError && <div className={classes.paymentMethodError}>{paymentMethodError}</div>}
                            {voucherView === null ? (
                                <div className={classes.paymentMethods}>
                                    {Object.values(paymentMethods).map((value) => (
                                        <PaymentMethod key={value.id} paymentMethod={value} buyFunction={buyFunction} />
                                    ))}
                                </div>
                            ) : (
                                <div className={classes.paymentMethods}>
                                    <VoucherView hideVoucher={hideVoucher} moveToReceipt={moveToReceipt} selectedPackage={selectedPackage} />
                                </div>
                            )}
                        </>
                    )}
                    {/* {!user.loggedIn && <div className={classes.paymentHideMethods} />} */}
                </div>
            )}
        </div>
    );
};

export default PaymentMethodsView;
