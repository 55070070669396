import { useHistory, Link } from "react-router-dom";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useTranslation } from "react-i18next";
import * as classes from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../scripts/colors";
const BottomBar2 = (props) => {
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div
      style={{
        background: colors.primary,
      }}
    >
      <div className={`${classes.Footer} font-200 protrebleextrabold`}>
        <div className={classes.Footer_Description}>
          <div className={classes.Footer_Description_Heading}>
            <img
              className="navBarSiteLogo"
              src={getImageByKey("logo_organization")}
              title={t("Back to Home")}
              alt="SiteName"
              onClick={() => history.push("/")}
              style={props?.styles?.navBar}
            />
          </div>
        </div>
        <div className={classes.Footer_Menu}>
          <div
            className={`${classes.Footer_Menu_Heading} protrebleheavy font-400`}
          >
            MENU
          </div>
          <div className={classes.Footer_Menu_info}>
            {/* <div className={classes.Footer_Menu_Links}>
              <Link to={`/${props.routes.aboutUs}`}>
                {" "}
                {t("footer.aboutUs")}
              </Link>
            </div> */}
            <div className={classes.Footer_Menu_Links}>
              <Link to={`/${props.routes.tos}`}> {t("footer.tos")}</Link>
            </div>
            <div className={classes.Footer_Menu_Links}>
              <Link to={`/${props.routes.privacyPolicy}`}>
                {t("footer.privacyPolicy")}
              </Link>
            </div>
            <div className={classes.Footer_Menu_Links}>
              <Link to={`${props.routes.contactus}`}>
                {t("footer.contactUs")}
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.Footer_SocialMedia}>
          <div
            className={`${classes.Footer_SocialMedia_Heading} protrebleheavy font-400`}
          >
            {" "}
            {t("footer.socialMedia")}
          </div>
          <div className={classes.Footer_SocialMedia_Heading_Info}>
            <div className={classes.Footer_SocialMedia_Links}>
              <FontAwesomeIcon
                icon={["fab", "facebook"]}
                className={classes.Footer_SocialMedia_Links_icons}
              />{" "}
              <a
                href="https://www.facebook.com/people/Netscouters/100063696782019/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook{" "}
              </a>
            </div>
            <div className={classes.Footer_SocialMedia_Links}>
              <FontAwesomeIcon
                icon={["fab", "instagram"]}
                className={classes.Footer_SocialMedia_Links_icons}
              />{" "}
              <a
                href="https://www.instagram.com/netscouters/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
            <div className={classes.Footer_SocialMedia_Links}>
              <FontAwesomeIcon
                icon={["fab", "linkedin"]}
                className={classes.Footer_SocialMedia_Links_icons}
              />{" "}
              <a
                href="https://www.linkedin.com/company/netscouters-international/"
                target="_blank"
                rel="noopener noreferrer"
              >
                linkedIn
              </a>
            </div>

            <div className={classes.Footer_SocialMedia_Links}>
              <FontAwesomeIcon
                icon={["fab", "twitter"]}
                className={classes.Footer_SocialMedia_Links_icons}
              />
              <a
                href="https://twitter.com/netscouters"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomBar2;
