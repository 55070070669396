import React from "react";
import { getImageByKey } from "../../scripts/getImageByKey";
import * as classes from "./ContactUs.module.css";

export default function ContactUs() {
  return (
    <div className={`${classes.ContactUs}  `}>
      <div className={classes.ContactUsPrimary}>
        <div className={classes.ContactUs_image}>
          <img src={getImageByKey("contactus")} alt="SalibandyTV" />
        </div>
        <div className={classes.ContactUs_description}>
          <div className={classes.ContactUs_description_Primary}>
            <h4 className="font-700">CONTACT US</h4>
            <div className={classes.ContactUs_description_address}></div>
            <div className={classes.ContactUs_description_phone}>
              <div
                className={`${classes.ContactUs_description_address_info_title} font-600 `}
                style={{ marginTop: "30px" }}
              >
                {" "}
                <div>Address</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
                className="font-400 "
              >
                <div>Calle Madrid 28400, Madrid</div>
              </div>
            </div>
            <div className={classes.ContactUs_description_email}>
              <a
                className={classes.ContactUs_description_email_button}
                href="mailto:information@netscouters.com
"
              >
                Send Email
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
