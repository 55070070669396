import React, { useEffect, useState } from "react";

import { getUpcomingGames } from "../../../scripts/dataHandlers";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import PastEventsCategoryItem from "./PastEventsCategoryItem";
import { useMyContext } from "../../../contexts/StateHolder";
import Loader from "react-loader-spinner";
import { useSearchParams } from "../../../Shared/Hooks/search-hook";
import { useCookies } from "react-cookie";
import EventsItem1 from "../Events/EventsItem1/EventsItem1";

export default function PastEvents(props) {
    const [allUpcomingEvents, setAllUpcomingEvents] = useState(null);

    const { t } = useTranslation();

    const [cookies] = useCookies([" "]);

    // if we need to show events from sub-organization
    const { subOrganizationId } = useParams();

    const { limit } = props.settings;

    const [loading, setLoading] = useState(false);

    const { selectedArea, selectedLevelContext, resetSelectedLevel, resetSelectedArea } = useMyContext();

    const { searchParam } = useSearchParams(selectedArea, selectedLevelContext, resetSelectedArea, resetSelectedLevel);

    useEffect(() => {
        const runFunction = async () => {
            try {
                if (searchParam) {
                    setLoading(true);
                    const res2 = await getUpcomingGames(
                        props.accessedbyAdmin && cookies?.supAdOrg ? cookies?.supAdOrg : subOrganizationId ? subOrganizationId : props.organizationId,
                        limit,
                        null,
                        null,
                        searchParam,
                        props.isPastEvents
                    );

                    if (res2?.data?.status === "ok") {
                        setAllUpcomingEvents(res2?.data?.games);
                    }
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        if (searchParam) {
            runFunction();
        }
    }, [searchParam, JSON.stringify(searchParam)]);

    useEffect(() => {
        const runFunction = async () => {
            try {
                const res2 = await getUpcomingGames(
                    props.accessedbyAdmin && cookies?.supAdOrg ? cookies?.supAdOrg : subOrganizationId ? subOrganizationId : props.organizationId,
                    limit,
                    null,
                    null,
                    null,
                    props.isPastEvents
                );

                if (res2?.data?.status === "ok") {
                    let s;

                    if (props.isPastEvents) {
                        s = res2?.data?.games.sort((a, b) => b.startTime - a.startTime);
                    } else {
                        s = res2?.data?.games;
                    }

                    setAllUpcomingEvents(s);
                }
            } catch (error) {
                console.log(error);
            }
        };

        props.organizationId && runFunction();
    }, [props.organizationId, limit, subOrganizationId]);
    return !loading ? ( // If it includes props(upcomingEventCategory) , it means it wont have any category id or name and we have to fetch filter data and route accordingly
        allUpcomingEvents && !props.accessedbyAdmin ? (
            <EventsItem1
                allEvents={allUpcomingEvents}
                setAllEvents={setAllUpcomingEvents}
                heading={props.heading || t("eventsCategory.upcomingEventsTitle")}
                upcomingEventCategory={true}
                organizationId={subOrganizationId ? subOrganizationId : props.organizationId}
                homepageContainer={props.homepageContainer}
                extraClassname={props.extraClassname}
                hideViewAll={props.hideViewAll}
                styles={props.styles}
                accessedbyAdmin={props.accessedbyAdmin}
                settings={props.settings}
                setting={props.settings}
                viewAllRoute={`/pastEvents/${subOrganizationId ? subOrganizationId : props.organizationId}`}
            />
        ) : (
            allUpcomingEvents &&
            props.accessedbyAdmin && (
                <PastEventsCategoryItem
                    heading={t("eventsCategory.upcomingEventsTitle")}
                    extraClassname={props.extraClassname}
                    hideViewAll={props.hideViewAll}
                    styles={props.styles}
                    accessedbyAdmin={props.accessedbyAdmin}
                    settings={props.settings}
                    setting={props.settings}
                    categoryIdProps={props.categoryIdProps}
                    categoryNameProps={props.categoryNameProps}
                    organizationIdProps={cookies?.supAdOrg ? cookies?.supAdOrg : subOrganizationId ? subOrganizationId : props.organizationId}
                    viewAllRoute={`/events/Past Events/${subOrganizationId ? subOrganizationId : props.organizationId}/pastEvent`}
                />
            )
        )
    ) : (
        <div className="display-flex-center">
            {" "}
            <Loader type="TailSpin" color="#161eaf" />{" "}
        </div>
    );
}
