import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

import * as classes from "./SearchEvent.module.css";
import * as settings from "../../../config_settings.json";
import { searchGames } from "../../../scripts/dataHandlers";
import EventsItem1 from "../../Events/Events/EventsItem1/EventsItem1";
import { getImageByKey } from "../../../scripts/getImageByKey";

export default function SearchEvent(props) {
  const { t } = useTranslation();

  const [searchFieldInput, setSearchFieldInput] = useState("");

  const [loading, setLoading] = useState(false);

  const [allGames, setAllGames] = useState(null);

  const [noGamesFound, setNoGamesFound] = useState(false);

  const formHandler = async (e) => {
    e.preventDefault();
    setNoGamesFound(false);
    setAllGames(null);

    setLoading(true);

    const res = await searchGames(
      settings.organization.organizationId,
      searchFieldInput,
      100
    );

    // console.log('resevent', res);

    if (res?.data?.status === "ok") {
      // need to check if there is any match or not
      if (res?.data?.games.length === 0) {
        setNoGamesFound(true);
      } else {
        // games found
        setAllGames(res?.data.games);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className={classes.searchContainer}>
        <form id={classes.searchForm} onSubmit={(e) => formHandler(e)}>
          <div className={classes.searchRow}>
            <div className={`${classes.searchColumn} font-300`}>
              <input
                className={`${classes.searchField} font-300`}
                type="text"
                value={searchFieldInput}
                onChange={(e) => setSearchFieldInput(e.target.value)}
                placeholder={t("search.searchByName")}
              />
            </div>

            <div>
              <button
                type="submit"
                className={classes.searchButton}
                disabled={!searchFieldInput || loading}
              >
                {!loading && <FaSearch className={classes.FaIcon} />}
                {loading
                  ? `${t("shared.loading")}...`
                  : t("search.searchButton")}
              </button>
            </div>
          </div>
        </form>
      </div>
      {!loading && !noGamesFound && allGames?.length > 0 && (
        <EventsItem1
          allEvents={allGames}
          setAllEvents={setAllGames}
          heading={t("adminSideBar.games")}
          organizationId={settings.organization.organizationId}
          accessedbyAdmin={false}
          settings={props.settings}
          setting={props.settings}
          hideCarousel={props.settings.hideCarousel}
          hideViewAll={props.settings.hideViewAll}
        />
      )}

      {!loading && noGamesFound && (
        <div className={classes.NoDataAvailable}>
          <div style={{ color: "white" }}> {t("search.searchNoMatch")}</div>
          <img
            src={getImageByKey("no_data_available")}
            alt="no_data_available"
          />
        </div>
      )}
    </>
  );
}
