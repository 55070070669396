import { useState, useEffect } from "react";
import { useMyContext } from "../../../contexts/StateHolder";
import { countries } from "../../../scripts/countries";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../Shared/Hooks/form-hook";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_NONE,
  VALIDATOR_NUMBER,
  VALIDATOR_PHONE,
} from "../../../Shared/Validation/Validator";
import {
  updateUser,
  getUser,
  fetchAllAdmins,
  findSubOrganizationSecret,
} from "../../../scripts/dataHandlers";
import { useCookies } from "react-cookie";
import * as classes from "../../../Shared/Input1/Input1.module.css";
import { toast, ToastContainer } from "react-toastify";
import { Input1 } from "../../../Shared/Input1/Input1";
import Header from "../../../Shared/Header/Header";
import Button from "../../../Shared/Button/Button";
import { useParams } from "react-router-dom";

import * as settings from "../../../config_settings.json";

const UserDetails = (props) => {
  // console.log(props);
  // Bring stateholders from context
  const { user, setUser, organizationId, key, language } = useMyContext();

  const { userId } = useParams();
  const [countryList, setCountryList] = useState(null);

  // Holds inform message for form
  const [formMessage, setFormMessage] = useState("");

  const [cookies] = useCookies("");

  // Setup translate function
  const { t } = useTranslation();

  // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform
  const [state, InputHandler] = useForm(
    {
      FIRSTNAME: {
        value: "",
        isValid: true,
      },
      LASTNAME: {
        value: "",
        isValid: true,
      },
      EMAIL: {
        value: "",
        isValid: true,
      },
      PHONE: {
        value: "",
        isValid: true,
      },
      ORGANIZATIONNAME: {
        value: "",
        isValid: true,
      },
      YTUNNUS: {
        value: "",
        isValid: true,
      },
      CITY: {
        value: "",
        isValid: true,
      },
      COUNTRY: {
        value: "",
        isValid: true,
      },
      ADDRESS: {
        value: "",
        isValid: true,
      },
      POSTALCODE: {
        value: "",
        isValid: true,
      },
      DATEOFBIRTH: {
        value: "",
        isValid: true,
      },
    },
    true
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );

  // Holder for profileData
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    city: "",
    country: "",
    countryId: "",
    address: "",
    postalCode: "",
    yTunnus: "",
    organizationName: "",
    dateOfBirth: "",
    regionId: "",
  });

  // Fetch userdata from API and set it to profileData
  useEffect(() => {
    async function getUserData(uToken, uOrg) {
      let y1 = [];

      countries.forEach((el) => {
        let g = { value: el.id, label: t(`countries.${el.name}`) };
        y1.push(g);
      });

      setCountryList(y1);
      try {
        const response = await getUser(uToken, uOrg);

        console.log("response", response);

        let newData;
        newData = {
          firstName:
            response.data.firstName !== "x" &&
            response.data.firstName !== "Sub" &&
            response.data.firstName !== "-"
              ? response.data.firstName
              : "",
          lastName:
            response.data.lastName !== "x" &&
            response.data.lastName !== "Sub" &&
            response.data.lastName !== "-"
              ? response.data.lastName
              : "",
          phone:
            response.data.phone !== "0" && response.data.phone !== "-"
              ? response.data.phone
              : "",
          email: response.data.emailAddress,
          city: response.data.city !== "-" ? response.data.city : "",
          country: response.data.country !== "x" ? response.data.country : "",
          countryId:
            response.data.countryId !== "x" ? response.data.countryId : "",
          yTunnus:
            response.data?.userSettings?.items?.find(
              (item) => item.name === "yTunnus"
            ) &&
            response.data?.userSettings?.items?.find(
              (item) => item.name === "yTunnus"
            ).value !== "x"
              ? response.data?.userSettings?.items?.find(
                  (item) => item.name === "yTunnus"
                ).value
              : "",
          organizationName:
            response.data?.userSettings?.items?.find(
              (item) => item.name === "organizationName"
            ) &&
            response.data?.userSettings?.items?.find(
              (item) => item.name === "organizationName"
            ).value !== "x"
              ? response.data?.userSettings?.items?.find(
                  (item) => item.name === "organizationName"
                ).value
              : "",
          address: response.data.address !== "-" ? response.data.address : "",
          postalCode:
            response.data.postalCode !== "0" ? response.data.postalCode : "0",
          regionId:
            response.data.regionId !== "-" ? response.data.regionId : "",
          dateOfBirth:
            response.data?.dateOfBirth && response.data?.dateOfBirth !== "x"
              ? response.data.dateOfBirth
              : Date(),
        };
        if (props.admin) {
          return newData;
        }
        console.log(response, "response");

        console.log(newData);
        setProfileData({ ...newData });

        user.buyerProducts = response.data.buyerProducts;
        setUser({ ...user });
      } catch (err) {
        console.log(err);
      }
    }

    if (user && !props.admin) {
      getUserData(user?.userToken, user?.organizationId);
    } else if (props.admin) {
      /// This means when admin clicks on edit button from all admin page we will be directed here and need to get detail of that particular user instead of loggedInuser.

      const fetchAdmin = async () => {
        // incase of superadmin we have to take orgId from cookies
        console.log(props.accessedbySuperAdmin, cookies?.supAdOrg);
        const res1 = await fetchAllAdmins(
          props.accessedbySuperAdmin && cookies?.supAdOrg
            ? cookies?.supAdOrg
            : user?.organizationId,
          user?.userToken
        );
        // finding selected admin from an array of all admins
        const response = res1?.users?.find(
          (el1) => el1.userId * 1 === userId * 1
        );
        console.log("response", response);
        const newData = {
          firstName:
            response.firstName !== "x" &&
            response.firstName !== "Sub" &&
            response.firstName !== "-"
              ? response.firstName
              : "",
          lastName:
            response.lastName !== "x" &&
            response.lastName !== "Sub" &&
            response.lastName !== "-"
              ? response.lastName
              : "",
          phone:
            response.phone !== "0" && response.phone !== "-"
              ? response.phone
              : "",
          email: response.emailAddress,
          city: response.city !== "-" ? response.city : "",
          country: response.country !== "x" ? response.country : "",
          countryId: response.countryId !== "x" ? response.countryId : "",
          yTunnus:
            response?.userSettings?.items?.find(
              (item) => item.name === "yTunnus"
            ) &&
            response?.userSettings?.items?.find(
              (item) => item.name === "yTunnus"
            ).value !== "x"
              ? response?.userSettings?.items?.find(
                  (item) => item.name === "yTunnus"
                ).value
              : "",
          organizationName:
            response?.userSettings?.items?.find(
              (item) => item.name === "organizationName"
            ) &&
            response?.userSettings?.items?.find(
              (item) => item.name === "organizationName"
            ).value !== "x"
              ? response?.userSettings?.items?.find(
                  (item) => item.name === "organizationName"
                ).value
              : "",
          address: response.address !== "-" ? response.address : "",
          postalCode: response.postalCode !== "0" ? response.postalCode : "0",
          regionId: response.regionId !== "-" ? response.regionId : "",
          dateOfBirth:
            response?.dateOfBirth && response?.dateOfBirth !== "x"
              ? response.dateOfBirth
              : Date(),
        };

        console.log(newData);
        setProfileData({ ...newData });
      };
      fetchAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    // console.log(state.inputs.DATEOFBIRTH.value);

    try {
      // here we also need to check if organization is same as config which will not be in case of salibandy-so we need to find secret as well
      let secret;

      if (
        user?.organizationId * 1 ===
        settings.organization.organizationId * 1
      ) {
        // incase of superAdmin -> super admin is created in salibandy parent org and this will match the above condition
        if (props.accessedbySuperAdmin) {
          // we have secret in cookies for superadmin
          secret = cookies?.supAdSec;
        } else {
          secret = key;
        }
      } else {
        // suborg will not change ->admin will only be shoown other admin of same org

        if (props.accessedbySuperAdmin) {
          // we have secret in cookies for superadmin
          secret = cookies?.supAdSec;
        } else {
          const res2 = await findSubOrganizationSecret(
            settings.organization.organizationId,
            key,
            user?.organizationId
          );
          secret = res2?.secret;
        }
      }

      // Update user data

      let phoneNumber = state.inputs.PHONE.value;

      if (phoneNumber && phoneNumber.includes("34") && phoneNumber > 10) {
        // this only checks in the starting hand

        phoneNumber = phoneNumber.toString().replace("34", "0");
      }

      //

      // return;
      // console.log(state);
      // return;
      console.log("secret", secret);

      const response = await updateUser(
        user.userId,
        user?.userToken,
        props.accessedbySuperAdmin && cookies?.supAdOrg
          ? cookies?.supAdOrg
          : user?.organizationId,
        secret,
        state.inputs.FIRSTNAME.value ? state.inputs.FIRSTNAME.value : "-",
        state.inputs.LASTNAME.value ? state.inputs.LASTNAME.value : "-",
        phoneNumber ? phoneNumber : "-",
        state.inputs.COUNTRY.value,
        profileData.regionId,
        state.inputs.CITY.value ? state.inputs.CITY.value : "-",
        state.inputs.POSTALCODE.value ? state.inputs.POSTALCODE.value : "-",
        state.inputs.EMAIL.value,
        // this userid is of user which we are trying to change from admin panel
        props.admin && userId,

        // not required below as of now

        state.inputs.ORGANIZATIONNAME.value,
        state.inputs.YTUNNUS.value,
        state.inputs.DATEOFBIRTH.value,
        state.inputs.ADDRESS.value
      );

      response?.data?.status === "ok"
        ? toast.success(t("userDetails.profileUpdated"), {
            autoClose: 10000,
            closeOnClick: true,
            draggable: true,

            theme: "dark",
          })
        : response?.data?.message
        ? toast.error(response?.data?.message, {
            autoClose: 10000,
            closeOnClick: true,
            draggable: true,

            theme: "dark",
          })
        : toast.error(t("userDetails.somethingWrongTryAgain"), {
            autoClose: 10000,
            closeOnClick: true,
            draggable: true,

            theme: "dark",
          });
    } catch (err) {
      console.log(err);
      setFormMessage(t("userDetails.somethingWrongTryAgain"));
    }
  };

  // If email (required value) is there and userId is set, render input fields with initial values from profileData
  // console.log('profileData', profileData);

  // console.log(profileData);
  if (profileData.email) {
    return (
      <>
        <div className={classes.formPrimary}>
          <div className={classes.formSecondary}>
            <form
              className={classes.formBlogAdmin}
              onSubmit={formSubmitHandler}
            >
              {/* <ProfileHeader showSubscriptions={props.showSubscriptions} /> */}
              <Header
                title={
                  props.admin
                    ? t("signUpForm.editAdminDetails")
                    : t("userDetails.userDetails")
                }
                extraClassname
                admin={props.admin}
                showBackButton={true}
              />

              {props.fields.email ? (
                <Input1
                  id="EMAIL"
                  placeholder={t("userDetails.inputPlaceholderEmail")}
                  label={t("userDetails.inputPlaceholderEmail")}
                  type="text"
                  element="input"
                  validators={[VALIDATOR_EMAIL()]}
                  errorText={t("userDetails.incorrectEmailAddress")}
                  onInput={InputHandler}
                  iconName="envelope"
                  initialValid={true}
                  initialValue={profileData.email}
                  disabled={true}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}

              {props.fields.firstName ? (
                <Input1
                  id="FIRSTNAME"
                  placeholder={t("userDetails.inputPlaceholderFirstName")}
                  label={t("userDetails.inputPlaceholderFirstName")}
                  type="text"
                  element="input"
                  validators={[VALIDATOR_NONE()]}
                  errorText={t("userDetails.askForFirstName")}
                  onInput={InputHandler}
                  iconName="user"
                  initialValid={true}
                  initialValue={profileData.firstName}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}

              {props.fields.lastName ? (
                <Input1
                  id="LASTNAME"
                  placeholder={t("userDetails.inputPlaceholderLastName")}
                  label={t("userDetails.inputPlaceholderLastName")}
                  type="text"
                  element="input"
                  validators={[VALIDATOR_NONE()]}
                  errorText={t("userDetails.askForLastName")}
                  onInput={InputHandler}
                  iconName="user"
                  initialValid={true}
                  initialValue={profileData.lastName}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}

              {props.fields.address ? (
                <Input1
                  id="ADDRESS"
                  placeholder={t("userDetails.inputPlaceholderAddress")}
                  label={t("userDetails.inputPlaceholderAddress")}
                  type="text"
                  element="input"
                  validators={[VALIDATOR_NONE()]}
                  errorText={t("userDetails.askForAddress")}
                  onInput={InputHandler}
                  iconName="map-pin"
                  initialValid={true}
                  initialValue={profileData.address}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}

              {props.fields.postalCode ? (
                <Input1
                  id="POSTALCODE"
                  placeholder={t("userDetails.inputPlaceholderPostalCode")}
                  label={t("userDetails.inputPlaceholderPostalCode")}
                  type="text"
                  element="input"
                  validators={[VALIDATOR_NONE()]}
                  errorText={t("userDetails.askForPostalCode")}
                  onInput={InputHandler}
                  iconName="map-marked"
                  initialValid={true}
                  initialValue={profileData.postalCode}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}

              {props.fields.city ? (
                <Input1
                  id="CITY"
                  placeholder={t("userDetails.inputPlaceholderCity")}
                  label={t("userDetails.inputPlaceholderCity")}
                  type="text"
                  element="input"
                  validators={[VALIDATOR_NONE()]}
                  errorText={t("userDetails.askForCity")}
                  onInput={InputHandler}
                  iconName="city"
                  initialValid={true}
                  initialValue={profileData.city}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}
              {/* dont pass iccon name in select */}
              {props.fields.country ? (
                // <Input1
                //   id='COUNTRY'
                //   placeholder={t('userDetails.inputPlaceholderCountry')}
                //   label={t('userDetails.inputPlaceholderCountry')}
                //   type='text'
                //   selectData={countries.map((country) => country.name)}
                //   element='select_dropdown'
                //   validators={[VALIDATOR_NONE()]}
                //   errorText={t('userDetails.askForCountry')}
                //   onInput={InputHandler}
                //   // iconName={['fas', 'globe-europe']}
                //   initialValid={true}
                //   initialValue={countryList}
                //   defaultValue={{
                //     label: countryList,
                //     value: countries.find((el) => el.name === countryList)?.id,
                //   }}
                //   // style={{ margin: '15px auto' }}
                // />
                <Input1
                  id="COUNTRY"
                  placeholder={t("userDetails.inputPlaceholderCountry")}
                  label={t("userDetails.inputPlaceholderCountry")}
                  type="text"
                  selectData={countryList}
                  element="select_dropdown"
                  validators={[VALIDATOR_NONE()]}
                  errorText={t("userDetails.askForCountry")}
                  onInput={InputHandler}
                  // iconName={['fas', 'globe-europe']}
                  initialValid={true}
                  initialValue={
                    countries.find((el) => el.name === profileData.country)?.id
                  }
                  defaultValue={{
                    label: t(`countries.${profileData.country}`),
                    value: countries.find((el) => el.name === countryList)?.id,
                  }}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}

              {props.fields.phone ? (
                <Input1
                  id="PHONE"
                  placeholder={t("userDetails.inputPlaceholderPhone")}
                  label={t("userDetails.inputPlaceholderPhone")}
                  type="number"
                  element="input"
                  validators={[VALIDATOR_PHONE()]}
                  errorText={t("userDetails.askForPhone")}
                  onInput={InputHandler}
                  iconName="phone"
                  initialValid={true}
                  initialValue={profileData.phone}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}

              {props.fields.dateOfBirth ? (
                <Input1
                  id="DATEOFBIRTH"
                  placeholder={t("userDetails.inputPlaceholderDateOfBirth")}
                  label={t("userDetails.inputPlaceholderDateOfBirth")}
                  type="text"
                  element="dateOfBirth"
                  validators={[VALIDATOR_NONE()]}
                  errorText={t("userDetails.askForDateOfBirth")}
                  onInput={InputHandler}
                  iconName="birthday-cake"
                  initialValid={true}
                  initialValue={profileData.dateOfBirth}
                />
              ) : null}

              {props.fields.yTunnus ? (
                <Input1
                  id="YTUNNUS"
                  label={t("userDetails.inputPlaceholderYtunnus")}
                  placeholder={t("userDetails.inputPlaceholderYtunnus")}
                  type="text"
                  element="input"
                  validators={[VALIDATOR_NONE()]}
                  errorText={t("userDetails.askForYtunnus")}
                  onInput={InputHandler}
                  iconName="certificate"
                  initialValid={true}
                  initialValue={profileData.yTunnus}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}

              {props.fields.organizationName ? (
                <Input1
                  id="ORGANIZATIONNAME"
                  label={t("userDetails.inputPlaceholderOrganizationName")}
                  placeholder={t(
                    "userDetails.inputPlaceholderOrganizationName"
                  )}
                  type="text"
                  element="input"
                  validators={[VALIDATOR_NONE()]}
                  errorText={t("userDetails.askForOrganizationName")}
                  onInput={InputHandler}
                  iconName="briefcase"
                  initialValid={true}
                  initialValue={profileData.organizationName}
                  // style={{ margin: '15px auto' }}
                />
              ) : null}

              <Button
                className={classes.loginFormButton}
                disabled={!state.isValid}
                formButton
                inverse
              >
                {t("userDetails.saveChanges")}
              </Button>

              <div className={classes.profileMessage}>{formMessage}</div>
            </form>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default UserDetails;
