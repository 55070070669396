import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMyContext } from '../../../contexts/StateHolder';
import '../../Admin/AdminSideBar/AdminSideBar1.css';

export default function AdminNavBar(props) {
  const { closeHamMenu } = useMyContext();

  return (
    <li className='features-item'>
      {' '}
      <NavLink
        key={props.name}
        style={props?.styles}
        to={`/${props.route}`}
        exact
        onClick={closeHamMenu}
        className={`features-item ${props.extraClassname && 'font-600'}`}
        activeClassName={'features-item-active'}
      >
        {props.icon && props.reactIcon ? (
          <>{props.reactIcon}</>
        ) : (
          props.icon && <FontAwesomeIcon icon={props.icon} />
        )}
        {props.name && <span className='features-item-text'>{props.name}</span>}{' '}
        {!props.hideToolTip && <span className='tooltip'>{props.name}</span>}
      </NavLink>
    </li>
  );
}
