import { library } from "@fortawesome/fontawesome-svg-core";
import { faTwitter, faInstagram, faTiktok, faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import {
    faMinus,
    faLink,
    faChartArea,
    faCheck,
    faClock,
    faComment,
    faEye,
    faHourglassStart,
    faInfoCircle,
    faPlay,
    faSearch,
    faStar,
    faThumbsUp,
    faExclamationCircle,
    faUser,
    faFilter,
    faCalendar,
    faAddressCard,
    faLock,
    faEnvelopeOpenText,
    faEnvelope,
    faInbox,
    faExternalLinkAlt,
    faChevronCircleDown,
    faChevronDown,
    faPlayCircle,
    faTimes,
    faTimesCircle,
    faTicketAlt,
    faEdit,
    faTrash,
    faPhone,
    faCity,
    faGlobe,
    faMapMarked,
    faBirthdayCake,
    faSpinner,
    faGamepad,
    faPlus,
    faHistory,
    faUserPlus,
    faUserCircle,
    faArrowRight,
    faChevronLeft,
    faChevronRight,
    faMapPin,
    faCode,
    faChartPie,
    faStream,
    faRedo,
    faExchangeAlt,
    faHome,
    faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faHome,
    faMinus,
    faLinkedin,
    faArrowCircleRight,

    faStream,
    faExchangeAlt,
    faExclamationCircle,
    faChartPie,
    faCode,
    faMapPin,
    faChevronLeft,
    faChevronRight,
    faArrowRight,
    faChartArea,
    faUserCircle,
    faUserPlus,
    faHistory,
    faPlus,
    faGamepad,
    faSearch,
    faUser,
    faThumbsUp,
    faPlay,
    faEye,
    faComment,
    faStar,
    faClock,
    faInfoCircle,
    faHourglassStart,
    faEye,
    faClock,
    faFilter,
    faCalendar,
    faAddressCard,
    faLink,

    faLock,
    faEnvelope,
    faEnvelopeOpenText,
    faUser,
    faInbox,
    faExternalLinkAlt,
    faSpinner,
    faInstagram,
    faTwitter,
    faTimes,
    faSearch,
    faUser,
    faThumbsUp,
    faTimesCircle,
    faPlay,
    faEye,
    faComment,
    faStar,
    faClock,
    faInfoCircle,
    faHourglassStart,
    faEye,
    faClock,
    faFilter,
    faCalendar,
    faAddressCard,
    faLock,
    faEnvelope,
    faEnvelopeOpenText,
    faUser,
    faInbox,
    faExternalLinkAlt,
    faPhone,
    faCity,
    faGlobe,
    faMapMarked,
    faBirthdayCake,
    faChevronCircleDown,
    faChevronDown,
    faPlayCircle,
    faTicketAlt,
    faEdit,
    faTrash,
    faFacebook,
    faInstagram,
    faTiktok,
    faCheck,
    faRedo
);
