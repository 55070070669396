import React, { useCallback, useEffect, useState, useRef } from "react";
import ScoreboardItem from "../components/Scoreboard/ScoreboardItem";
import { scoreBoardHandler } from "../scripts/dataHandlers";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useMyContext } from "../contexts/StateHolder";
import Button from "../Shared/Button/Button";
import Modal from "../Shared/Modal/Modal";
import { useForm } from "../Shared/Hooks/form-hook";
import { Input1 } from "../Shared/Input1/Input1";
import { VALIDATOR_NONE } from "../Shared/Validation/Validator";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

let USE_UPDATED_API_ENDPOINT = true;
export default function ScoreBoard({
  homeTeamName,
  awayTeamName,
  serviceId,
  scoreboardEnabled,
  setScoreboardEnabled,
  mobileView,
}) {
  const { t } = useTranslation();

  const [cookies, setCookie] = useCookies("");

  const [clockRunning, setClockRunning] = useState(false);

  const [scoreTeamA, setScoreTeamA] = useState(0);
  const [scoreTeamB, setScoreTeamB] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const [gamePeriod, setGamePeriod] = useState(0);

  const [updateScore, setUpdateScore] = useState(null);
  const [updatePeriod, setUpdatePeriod] = useState(null);

  const [scoreLoading, setScoreLoading] = useState(false);

  const scoreBoardStatus = useRef();

  const [showScore, setshowScore] = useState(true);
  const [showPeriod, setShowPeriod] = useState(true);
  const [showScoreboard, setShowScoreboard] = useState(true);

  const { user } = useMyContext();

  const [isINITMODAL, setIsINITMODAL] = useState(false);
  // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform
  const [state, InputHandler] = useForm(
    {
      HOME_TEAM_NAME: {
        value: "",
        isValid: true,
      },
      AWAY_TEAM_NAME: {
        value: "",
        isValid: true,
      },
    },
    true
  );

  const bottomCTA = [
    {
      title: clockRunning ? t("scoreboard.stop") : t("scoreboard.start"),
      onClick: async (title) => {
        let curStatus = clockRunning;
        scoreBoardStatus.current = curStatus ? "stop" : "start";
        setClockRunning((prev) => !prev);

        const data = {
          // START/STOP/RESET
          // we will just start or stop the clock

          timerStatus: title === t("scoreboard.start") ? "START" : "STOP",
          // timerStatus: title.toUpperCase(),
          streamName: serviceId,
        };
        let totalSecond;
        if (!clockRunning) {
          // here user might update time as well so we have to pass time also ->only when user press start button
          totalSecond = (minutes * 60 + seconds * 1) * 1000;
          data.time = totalSecond;
        }

        // setLocalCookie(!clockRunning && totalSecond);
        await scoreBoardHandler(
          data,
          user?.userToken,
          USE_UPDATED_API_ENDPOINT
        );
      },
      inverse: true,
      danger: clockRunning ? true : false,
    },

    {
      title: t("scoreboard.update"),
      disabled: clockRunning,
      onClick: async () => {
        setClockRunning(true);

        let totalSecond = (minutes * 60 + seconds * 1) * 1000;

        const data = {
          // WE WILL START THE CLOCK WHEN USER PRESSES UPDATE BUTTON
          timerStatus: "START",
          streamName: serviceId,
          time: totalSecond,
          // here we have to pass the time and time is millisecond
        };
        scoreBoardStatus.current = "start";
        // since minutes and seconds will not update when calling localCookie fucntion we are passing it from here
        // setLocalCookie(totalSecond);
        await scoreBoardHandler(
          data,
          user?.userToken,
          USE_UPDATED_API_ENDPOINT
        );
      },
    },
  ];
  // useEffect(() => {
  //     if (cookies?.scb && initialLoading) {
  //         console.log("cookies.scb", cookies.scb);
  //         // first thing we should check is eventId ->if it doesnot matches we delete that cookies

  //         if (cookies?.scb.eventId) {
  //             if (cookies?.scb.eventId * 1 !== eventId * 1) {
  //                 return removeCookie("scb", { path: "/" });
  //             }
  //             if (cookies?.scb?.period) {
  //                 setGamePeriod(cookies?.scb?.period * 1);
  //             }

  //             if (cookies?.scb?.homeTeamScore) {
  //                 setScoreTeamA(cookies?.scb?.homeTeamScore * 1);
  //             }
  //             if (cookies?.scb?.awayTeamScore) {
  //                 setScoreTeamB(cookies?.scb?.awayTeamScore * 1);
  //             }

  //             if (cookies?.scb?.clockRunning === "start") {
  //                 setClockRunning(true);

  //                 if (cookies?.scb?.startLastTime) {
  //                     // subtract current time from when clock was started
  //                     let curTime = Date.now() - cookies?.scb?.startLastTime * 1;

  //                     // now add remaining minutes and seconds if any was present

  //                     curTime = Number(curTime + cookies?.scb?.stopLastTime * 1);

  //                     let minutesLocal = Math.floor(curTime / 60000);

  //                     let secondsLocal = ((curTime % 60000) / 1000).toFixed(0);

  //                     setMinutes(minutesLocal * 1);
  //                     setSeconds(secondsLocal * 1);
  //                 }
  //             } else if (cookies?.scb.clockRunning === "stop") {
  //                 setClockRunning(false);

  //                 if (cookies?.scb?.stopLastTime) {
  //                     // we get total seconds from stopLastTime
  //                     let minutesLocal = Math.floor((cookies?.scb?.stopLastTime * 1) / 60);

  //                     let secondsLocal = cookies?.scb?.stopLastTime * 1 - minutesLocal * 60;

  //                     setMinutes(minutesLocal * 1);
  //                     setSeconds(secondsLocal * 1);
  //                 }
  //             }
  //             setInitialLoading(false);
  //         }
  //         setInitialLoading(false);
  //     } else {
  //         setInitialLoading(false);
  //     }
  // }, [cookies?.scb, initialLoading, eventId]);

  // useEffect(() => {
  //     if (cookies?.scbSwitch) {
  //         console.log("cookies?.scbSwitch", cookies?.scbSwitch);
  //         if (cookies?.scbSwitch?.showScoreBoard === "show") {
  //             setShowScoreboard(true);
  //         } else if (cookies?.scbSwitch?.showScoreBoard === "hide") {
  //             setShowScoreboard(false);
  //         }
  //         if (cookies?.scbSwitch?.showPeriod === "show") {
  //             setShowPeriod(true);
  //         } else if (cookies?.scbSwitch?.showPeriod === "hide") {
  //             setShowPeriod(false);
  //         }
  //         if (cookies?.scbSwitch?.showScore === "show") {
  //             setshowScore(true);
  //         } else if (cookies?.scbSwitch?.showScore === "hide") {
  //             setshowScore(false);
  //         }
  //     }
  // }, []);

  // initScoreboard

  useEffect(() => {
    // first we need to get scoreboard status whethere it has been enabled or not

    const getScoreboardINITStatus = async () => {
      try {
        const data = {
          streamName: serviceId,
        };

        let res;

        res = await scoreBoardHandler(data, user?.userToken);

        console.log("res", res);
        if (res?.data.status !== "ok") {
          // this means scoreboard not enabled and we dont have to send any more request
          return;
        }

        // saving overlay param

        // toggle scoreb
        if (res?.data?.wowzaResponse.streamSettings?.scoreboardShowScore) {
          setshowScore(true);
        } else {
          setshowScore(false);
        }

        // toggle scoreboard
        if (res?.data?.wowzaResponse.streamSettings?.scoreboardImage) {
          setShowScoreboard(true);
        } else {
          setShowScoreboard(false);
        }

        let isAlreadyInitiated =
          res?.data?.wowzaResponse?.streamSettings?.enable;

        // if scoreboard enabled we get scoreboard data from wowza
        const resScoreboard = await scoreBoardHandler(
          data,
          user?.userToken,
          USE_UPDATED_API_ENDPOINT
        );
        console.log("res", resScoreboard);

        // clock

        if (
          resScoreboard?.data?.wowzaResponse?.scoreboardStatus?.timerStatus ===
          "START"
        ) {
          setClockRunning(true);

          let curTime =
            Date.now() -
            resScoreboard?.data?.wowzaResponse?.scoreboardStatus?.timerStart *
              1;

          console.log("curTime", curTime);

          let minutesLocal = Math.floor(curTime / 60000);

          let secondsLocal = ((curTime % 60000) / 1000).toFixed(0);

          console.log("minutesLocal,secondsLocal", minutesLocal, secondsLocal);

          setMinutes(minutesLocal * 1);
          setSeconds(secondsLocal * 1);
        } else {
          // timer is stopeed
          setClockRunning(false);

          if (
            resScoreboard?.data?.wowzaResponse?.scoreboardStatus?.timerStart
          ) {
            let curTime =
              resScoreboard?.data?.wowzaResponse?.scoreboardStatus?.prevTimer *
              1;

            console.log("curTime", curTime);

            let minutesLocal = Math.floor(curTime / 60000);

            let secondsLocal = ((curTime % 60000) / 1000).toFixed(0);

            console.log(
              "minutesLocal,secondsLocal",
              minutesLocal,
              secondsLocal
            );

            setMinutes(minutesLocal * 1);
            setSeconds(secondsLocal * 1);
          }
        }

        // scores
        if (resScoreboard?.data?.wowzaResponse.scoreboardStatus?.scoreLeft) {
          setScoreTeamA(
            resScoreboard?.data?.wowzaResponse?.scoreboardStatus?.scoreLeft * 1
          );
        }
        if (resScoreboard?.data?.wowzaResponse.scoreboardStatus?.scoreRight) {
          setScoreTeamB(
            resScoreboard?.data?.wowzaResponse?.scoreboardStatus?.scoreRight * 1
          );
        }
        // period

        if (resScoreboard?.data?.wowzaResponse.scoreboardStatus?.period) {
          let curPer =
            resScoreboard?.data?.wowzaResponse.scoreboardStatus?.period.split(
              ""
            );
          console.log("cutPer", curPer);

          if (curPer[0] === "P") {
            // it is normal period
            setGamePeriod(curPer[1] * 1);
          } else {
            // extra perios
            setGamePeriod(curPer[1] * 1 + 3);
          }

          setScoreTeamB(
            resScoreboard?.data?.wowzaResponse?.scoreboardStatus?.scoreRight * 1
          );
        }

        // show or hide period
        if (resScoreboard?.data?.wowzaResponse.scoreboardStatus?.period) {
          setShowPeriod(true);
        } else {
          setShowPeriod(false);
        }

        setTimeout(() => {
          setScoreboardEnabled(isAlreadyInitiated);
        }, 100);
      } catch (error) {
        // return toast.error("please start the streaming first before using scoreboard");
      }
    };

    !scoreboardEnabled && getScoreboardINITStatus();
  }, [scoreboardEnabled, serviceId, setScoreboardEnabled, user?.userToken]);

  const scoreBoardINIT = async () => {
    try {
      const data = {
        streamName: serviceId,
        enable: true,
        scoreboardSecondTeam: state.inputs.AWAY_TEAM_NAME.value,
        scoreboardFirstTeam: state.inputs.HOME_TEAM_NAME.value,

        scoreboardImage: "scoreboard.png",
        scoreboardImageLocation: "",
        scoreboardFirstTeamLocation: "10,40",
        scoreboardSecondTeamLocation: "120,40",
        scoreboardPeriodLocation: "10,70",
        scoreboardScoreLocation: "60,40",
        scoreboardTimerLocation: "120,70",
        scoreboardFontColor: "#ffffff",
        scoreboardFontSize: "20",
        // font
        font: "Heading Pro Treble",
      };
      console.log("data", data);
      const res1 = await scoreBoardHandler(data, user?.userToken);
      if (res1?.data.status !== "ok") {
        return;
      }
      console.log("res1", res1);
      setScoreboardEnabled(true);
      setIsINITMODAL(false);
    } catch (error) {
      setIsINITMODAL(false);
      return toast.error(t(`scoreboard.noStreamingBeforeScoreboardError`));
    }
  };
  console.log("scoreboardEnabled", scoreboardEnabled);
  useEffect(() => {
    // we update request every 1.5 s after user changes score
    const delayDebounceFn = setTimeout(async () => {
      if (updateScore?.streamName) {
        console.log(updateScore?.streamName);
        console.log("updateScore", updateScore);
        console.log("scoreTeamA,scoreTeamB", scoreTeamA, scoreTeamB);

        // // setLocalCookie();
        await scoreBoardHandler(
          updateScore,
          user?.userToken,
          USE_UPDATED_API_ENDPOINT
        );
        setUpdateScore(null);
        setScoreLoading(false);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [updateScore]);

  useEffect(() => {
    // we update request every 1.5 s after user changes score
    const delayDebounceFn = setTimeout(async () => {
      if (updatePeriod?.streamName) {
        console.log(updatePeriod?.streamName);
        // lets save them in cookie

        // setLocalCookie();

        // if we send any period to backend ->it auto shows the period even ifit was hidden
        setShowPeriod(true);
        await scoreBoardHandler(
          updatePeriod,
          user?.userToken,
          USE_UPDATED_API_ENDPOINT
        );
        setUpdatePeriod(null);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [updatePeriod]);

  // to change team score

  const teamScoreHandler = async (val, type) => {
    let data = {
      streamName: serviceId,
    };

    if (type === "home") {
      data.scoreLeft = val;
      setScoreTeamA(val);
    } else {
      data.scoreRight = val;
      setScoreTeamB(val);
    }
    setUpdateScore(data);
    setScoreLoading(true);
  };
  // const setLocalCookie = useCallback(
  //     (updatedTime) => {
  //         console.log("updatedTime", updatedTime);
  //         let stopLastTime, startLastTime;
  //         console.log("scoreBoardStatus.current ", scoreBoardStatus.current);

  //         if (scoreBoardStatus.current === "stop") {
  //             // we save stopLastTime
  //             startLastTime = false;
  //             // we save total time in seconds
  //             stopLastTime = minutes * 60 + seconds * 1;
  //         } else if (scoreBoardStatus.current === "start") {
  //             // we also have to add minutes and seconds to current time
  //             // converting minutes and seconds addition to milliseconds
  //             let curTime = (minutes * 60 + seconds * 1) * 1000;

  //             startLastTime = Date.now();
  //             stopLastTime = updatedTime ? updatedTime * 1 : curTime * 1;
  //         }

  //         let cookieData = {
  //             period: gamePeriod,
  //             eventId,
  //             awayTeamScore: scoreTeamB,
  //             homeTeamScore: scoreTeamA,
  //             clockRunning: scoreBoardStatus.current ? scoreBoardStatus?.current : clockRunning ? "start" : "stop",
  //         };

  //         if (scoreBoardStatus.current) {
  //             cookieData.stopLastTime = stopLastTime;
  //             cookieData.startLastTime = startLastTime;
  //         } else {
  //             cookieData.stopLastTime = cookies?.scb?.stopLastTime;
  //             cookieData.startLastTime = cookies?.scb?.startLastTime;
  //         }

  //         setCookie("scb", cookieData, {
  //             path: "/",
  //         });
  //     },
  //     [
  //         eventId,
  //         gamePeriod,
  //         scoreTeamA,
  //         scoreTeamB,
  //         setCookie,
  //         scoreBoardStatus,
  //         minutes,
  //         seconds,
  //         cookies?.scb?.stopLastTime,
  //         cookies?.scb?.startLastTime,
  //         clockRunning,
  //     ]
  // );

  // to change value of period

  const periodChangeHandler = (val) => {
    setGamePeriod(val);
    // we have p1 p2 p3 for normal time and more than 3 we have e1 and more
    let data = {
      streamName: serviceId,
    };

    if (val <= 3) {
      // normal time period
      data.period = `P${val}`;
    } else if (val > 3) {
      // extra time period
      // 1-3 cause p4 is acutal E1
      data.period = `E${val * 1 - 3}`;
    }
    setUpdatePeriod(data);
  };

  const changeSwitchCookieVal = (type, val) => {
    let cookieData = cookies?.scbSwitch || {};

    cookieData[type] = val ? "show" : "hide";
    setCookie("scbSwitch", cookieData, {
      path: "/",
    });
  };

  // to show period or not

  const showPeriodHandler = async (val) => {
    setShowPeriod(val);

    changeSwitchCookieVal("showPeriod", val);

    /* 
         To hide period you should send an empty value :

        curl -d '{"streamName":"score","period":""}'  [http://[wowza_IP]:8086/overlay]http://[wowza_IP]:8086/overlay 
        
        */

    let curGamePeiod;
    if (!val) {
      curGamePeiod = "";
    } else {
      curGamePeiod =
        (cookies?.scb?.period ? cookies?.scb?.period : gamePeriod) * 1;
      if (curGamePeiod * 1 <= 3) {
        // normal time period
        curGamePeiod = `P${curGamePeiod}`;
      } else {
        // extra time period
        // 1-3 cause p4 is acutal E1
        curGamePeiod = `E${curGamePeiod - 3}`;
      }
    }
    const data = {
      streamName: serviceId,
      period: curGamePeiod,
    };

    // this uses scoreboard api not overlay api

    await scoreBoardHandler(data, user?.userToken, USE_UPDATED_API_ENDPOINT);
  };

  // to show both teams scores or not
  const showScoreHandler = async (val) => {
    setshowScore(val);

    changeSwitchCookieVal("showScore", val);
    const data = {
      streamName: serviceId,
      scoreboardShowScore: val ? true : false,
    };

    await scoreBoardHandler(data, user?.userToken);
  };

  // to show scoreboard or not ->to hide send value as empty string

  const showScoreBoardHandler = async (val) => {
    setShowScoreboard(val);

    changeSwitchCookieVal("showScoreBoard", val);

    const data = {
      streamName: serviceId,
      scoreboardImage: val ? "scoreboard.png" : "",
    };

    await scoreBoardHandler(data, user?.userToken);
  };

  // to set team names

  const setBothTeamNames = async (teamA = "koovee", teamB = "SCH") => {
    const data = {
      streamName: serviceId,
      scoreboardSecondTeam: teamA,
      scoreboardFirstTeam: teamB,
    };

    await scoreBoardHandler(data, user?.userToken, USE_UPDATED_API_ENDPOINT);
  };

  // to show/ hide timer
  const showTimerHandler = async (val) => {
    const data = {
      streamName: serviceId,
      scoreboardShowTimer: val ? true : false,
    };

    await scoreBoardHandler(data, user?.userToken, USE_UPDATED_API_ENDPOINT);
  };

  return (
    <>
      {isINITMODAL && (
        <Modal
          button1OnClick={() => setIsINITMODAL(false)}
          button2OnClick={() => scoreBoardINIT()}
          buttonText1={t("eventsCategory.cancel")}
          buttonText2={t("eventsCategory.confirm")}
          text={t("scoreboard.enterTeamNames")}
          childElement={
            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                width: "80%",
                margin: "0 auto",
                marginTop: "20px",
              }}
            >
              <Input1
                id="HOME_TEAM_NAME"
                label={t("addNewSubOrganization.teamName")}
                type="text"
                element="input"
                validators={[VALIDATOR_NONE()]}
                onInput={InputHandler}
                iconName="home"
                initialValue={homeTeamName ? homeTeamName : ""}
                initialValid={true}
              />

              <Input1
                id="AWAY_TEAM_NAME"
                label={t("addNewSubOrganization.opponent")}
                type="text"
                element="input"
                validators={[VALIDATOR_NONE()]}
                onInput={InputHandler}
                iconName="arrow-circle-right"
                initialValue={awayTeamName ? awayTeamName : ""}
                initialValid={true}
              />
            </div>
          }
        />
      )}
      {!scoreboardEnabled ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: mobileView ? "" : "flex-end",
              width: mobileView ? "100%" : "auto",
            }}
          >
            {" "}
            <Button
              style={{
                width: mobileView ? "100%" : "auto",
              }}
              onClick={() => setIsINITMODAL(true)}
            >
              {t("scoreboard.useScoreboard")}
            </Button>
          </div>
        </>
      ) : (
        <ScoreboardItem
          bottomCTA={bottomCTA}
          clockRunning={clockRunning}
          scoreTeamB={scoreTeamB}
          scoreTeamA={scoreTeamA}
          teamScoreHandler={teamScoreHandler}
          seconds={seconds}
          setSeconds={setSeconds}
          minutes={minutes}
          setMinutes={setMinutes}
          gamePeriod={gamePeriod}
          periodChangeHandler={periodChangeHandler}
          showPeriod={showPeriod}
          showScoreboard={showScoreboard}
          showScore={showScore}
          showPeriodHandler={showPeriodHandler}
          showScoreHandler={showScoreHandler}
          showScoreBoardHandler={showScoreBoardHandler}
          homeTeamName={homeTeamName}
          awayTeamName={awayTeamName}
          scoreLoading={scoreLoading}
        />
      )}
    </>
  );
}
