import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import Modal from "react-modal";

import {
  getLiveNowGames,
  getUpcomingGames,
} from "../../../scripts/dataHandlers";

import * as classes from "../Events.module.css";
import Header from "../../../Shared/Header/Header";
import { useTranslation } from "react-i18next";

import { getImageByKey } from "../../../scripts/getImageByKey";
import { useSearchParams } from "../../../Shared/Hooks/search-hook";
import { useMyContext } from "../../../contexts/StateHolder";
import EventsItem1 from "../../Events/Events/EventsItem1/EventsItem1";

Modal.setAppElement(document.body);

export default function LiveNowEventsCategoryItem({
  accessedbyAdmin,
  setting,
  categoryIdProps,
  organizationIdProps,
  extraClassname,
  hideViewAll,
  styles,
  hideCarousel,
  viewAllRoute,
  heading,
}) {
  const { t } = useTranslation();

  let { organizationId } = useParams();

  let categoryId = "liveEvent";

  let orgId = accessedbyAdmin ? organizationIdProps : organizationId;

  const [allEvents, setAllEvents] = useState(null);

  const {
    selectedArea,
    selectedLevelContext,
    resetSelectedLevel,
    resetSelectedArea,
    setSelectedArea,
    setSelectedLevelContext,
  } = useMyContext();
  const { searchParam } = useSearchParams(
    selectedArea,
    selectedLevelContext,
    resetSelectedArea,
    resetSelectedLevel
  );

  const fetchOnlyUpcomingEvents = useCallback(async (oId) => {
    let res = await getLiveNowGames(oId);

    let s = res?.data?.games.sort((a, b) => a.startTime - b.startTime);

    setAllEvents(s);
  }, []);

  useEffect(() => {
    if (accessedbyAdmin) {
      organizationIdProps &&
        categoryIdProps &&
        fetchOnlyUpcomingEvents(
          organizationIdProps,
          categoryIdProps,
          searchParam
        );
    } else {
      organizationId &&
        categoryId &&
        fetchOnlyUpcomingEvents(organizationId, categoryId, searchParam);
    }
  }, [
    organizationId,
    accessedbyAdmin,
    fetchOnlyUpcomingEvents,
    categoryIdProps,
    organizationIdProps,
  ]);

  useEffect(() => {
    setSelectedArea([]);
    setSelectedLevelContext([]);

    return () => {
      setSelectedArea([]);
      setSelectedLevelContext([]);
    };
  }, []);

  return (
    <>
      <div className={classes.Events_main} style={{ margin: "0 auto" }}>
        <div className={classes.Events_main_info}>
          <Header
            extraClassname={extraClassname}
            style={styles}
            title={heading}
            admin={accessedbyAdmin}
            showTitle={accessedbyAdmin}
          />
        </div>
      </div>
      {allEvents && allEvents?.length > 0 ? (
        <>
          <EventsItem1
            allEvents={allEvents}
            heading={heading}
            organizationId={orgId}
            // need to set prooperty to true so we can add calendar icon herej
            hidetitle={heading}
            hideViewAll={hideViewAll}
            settings={setting}
            accessedbyAdmin={accessedbyAdmin}
            styles={styles}
            hideCarousel={hideCarousel}
            viewAllRoute={viewAllRoute}
          />
        </>
      ) : (
        <div className={classes.NoDataAvailable}>
          <img
            src={getImageByKey("no_data_available")}
            alt="no_data_available"
          />
        </div>
      )}
    </>
  );
}
