import React, { useState } from 'react';
import * as classes from './SideBar.module.css';
import * as settings from '../../../config_settings.json';
import NavBarButton from '../../NavBars/NavBarButton';
import AdminNavBar from '../../NavBars/AdminNavBar/AdminNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default function SideBar({ styles }) {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  return (
    // <div className={classes.navBarRow_primary_sideBar}>
    //   <NavBarButton
    //     route={settings.routes.changePassword}
    //     name={'Change Password'}
    //   />

    //   <NavBarButton route={settings.routes.userDetails} name={'User Details'} />
    //   <NavBarButton
    //     route={settings.routes.subscription}
    //     name={'Subscription'}
    //   />
    // </div>

    <>
      <div
        className={`${
          sidebarOpen ? 'side-bar' : ' side-bar collapse'
        } protrebleextrabold`}
      >
        <div
          className='logo-name-wrapper font-600'
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <div className='logo-name'>
            <span className='logo-name__name'>
              {t('profileMenu.userProfile')}
            </span>
          </div>
          <button className='logo-name__button'>
            {!sidebarOpen ? (
              <FontAwesomeIcon
                icon='chevron-right'
                className='logo-name__icon font-700'
              />
            ) : (
              <FontAwesomeIcon
                icon='chevron-left'
                className='logo-name__icon font-700'
              />
            )}
          </button>
        </div>
        <ul className='features-list'>
          <AdminNavBar
            icon='lock'
            route={settings.routes.changePassword}
            name={t('profileMenu.changePassword')}
          />

          <AdminNavBar
            route={settings.routes.userDetails}
            name={t('profileMenu.userDetails')}
            icon='user'
          />

          <AdminNavBar
            route={settings.routes.subscription}
            name={t('profileMenu.subscriptions')}
            icon={'arrow-right'}
          />
        </ul>
      </div>
    </>
  );
}
