import { useMyContextFunctions } from "../../contexts/ContextFunctions";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useForm } from "../../Shared/Hooks/form-hook";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
} from "../../Shared/Validation/Validator";
import { Input1 } from "../../Shared/Input1/Input1";
import * as classes from "../../Shared/Input1/Input1.module.css";
import { toast } from "react-toastify";
import { useMyContext } from "../../contexts/StateHolder";
import Button from "../../Shared/Button/Button";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import Header from "../../Shared/Header/Header";

// Renders contentRatings of chosen item
const LoginForm = (props) => {
  // Bring stateholders from context
  const { authProcess } = useMyContextFunctions();
  const { goBackToPrevious, setGoBackToPrevious, UILoading } = useMyContext();

  const [allSubOrganization, setAllSubOrganization] = useState(null);
  const [cookies, setCookie] = useCookies("");
  console.log("cookies", cookies?.redirectUrl);

  const { admin, superAdmin } = useParams();
  console.log("superAdmin", superAdmin);

  // we need to import UseForm hook and pass the initial inputs and initialFormValidity to userform
  const [state, InputHandler] = useForm(
    {
      EMAIL: {
        value: "",
        isValid: false,
      },
      PASSWORD: {
        value: "",
        isValid: false,
      },

      SUBORG: {
        value: "",
        isValid: true,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );

  useEffect(() => {
    return () => {
      setGoBackToPrevious(false);
    };
  }, []);

  const { t } = useTranslation();

  const history = useHistory();

  // Handle the click of "Next" button
  const formSubmitHandler = async (e) => {
    e.preventDefault();

    // console.log(state.inputs.EMAIL.value, state.inputs.PASSWORD.value);

    try {
      // Do the signUp/login process
      const authResponse = await authProcess(
        state.inputs.EMAIL.value,
        state.inputs.PASSWORD.value,
        superAdmin
          ? "Affiliate Administrator"
          : admin && "ismaccount administrator",

        // affilate admin is in parent account so no need to find any more info for affilate admin yet
        admin && allSubOrganization?.length > 0 && state.inputs.SUBORG.value
      );

      if (authResponse.data.status === "error") {
        console.log("authResponse.data.message", authResponse.data.message);

        if (
          admin &&
          authResponse.data.message.includes("exists with the key")
        ) {
          return toast.error(t("loginForm.clubError"), {
            autoClose: 6000,
            position: "top-center",
            closeOnClick: true,
            draggable: true,
            theme: "dark",
          });
        }

        return toast.error(authResponse.data.message, {
          autoClose: 6000,
          position: "top-center",
          closeOnClick: true,
          draggable: true,

          theme: "dark",
        });
      } else if (
        authResponse.data.status === "ok" &&
        authResponse?.data?.authentications &&
        authResponse?.data?.authentications.length > 1
      ) {
        // we need to check if their is authentications array
        toast.info("Please select clubname from dropdown list", {
          autoClose: 6000,
          position: "bottom-center",
          closeOnClick: true,
          draggable: true,
          theme: "dark",
        });
        if (authResponse?.data) {
          let y = [];
          authResponse?.data?.authentications.forEach((el) => {
            let g = {
              value: el?.user_organization_id,
              label: el?.user_organization_name,
            };
            y.push(g);
          });

          setAllSubOrganization(y);
        }

        // now we need to show dropdownlist
      } else if (authResponse.data.status === "ok") {
        setGoBackToPrevious(false);

        setAllSubOrganization(null);
        console.log(superAdmin);
        if (superAdmin) {
          // we will redirect to select org page first
          return history.push(`/${props.routes.settingsChangeOrganization}`);
        }
        let lastUrl = cookies?.redirectUrl;
        if (lastUrl) {
          // wee are deleting cookies
          setCookie("redirectUrl", { path: "/", expires: 1 });
        }

        goBackToPrevious
          ? history.push(lastUrl)
          : history.push(`${props.routes.home}`);
      } else {
        // this means our server has died so need to give appropriate messages

        toast.error(`${t("shared.systemError")}`, {
          autoClose: 3000,
          position: "top-center",
          closeOnClick: true,
          draggable: true,
          theme: "dark",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="loginViewBackground"
      style={
        props.background
          ? { backgroundImage: `url(${getImageByKey("signUpBackground")}` }
          : { backgroundImage: `none` }
      }
    >
      <div className={`${classes.formPrimary} ${classes.formPrimarySignIn}`}>
        <div className={classes.formSecondary}>
          <form
            className={`${classes.formBlog} ${admin && classes.formBlogAdmin2}`}
            onSubmit={formSubmitHandler}
          >
            <Header
              title={
                superAdmin
                  ? t("loginForm.superAdminLogin")
                  : admin
                  ? t("loginForm.adminLogin")
                  : t("login.loginButton")
              }
              extraClassname
              admin={props.admin}
              showBackButton={true}
            />
            {goBackToPrevious && (
              <p
                className="font-400 smallcasebold "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("loginForm.loginForAccess")}
              </p>
            )}

            <Input1
              id="EMAIL"
              label={t("signUpBar.inputPlaceholderEmail")}
              placeholder={t("Enter your email here")}
              type="text"
              element="input"
              validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE()]}
              errorText={t("signUpBar.errorIncorrectEmailAddress")}
              onInput={InputHandler}
              iconName="envelope"
            />

            <Input1
              id="PASSWORD"
              label={t("signUpForm.password")}
              placeholder={t("signUpForm.askForPassword")}
              errorText={t("signUpForm.askForPassword")}
              type="Password"
              element="input"
              validators={[VALIDATOR_REQUIRE()]}
              onInput={InputHandler}
              iconName="lock"
            />

            {admin && allSubOrganization?.length > 0 && (
              <Input1
                id="SUBORG"
                label={t("navBar.clubs")}
                placeholder={t("loginForm.selectClub")}
                errorText={t("loginForm.errorClub")}
                type="text"
                element="select_dropdown"
                validators={[VALIDATOR_REQUIRE()]}
                onInput={InputHandler}
                initialValid={admin ? false : true}
                selectData={allSubOrganization}
              />
            )}

            <Button
              className={classes.loginFormButton}
              disabled={!state.isValid || UILoading}
              formButton
              inverse
            >
              {UILoading ? t("shared.loading") : t("login.loginButton")}
            </Button>
            {!goBackToPrevious && (
              <div
                className={`${classes.loginRow} 
              
              font-200 regular`}
              >
                {!admin && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <div> {`${t("loginForm.newUserLink")}  `} </div>
                      <Link to={`/${props.routes.signUp}`}>{`${t(
                        "loginForm.signUpLink"
                      )}  `}</Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <div>{t("resetPassword.forgotPassword")}?</div>
                      <Link to={`/${props.routes.requestResetPassword}`}>
                        {t("loginForm.clickHere")}
                      </Link>
                    </div>
                  </>
                )}
                {admin && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <div>{`${t("loginForm.loginAsUser")}`}?</div>
                      <div>
                        {" "}
                        <Link to={`${`/${props.routes.login}`}`}>
                          {t("loginForm.clickHere")}
                        </Link>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <div>{t("resetPassword.forgotPassword")}?</div>
                      <Link to={`/${props.routes.requestResetAdminPassword}`}>
                        {t("loginForm.clickHere")}
                      </Link>
                    </div>
                  </>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
