import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../../contexts/StateHolder";
import { convertDate, addZeroes, convertCurrency } from "../../../../scripts/utils";
import * as classes from './Subscription.module.css';

//single subscription method
const Subscription = (props) => {
  const { product, count } = props;
  const { profilePackages, paymentMethods } = useMyContext();
  const productPackage = profilePackages.filter(pp => pp.sku === product.sku);

  console.log('subscription', product);
  console.log('PP: ',productPackage);
  console.log('PPS: ',profilePackages);

  const { t } = useTranslation();

  if (!product.quantity && productPackage.length === 0){
    return null;
  } else {
    return (
      <div className={classes.item} key={count}>
        <div className={`${classes.itemName} font-600`}>{ (product.productName ? product.productName : productPackage[0].name ) }</div>
        <div className={classes.inner}>
          <div className={classes.innerRow}>
            <div className={classes.subTitle}>{`${t("subscriptions.quantity")}:`}</div>
            <div className={classes.innerValue}>{product.quantity > 0 ? product.quantity : 1}</div>
          </div>
          <div className={classes.innerRow}>
            <div className={classes.subTitle}>{`${t("subscriptions.price")}:`}</div>
            <div className={classes.innerValue}>
              {addZeroes( (product.price ? product.price : productPackage[0].price ) )} {convertCurrency( (product.currency ? product.currency : productPackage[0].currency ) )}
            </div>
          </div>
          <div className={classes.innerRow}>
            <div className={classes.subTitle}>{`${t("subscriptions.priceTotal")}:`}</div>
            <div className={classes.innerValue}>
              {addZeroes( (product.quantity ? product.quantity * product.price : productPackage[0].price ) )} {convertCurrency( (product.currency ? product.currency : productPackage[0].currency ) )}
            </div>
          </div>
          {product.tickets && product.tickets.length > 0 && (
            <div className={classes.innerRow}>
              <div className={classes.subTitle}>{`${t("subscriptions.tickets")}:`}</div>
              <div className={classes.innerValueTickets}>
                {product.tickets.split(",").map((ticket, i) => (
                  <div className={classes.innerTicket} key={i}>
                    {ticket}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
};

export default Subscription;