import Axios from "axios";
import {
	checkArguments,
	createAccountToken,
	createChangePasswordToken,
	createGeneralToken,
	createSubOrgToken,
	getCurrentTime,
} from "./utils";
import { countries } from "./countries";
import * as settings from "../config_settings.json";

let API_Server = "";

let API_Server_MY = "";

if (settings.API_Server_Number === 1) {
	API_Server = "https://suite.icareus.com";
	API_Server_MY = "https://my.icareus.com";
} else if (settings.API_Server_Number === 2) {
	API_Server = "https://suite.icareus.com";
	API_Server_MY = "https://my.icareus.com";
} else if (settings.API_Server_Number === 3) {
	API_Server = "https://suiterc.icareus.com";
	API_Server_MY = "https://suiterc.icareus.com";
}

// scoreboard

export const scoreBoardHandler = async (data, token, scoreboardURL) => {
	// there are two end points for sending api request
	try {
		let url = `${API_Server}/api/game/scoreboard?serviceId=${data.streamName}`;
		if (scoreboardURL) {
			url = `${API_Server}/api/game/scoreboard?serviceId=${data.streamName}&scoreboard=true`;
		}

		const res = await Axios({
			method: "POST",
			url,

			data,

			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return res;
	} catch (err) {
		console.log(err);
		return err;
	}
};

// Get all category names and id:s
const getCategories = async (organizationId, key, lang, user) => {
	if (checkArguments(organizationId, key, lang)) {
		try {
			const catResponse = await Axios.get(
				`${API_Server}/api/archive?action=getGroups&organizationId=${organizationId}&userId=${
					user?.userId || 0
				}&userToken=${user?.userToken || ""}&languageId=${lang}&token=${key}&groupTypeName=Folders`
			);
			console.log("getCategories: ", catResponse);
			return catResponse.data.groupItems;
		} catch (err) {
			console.log(err);
		}
	}
};
// get Latest Assets
const getLatestAssets = async (organizationId, token, languageId, limit) => {
	if (checkArguments(organizationId, token, languageId)) {
		try {
			const itemResponse = await Axios.get(
				`${API_Server}/api/publishing?organizationId=${organizationId}&languageId=${languageId}&limit=${
					limit ? limit : 10
				}&onlyVideos=true&action=getLatestAssets&token=${token}`
			);
			// console.log("get latest Asset: ", itemResponse);
			return itemResponse.data;
		} catch (err) {
			console.log(err);
		}
	}
};
const getTrendingAssets = async (organizationId, token, languageId, limit, days) => {
	if (checkArguments(organizationId, token, languageId)) {
		try {
			const res = await Axios.get(
				`${API_Server}/api/publishing?organizationId=${organizationId}&amount=${
					limit ? limit : 10
				}&days=${days ? days : 7}0&onlyVideos=true&action=getMostPopular&token=${token}`
			);
			// console.log("get trending Asset: ", res);
			const res1 = res.data.assets.map((el) => el.asset);

			return res1;
		} catch (err) {
			console.log(err);
		}
	}
};

export const searchGames = async (organizationId, keyword, limit) => {
	try {
		const config = {
			headers: {
				"Cache-Control": "cache",
				Pragma: "cache",
				Expires: "0",
			},
		};

		let url = `${API_Server}/api/games?action=search&version=02&organizationId=${organizationId}&skip=0&limit=${
			limit ? limit : 100
		}&keyword=${keyword}`;
		const res = await Axios.get(url);
		return res;
	} catch (error) {
		console.log(error);
		return error;
	}
};

// Get assets that are listed in groupItemId -string
const getSeries = async (organizationId, key, languageId, user, assetProperty) => {
	console.log(organizationId, key, languageId, user, assetProperty, "assetProperty");

	if (checkArguments(organizationId, key, languageId)) {
		try {
			const seriesResponse = await Axios.get(`${API_Server}/api/archive?action=getGroups`, {
				params: {
					organizationId,
					languageId,
					groupTypeName: "Series",
					...(user?.userId ? { userId: user?.userId } : {}),
					...(user?.userToken ? { userToken: user?.userToken } : {}),
					...(assetProperty ? { assetProperty: assetProperty } : {}),
				},
			});

			console.log("getSeries: ", seriesResponse);
			return seriesResponse.data.groupItems;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get subCategories with groupItemId string (single or separated by comma)
const getSubCategories = async (organizationId, token, groupItemId, lang, user) => {
	if (checkArguments(organizationId, token, groupItemId, lang)) {
		console.log(organizationId, token, groupItemId, lang);
		try {
			const catResponse = await Axios.get(
				`${API_Server}/api/archive?action=getGroup&organizationId=${organizationId}&groupItemIds=${groupItemId}&userId=${
					user?.userId || 0
				}&userToken=${user?.userToken || ""}&languageId=${lang}&token=${token}&showHidden=true`
			);
			console.log("getSubCategories: ", catResponse);
			return catResponse.data.data;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get all category names and id:s with root category id
const getRootSubCategories = async (organizationId, key, lang, groupItemId, user) => {
	if (checkArguments(organizationId, groupItemId, key, lang, groupItemId)) {
		try {
			const catResponse = await Axios.get(
				`${API_Server}/api/archive?action=getGroups&organizationId=${organizationId}&userId=${
					user?.userId || 0
				}&userToken=${
					user?.userToken || ""
				}&languageId=${lang}&token=${key}&groupItemId=${groupItemId}`
			);
			console.log("getRootSubCategories: ", catResponse);
			return catResponse.data.groupItems;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get assets that are listed in groupItemId -string
const getAssets = async (organizationId, groupItemId, token, languageId, assetProperty, user) => {
	if (checkArguments(organizationId, groupItemId, token, languageId)) {
		try {
			const itemResponse = await Axios.get(`${API_Server}/api/publishing/getAssets?version=06`, {
				params: {
					organizationId,
					languageId,
					groupItemId,
					token,
					...(user?.userId ? { userId: user?.userId } : {}),
					...(user?.userToken ? { userToken: user?.userToken } : {}),
					...(assetProperty ? { assetProperty: assetProperty } : {}),
				},
			});

			console.log("getAssets: Editor ", itemResponse);
			return itemResponse.data.assets;
		} catch (err) {
			console.log(err);
		}
	}
};

// create new event
const createNewEvent = async (organizationId, event, token) => {
	try {
		let url = `${API_Server}/api/event`;

		const res1 = await Axios({
			method: "post",
			url: url,

			data: event,

			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		console.log(`res create event`, res1);

		return res1;
	} catch (error) {
		console.log(error);
		return error;
	}
};

// create New Game

const createNewGame = async (event, token) => {
	try {
		let url = `${API_Server}/api/game`;

		const res1 = await Axios({
			method: "post",
			url: url,

			data: event,

			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		console.log(`res create game`, res1);

		return res1;
	} catch (error) {
		console.log(error);
		return error;
	}
};

export const createTicketAccess = async (ticketObject, token, edit) => {
	console.log("ticketObject: ", ticketObject);
	try {
		let url = `${API_Server}/api/event/accesscontrol`;
		const res1 = await Axios({
			method: edit ? "put" : "post",
			url: url,

			data: ticketObject,

			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		console.log(`res create game`, res1);

		return res1;
	} catch (error) {
		console.log(error);
		return error;
	}
};

const updateSingleGame = async (event, token) => {
	try {
		let url = `${API_Server}/api/game`;

		const res1 = await Axios({
			method: "put",
			url: url,

			data: event,

			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		console.log(`res create game`, res1);

		return res1;
	} catch (error) {
		console.log(error);
		return error;
	}
};

// edit single event
const editEvent = async (organizationId, event, token) => {
	try {
		let url = `${API_Server}/api/event`;

		const res1 = await Axios({
			method: "put",
			url: url,

			data: event,

			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		console.log(`res edit event`, res1);

		return res1;
	} catch (error) {
		console.log(error);
		return error;
	}
};
// delete event
const deleteEvent = async (organizationId, eventId, token) => {
	try {
		let url = `${API_Server}/api/event?organizationId=${organizationId}&eventId=${eventId}`;

		let event = {
			organizationId: organizationId * 1,

			eventId: eventId,
		};
		console.log(event);

		const res1 = await Axios({
			method: "delete",
			url: url,

			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		console.log(`res edit event`, res1);

		return res1;
	} catch (error) {
		console.log(error);
		return error;
	}
};
// delete game
const deleteGame = async (organizationId, gameId, token) => {
	console.log("organizationId", organizationId, gameId, token);
	try {
		let url = `${API_Server}/api/game?organizationId=${organizationId}&gameId=${gameId}`;
		// ${API_Server}/api/game?organizationId=181282321&gameId=5116302

		const res1 = await Axios({
			method: "delete",
			url: url,

			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		console.log(`res edit event`, res1);

		return res1;
	} catch (error) {
		console.log(error);
		return error;
	}
};
export const deleteAccessControl = async (organizationId, gameId, token) => {
	try {
		let url = `${API_Server}/api/event/accesscontrol?organizationId=${organizationId}&gameId=${gameId}`;

		const res1 = await Axios({
			method: "delete",
			url: url,

			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		console.log(`res edit event`, res1);

		return res1;
	} catch (error) {
		console.log(error);
		return error;
	}
};
// get upcomingEvents based on currentTime
const getUpcomingEvents = async (organizationId, limit, from, to) => {
	try {
		const currentTime = Date.now();
		let url = `${API_Server}/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}&getSubOrganizationEvents=true`;

		if (from && !to) {
			url = `${url}&from=${from}`;
		}

		if (from && to) {
			url = `${url}&from=${from}&to=${to}`;
		}
		if (!from && to) {
			url = `${url}&to=${to}`;
		}
		if (!from && !to) {
			url = `${url}&from=${currentTime}`;
		}

		const res = await Axios.get(url);
		console.log(`res upcoming events`, res);

		return res;
	} catch (error) {
		console.log(error);
	}
};

const getUpcomingGames = async (
	organizationId,
	limit,
	from,
	to,
	searchParams,
	isPastEvent,
	isPastEventFiltering
) => {
	try {
		const currentTime = Date.now();
		let url = `${API_Server}/api/games?action=search&version=02&organizationId=${organizationId}&skip=0&limit=${
			limit ? limit : 100
		}`;
		let mainUrl = url;
		// console.log(from, to, searchParams);
		if (from && !to) {
			url = `${url}&from=${from}`;
		}

		if (!from && to) {
			url = `${url}&to=${to}`;
		}
		if (!from && !to) {
			url = `${url}&from=${currentTime}`;
		}
		if (from && to) {
			url = `${url}&from=${from}&to=${to}`;
		}

		if (isPastEvent) {
			if (isPastEventFiltering) {
				from = from;
				to = to;
			} else {
				from = null;
				to = Date.now();
			}
			url = `${mainUrl}&to=${to}&from=${from}`;
			// console.log("url", url);
		}
		if (searchParams) {
			// console.log("searchParams", searchParams);

			url = `${url}${searchParams}`;
		}
		if (isPastEvent) {
			url = `${url}&order=desc`;
		} else {
			url = `${url}&order=asc`;
		}
		const res = await Axios.get(url);
		// console.log(`res upcoming games`, res);
		// console.log("url", url);
		// console.log(organizationId, limit, from, to, searchParams, isPastEvent);

		return res;
	} catch (error) {
		console.log(error);
	}
};

const getSingleGame = async (organizationId, gameId, token) => {
	try {
		// ${API_Server}/api/game?organizationId=181282321&gameId=5116302
		let url = `${API_Server}/api/game?organizationId=${organizationId}&gameId=${gameId}`;

		const res = await Axios({
			method: "get",
			url,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return res?.data;
	} catch (error) {
		console.log(error);
	}
};
const getSingleGamePublic = async (organizationId, eventId) => {
	try {
		let url = `${API_Server}/api/games?action=getGame&organizationId=${organizationId}&eventId=${eventId}`;

		const res = await Axios({
			method: "get",
			url,
		});

		return res?.data;
	} catch (error) {
		console.log(error);
	}
};

// get upcomingEvents based on currentTime
const getLiveNowGames = async (organizationId, limit, searchParams) => {
	try {
		let url = `${API_Server}/api/games?action=search&version=02&organizationId=${organizationId}&skip=0&limit=${
			limit ? limit : 20
		}&ongoing=true`;

		if (searchParams) {
			// console.log("searchParams", searchParams);
			url = `${url}${searchParams}`;
		}

		const res = await Axios.get(url);
		// console.log(`getLiveNowEvents: `, res);

		return res;
	} catch (error) {
		console.log(error);
	}
};

export const getAllGamesFilter = async (organizationId) => {
	try {
		let url = `${API_Server}/api/games?organizationId=${organizationId}&action=getFilters`;

		const res = await Axios.get(url);
		// console.log("resGetAllFilters", res);

		return res?.data;

		// const res= await
	} catch (error) {
		console.log(error);
		return error;
	}
};

// get upcomingEvents based on currentTime
const getLiveNowEvents = async (organizationId) => {
	try {
		let url = `${API_Server}/api/events?action=getLiveEvents&organizationId=${organizationId}&includeSubOrganizationEvents=true`;

		const res = await Axios.get(url);
		console.log(`getLiveNowEvents: `, res);

		return res;
	} catch (error) {
		console.log(error);
	}
};

// Get all events
const getAllEvents = async (organizationId, categoryId, limit, from, to) => {
	try {
		let url;
		if (categoryId) {
			url = `${API_Server}/api/events?action=getEvents&version=02&organizationId=${organizationId}&categoryIds=${categoryId}&end=${limit}`;
		} else {
			url = `${API_Server}/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}`;
		}
		if (from && !to) {
			url = `${url}&from=${from}`;
		}

		if (from && to) {
			url = `${url}&from=${from}&to=${to}`;
		}
		if (!from && to) {
			url = `${url}&to=${to}`;
		}

		const res = await Axios.get(url);

		return res;
	} catch (error) {
		console.log(error);
	}
};
// // Get all events
// const getAllEventsBeta = async (
//   organizationId,
//   categoryId,
//   limit,
//   from,
//   to
// ) => {
//   try {
//     let url;
//     if (categoryId) {
//       url = `https://suiterc.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&categoryIds=${categoryId}&end=${limit}`;
//     } else {
//       url = `https://suiterc.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}`;
//     }
//     if (from && !to) {
//       url = `${url}&from=${from}`;
//     }

//     if (from && to) {
//       url = `${url}&from=${from}&to=${to}`;
//     }

//     const res = await Axios.get(url);

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

const getRecordings = async (organizationId, assetId, token) => {
	try {
		const res = await Axios.get(
			`${API_Server}/api/publishing/getAssetPlaybackUrls?version=03&organizationId=${organizationId}&assetId=${assetId}&token=${token}`
		);
		console.log(res, "recordings");
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

const getSingleEvent = async (organizationId, eventId) => {
	try {
		const res = await Axios.get(
			`${API_Server}/api/events?action=getEvent&version=02&organizationId=${organizationId}&eventId=${eventId}`
		);
		// console.log(res);
		return res.data;
	} catch (error) {}
};

// const getUpcomingEventsBeta = async (organizationId, limit, from, to) => {
//   try {
//     const currentTime = Date.now();
//     let url = `https://suiterc.icareus.com/api/events?action=getEvents&version=02&organizationId=${organizationId}&end=${limit}`;

//     if (from && !to) {
//       url = `${url}&from=${from}`;
//     }

//     if (from && to) {
//       url = `${url}&from=${from}&to=${to}`;
//     }
//     if (!from && !to) {
//       url = `${url}&from=${currentTime}`;
//     }

//     const res = await Axios.get(url);
//     console.log(`res upcoming eventsbeta`, res);

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };
// const getEventsCategoriesBeta = async (organizationId) => {
//   try {
//     const res = await Axios.get(
//       `https://suiterc.icareus.com/api/events?action=getCategories&version=02&organizationId=${organizationId}`
//     );
//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

const getEventsCategories = async (organizationId) => {
	try {
		const res = await Axios.get(
			`${API_Server}/api/events?action=getCategories&version=02&organizationId=${organizationId}`
		);
		return res;
	} catch (error) {
		console.log(error);
	}
};

const getChannels = async (organizationId, secret) => {
	try {
		// const res = await Axios.get(
		//   `${API_Server}/api/organization?action=getOrganization&version=04&organizationId=69922&includeSubOrganizations=true`
		// );
		let token = secret;
		const res = await Axios.get(
			`${API_Server}/api/organization?action=getOrganization&version=04&organizationId=${organizationId}&includeSubOrganizations=true&token=${token}`
		);
		return res;
	} catch (error) {
		console.log(error);
	}
};

const findSubOrganizationSecret = async (organizationId, key, subOrganizationId) => {
	if (organizationId * 1 === subOrganizationId * 1) {
		return settings.organization.key;
	}
	const res = await getChannels(organizationId, key);

	return Object.values(res?.data?.organization?.subOrganizations).find(
		(el) => el.organizationId === subOrganizationId * 1
	);
};

export const getAllSuborganization = async (organizationId, key) => {
	try {
		const res = await getChannels(organizationId, key);
		return res;
	} catch (error) {
		console.log(error);
	}
};
// const getChannelsBeta = async (organizationId, secret) => {
//   try {
//     // const res = await Axios.get(
//     //   `https://suiterc.icareus.com/api/organization?action=getOrganization&version=04&organizationId=3409909&includeSubOrganizations=true`
//     // );
//     let token = secret ? secret : "tNtSx9xU06";
//     const res = await Axios.get(
//       `https://suiterc.icareus.com/api/organization?action=getOrganization&version=04&organizationId=${organizationId}&includeSubOrganizations=true&token=${token}`
//     );
//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

// Get items that are listed in groupItemId -string
const getSimiliar = async (
	organizationId,
	groupItemId,
	token,
	lang,
	assetProperty,
	user,
	limit
) => {
	if (checkArguments(organizationId, groupItemId, token, lang, assetProperty)) {
		try {
			let limitValue = limit ? limit : 10;
			const itemResponse = await Axios.get(
				`${API_Server}/api/publishing/getAssets?version=06&organizationId=${organizationId}&userId=${
					user?.userId || 0
				}&userToken=${user?.userToken || ""}&assetProperty=${
					assetProperty || ""
				}&orderBy=publishStart&limit=${limitValue}&languageId=${lang}&groupItemId=${groupItemId}&token=${token}`
			);
			console.log("getSimiliar: ", itemResponse);
			return itemResponse.data.assets;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get items that are listed in groupItemId -string
const getAsset = async (organizationId, assetId, token, lang, assetProperty, user) => {
	if (checkArguments(organizationId, assetId, token, lang)) {
		console.log(organizationId, assetId, token, lang);

		try {
			const itemResponse = await Axios.get(
				`${API_Server}/api/publishing/getAsset?version=03&organizationId=${organizationId}&userId=${
					user?.userId || 0
				}&userToken=${user?.userToken || ""}&assetProperty=${
					assetProperty || ""
				}&languageId=${lang}&assetId=${assetId}&token=${token}`
			);
			console.log(
				`${API_Server}/api/publishing/getAsset?version=03&organizationId=${organizationId}&userId=${
					user?.userId || 0
				}&userToken=${user?.userToken || ""}&assetProperty=${
					assetProperty || ""
				}&languageId=${lang}&assetId=${assetId}&token=${token}}`
			);

			console.log("getAsset: ", itemResponse);
			return itemResponse.data;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get serie episodes
const getEpisodes = async (organizationId, groupItemId, token, lang, assetProperty, user) => {
	if (checkArguments(organizationId, groupItemId, token, lang)) {
		try {
			const episodeResponse = await Axios.get(
				`${API_Server}/api/publishing?action=getAssets&version=06&organizationId=${organizationId}&userId=${
					user?.userId || 0
				}&userToken=${user?.userToken || ""}&assetProperty=${
					assetProperty || ""
				}&languageId=${lang}&token=${token}&groupItemId=${groupItemId}&series=false`
			);
			console.log("getEpisodes: ", episodeResponse);
			return episodeResponse.data.assets;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get promoted content
const getPromo = async (
	organizationId,
	groupItemId,
	token,
	lang,
	assetProperty,
	user,
	limitValue
) => {
	if (checkArguments(organizationId, groupItemId, token, lang)) {
		try {
			let limit = limitValue ? limitValue : 3;

			const promoResponse = await Axios.get(
				`${API_Server}/api/publishing?action=getAssets&version=05&organizationId=${organizationId}&userId=${
					user?.userId || 0
				}&userToken=${user?.userToken || ""}&assetProperty=${
					assetProperty || ""
				}&languageId=${lang}&orderBy=publishStart&limit=${limitValue}&token=${token}&groupItemId=${groupItemId}&series=false`
			);
			console.log("getPromo: ", promoResponse);
			return promoResponse;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get banner items
const getBanner = async (organizationId, groupItemId, token, lang, user) => {
	if (checkArguments(organizationId, groupItemId, token, lang)) {
		try {
			const bannerResponse = await Axios.get(
				`${API_Server}/api/publishing?action=getBanners&version=05&organizationId=${organizationId}&userId=${
					user?.userId || 0
				}&userToken=${
					user?.userToken || ""
				}&languageId=${lang}&token=${token}&groupItemId=${groupItemId}&series=false`
			);
			// console.log("getBanner: ", bannerResponse);
			return bannerResponse.data.assets;
		} catch (err) {
			console.log(err);
		}
	}
};

// Authenticate user
const authenticateUser = async (organizationId, email, password, role) => {
	try {
		let url = `${API_Server}/api/login?action=authenticate`;

		const authResponse = await Axios.get(url, {
			params: {
				organizationId,
				eMail: email,
				password: password,
				role: role ? role : "ismsubscriber",
				getSecret: true,
			},
		});
		return authResponse;
	} catch (err) {
		console.log(err);
	}
};

// Register user
const registerUser = async (organizationId, inputs) => {
	try {
		console.log(inputs);

		const registerResponse = await Axios.get(`${API_Server_MY}/api/register?action=addSubscriber`, {
			params: {
				organizationId,
				emailAddress: inputs?.EMAIL?.value,
				userPassword: inputs?.PASSWORD?.value,
				...(inputs?.PHONE?.value ? { phoneNumber: inputs?.PHONE?.value } : {}),
				...(inputs?.CITY?.value ? { cityName: inputs?.CITY?.value } : {}),
				...(inputs?.DATEOFBIRTH?.value ? { dateOfBirth: inputs?.DATEOFBIRTH?.value } : {}),
				...(inputs?.COUNTRY?.value
					? {
							countryId: countries.find((country) => country.name === inputs?.COUNTRY?.value).id,
					  }
					: {}),
				...(inputs?.FIRSTNAME?.value ? { firstName: inputs?.FIRSTNAME?.value } : {}),
				...(inputs?.LASTNAME?.value ? { lastName: inputs?.LASTNAME?.value } : {}),
				...(inputs?.ADDRESS?.value ? { address1: inputs?.ADDRESS?.value } : {}),
			},
		});

		console.log("Register: ", registerResponse);
		return registerResponse;
	} catch (err) {
		console.log(err);
	}
};
const registerUserWithRoles = async (organizationId, inputs, role, token) => {
	try {
		console.log(organizationId, inputs, role, token);

		let url = `${API_Server_MY}/api/register?action=addSubscriber`;

		if (role?.includes("admin")) {
			// we also need to pass token when creating admin
			url = `${url}&token=${token}`;
		}
		const registerResponse = await Axios.get(url, {
			params: {
				organizationId,
				emailAddress: inputs?.EMAIL?.value,
				userPassword: inputs?.PASSWORD?.value,
				...(inputs?.PHONE?.value ? { phoneNumber: inputs?.PHONE?.value } : {}),
				...(inputs?.CITY?.value ? { cityName: inputs?.CITY?.value } : {}),
				...(inputs?.DATEOFBIRTH?.value ? { dateOfBirth: inputs?.DATEOFBIRTH?.value } : {}),
				...(inputs?.COUNTRY?.value
					? {
							countryId: countries.find((country) => country.name === inputs?.COUNTRY?.value)?.id,
					  }
					: {}),
				...(inputs?.FIRSTNAME?.value ? { firstName: inputs?.FIRSTNAME?.value } : {}),
				...(inputs?.LASTNAME?.value ? { lastName: inputs?.LASTNAME?.value } : {}),
				...(inputs?.ADDRESS?.value ? { address1: inputs?.ADDRESS?.value } : {}),
				// if no role means register as a subscriber
				...(role ? { role: role } : { role: "ismsubscriber" }),
			},
		});

		return registerResponse;
	} catch (err) {
		console.log(err);
	}
};
const changePassword = async (
	organizationId,
	key,
	userId,
	newPassword,
	confirmPassword,
	oldPassword,
	userToken,
	admin
) => {
	const token = createChangePasswordToken(
		organizationId,
		key,
		admin ? admin : userId,
		newPassword,
		confirmPassword
	);

	try {
		// TODO: API CHANGES TO PASSWORD CHANGE REQUEST
		// const changePasswordResponse = await Axios.get(
		//   `${API_Server_MY}/api/user?action=changePassword&organizationId=${organizationId}&userId=${
		//     admin ? admin : userId
		//   }&newPassword=${newPassword}&confirmPassword=${confirmPassword}&token=${token}&oldPassword=${oldPassword}&userToken=${userToken}`
		// );

		let url = `${API_Server_MY}/api/user?action=changePassword`;
		const changePasswordResponse = await Axios.get(url, {
			params: {
				organizationId,
				userId: admin ? admin : userId,
				newPassword,
				confirmPassword,
				token,
				oldPassword,
				userToken,
			},
		});

		console.log("ChangePassword: ", changePasswordResponse);
		return changePasswordResponse;
	} catch (err) {
		console.log(err);
		return err;
	}
};

export const requestResetPassword = async (emailAddress, organizationId, role, token) => {
	let url = `${API_Server}/api/user?action=resetPassword`;
	const requestResetPasswordResponse = await Axios.get(url, {
		params: {
			organizationId,
			emailAddress,
			role,
			token,
		},
	});

	console.log("requestResetPassword response: ", requestResetPasswordResponse);
	return requestResetPasswordResponse;
};

export const validatePasswordResetToken = async (token) => {
	let url = `${API_Server}/api/user?action=validatePasswordToken`;
	const res = await Axios.get(url, {
		params: {
			token,
		},
	});

	console.log("validatePasswordResetToken response: ", res);
	return res;
};

export const resetPasswordChange = async (newPassword, confirmPassword, token) => {
	let url = `${API_Server}/api/user?action=setNewPassword`;
	const res = await Axios.get(url, {
		params: {
			newPassword,
			confirmPassword,
			token,
		},
	});

	console.log("reset password response: ", res);
	return res;
};

export const fetchAllAdmins = async (organizationId, userToken) => {
	try {
		const res = await Axios.get(
			`${API_Server_MY}/api/user?action=getUsers&organizationId=${organizationId}&userToken=${userToken}&role=ismaccount%20administrator`
		);
		// console.log("resfetchAllAdmins", res);

		return res?.data;
	} catch (error) {}
};

// Update user
const updateUser = async (
	userId,
	userToken,
	organizationId,
	key,
	firstName,
	lastName,
	phoneNumber,
	countryId,
	regionId,
	cityName,
	postalCode,
	eMail,
	admin
) => {
	// console.log(
	//   userId,
	//   userToken,
	//   organizationId,
	//   key,
	//   firstName,
	//   lastName,
	//   phoneNumber,
	//   countryId,
	//   regionId,
	//   cityName,
	//   postalCode,
	//   eMail,
	//   admin
	// );
	const token = createAccountToken(
		// if admin means one admin wants to change other admin info
		admin ? admin : userId,
		organizationId,
		key,
		firstName,
		lastName,
		phoneNumber,
		countryId,
		regionId,
		cityName,
		postalCode
	);

	try {
		let url = `${API_Server_MY}/api/user?action=updateUser&organizationId=${organizationId}&userToken=${userToken}&firstName=${firstName}&lastName=${lastName}&emailAddress=${eMail}&countryId=${countryId}&regionId=${regionId}&postalCode=${postalCode}&cityName=${cityName}&phoneNumber=${phoneNumber}&token=${token}`;
		if (admin) {
			url = `${url}&userId=${admin}`;
		}
		const updateResponse = await Axios.get(url);
		// console.log("updateUser: ", updateResponse);
		return updateResponse;
	} catch (err) {
		console.log(err);
	}
};

// Get user data
const getUser = async (userToken, organizationId) => {
	try {
		const getUserResponse = await Axios.get(
			`${API_Server}/api/user?action=getUserProfile&organizationId=${organizationId}&userToken=${userToken}`
		);
		console.log("getUser: ", getUserResponse);
		return getUserResponse;
	} catch (err) {
		console.log(err);
	}
};

// Get user data
const searchAssets = async (keyword, user, organizationId, language) => {
	try {
		const getSearchResponse = await Axios.get(
			`${API_Server}/api/search/assets?action=searchAssets`,
			{
				params: {
					organizationId,
					keyword: keyword,
					...(user?.userToken ? { userToken: user?.userToken } : {}),
					...(language ? { language: language } : {}),
					...(user?.userId ? { userId: user?.userId } : {}),
				},
			}
		);

		console.log("searchAsset: ", getSearchResponse);
		return getSearchResponse;
	} catch (err) {
		console.log(err);
	}
};
const getPlayerConfig = async (organizationId, channelID) => {
	try {
		const res = await Axios.get(
			`${API_Server}/api/applications?action=getAppConfig&appId=133485121`
		);
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

// Get organizations packages based on given object (if no object given it will fetch all packages)
const getPackages = async (organizationId, lang, objectId, userToken) => {
	if (checkArguments(organizationId, lang)) {
		try {
			const params = {
				action: "getPackages",
				organizationId,
				// languageId: lang,
			};
			if (objectId > 0) {
				params.objectId = objectId;
			}
			if (userToken) {
				params.userToken = userToken;
			}
			const packagesResponse = await Axios.get(`${API_Server}/api/packages`, {
				params,
			});
			console.log("Org packages: ", packagesResponse);
			return packagesResponse.data;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get all shopping categories for organization
const getShoppingCategories = async (organizationId, lang) => {
	if (checkArguments(organizationId, lang)) {
		try {
			const params = {
				action: "getShoppingCategories",
				organizationId,
			};
			const shoppingCategoriesResponse = await Axios.get(`${API_Server}/api/packages`, { params });
			console.log("Shopping Cats: ", shoppingCategoriesResponse);
			return shoppingCategoriesResponse.data;
		} catch (err) {
			console.log(err);
		}
	}
};

// Get organizations payment methods
const getPaymentMethods = async (organizationId) => {
	try {
		const params = {
			action: "getPaymentMethods",
			organizationId,
		};
		const paymentMethodsResponse = await Axios.get(`${API_Server}/api/payment/methods`, { params });
		console.log("Payment methods: ", paymentMethodsResponse);
		return paymentMethodsResponse.data;
	} catch (err) {
		console.log(err);
	}
};

//get payment url
//get payment url
const getPaymentUrl = async (
	userToken,
	organizationId,
	packageId,
	paymentMethodId,
	userEmail,
	handlerUrl,
	quantity
) => {
	console.log("organiId: ", organizationId);
	try {
		const params = {
			action: "initPurchase",
			organizationId: settings.organization.organizationId,
			// subOrganizationId: organizationId,
			packageId,
			paymentMethodId,
			handlerUrl,
			// vismaVersion: "wp31",
		};
		if (quantity > 0) {
			params.quantity = quantity;
			params.userEmail = userEmail;
		} else {
			params.userToken = userToken;
		}
		console.log(params);
		const paymentUrlResponse = await Axios.get(`${API_Server}/api/purchase`, {
			params,
		});

		console.log("Url", paymentUrlResponse);
		return paymentUrlResponse.data;
	} catch (err) {
		console.log(err);
	}
};

// return from bambora payment
const purchasePackage = async (userToken, oldParams) => {
	try {
		let params = oldParams;
		params.userToken = userToken;
		params.action = "return";

		const purchasePackageResponse = await Axios.get(`${API_Server}/api/purchase/return`, {
			params,
		});

		console.log("PP: ", purchasePackageResponse);
		return purchasePackageResponse.data;
	} catch (err) {
		console.log(err);
	}
};

// Try to make Voucher purchase
const voucherPurchase = async (
	userToken,
	languageId,
	organizationId,
	productId,
	voucherCode,
	quantity,
	usedByEmail
) => {
	try {
		const params = {
			// userToken,
			action: "activateVoucher",
			voucherCode,
			languageId,
			productId,
			usedDate: new Date().getTime(),
			organizationId,
			quantity,
			usedByEmail,
		};
		console.log("PAR: ", params);
		const voucherPurchaseResponse = await Axios.get(`${API_Server}/api/purchase`, { params });
		console.log("VPR: ", voucherPurchaseResponse);
		return voucherPurchaseResponse;
	} catch (err) {
		console.log(err);
	}
};

// checking ticket number
const checkTicketNumber = async (eventId, ticketNumber) => {
	const paymentUrlResponse = await Axios.get(
		`${API_Server}/api/events/access?action=eventAccess&eventId=${eventId}&ticket=${ticketNumber}`
	);
	// console.log(`checkTicket`, paymentUrlResponse);
	return paymentUrlResponse;
};

export const createNewClub = async (
	organizationId,
	name,
	countryId,
	regionId,
	postalCode,
	cityName,

	phoneNumber,
	emailAddress,

	key,

	accountType = "full-organization"
) => {
	console.log("phoneNumber", phoneNumber);
	try {
		let token = createSubOrgToken(
			organizationId,
			accountType,
			name,
			countryId,
			regionId,
			postalCode,
			cityName,
			phoneNumber,
			emailAddress,
			key
		);

		console.log(
			"organizationId",
			name,
			countryId,
			regionId,
			postalCode,
			cityName,

			phoneNumber,
			emailAddress,

			key,

			" organizationId",
			name,
			countryId,
			regionId,
			postalCode,
			cityName,

			phoneNumber,
			emailAddress,

			key
		);

		console.log("token", token);
		// const url = `https://my.icareus.com/delegate/account?action=addOrganization&organizationId=${organizationId}&accountType=${accountType}&name=${name}&countryId=${countryId}&regionId=${regionId}&postalCode=${postalCode}&cityName=${cityName}&phoneNumber=${phoneNumber}&emailAddress=${emailAddress}&token=${token}&nodeonClubId=${nodeonClubId}&edition=ismaccount&templateId=1`;

		const url = `${API_Server_MY}/delegate/account?action=addOrganization&organizationId=${organizationId}&accountType=${accountType}&name=${name}&countryId=${countryId}&regionId=${regionId}&postalCode=${postalCode}&cityName=${cityName}&phoneNumber=${phoneNumber}&emailAddress=${emailAddress}&token=${token}&edition=ismaccount&templateId=3`;

		console.log("urldata", url);
		// return;
		const res = await Axios.get(url);

		console.log("rescreatenew_organization", res);
		return res?.data;
	} catch (error) {
		console.log(error);
		console.log(error?.message);
		console.log(error?.stack);
	}
};

//Get admin account settings (visma payment stuff)
const getAccountSettings = async (organizationId, userToken) => {
	console.log("get account settings");
	try {
		const params = {
			action: "getSettings",
			organizationId,
			userToken,
		};
		const adminSettingsResponse = await Axios.get(`${API_Server}/api/account`, {
			params,
		});

		console.log("Url", adminSettingsResponse);
		return adminSettingsResponse.data;
	} catch (err) {
		console.log(err);
	}
};

// create new event
const updateAccountSettings = async (organizationId, userToken, settings) => {
	try {
		let params = {
			action: "updateSettings",
			organizationId,
			userToken,
		};
		let url = `${API_Server}/api/account`;

		const res1 = await Axios.post(url, settings, { params });

		console.log(`res update account`, res1);

		return res1;
	} catch (error) {
		console.log(error);
		return error;
	}
};

//get bought tickets
const getUserTickets = async (organizationId, userToken, languageId) => {
	try {
		let params = {
			action: "getUserTickets",
			organizationId,
			userToken,
			languageId,
		};
		let url = `${API_Server}/api/user`;

		const res = await Axios.get(url, { params });
		console.log(`res get tickets`, res);
		return res;
	} catch (error) {
		console.log(error);
		return error;
	}
};

export const deleteUser = async (organizationId, userId, key, userToken) => {
	const currentTime = getCurrentTime();

	let paramUser = [organizationId, userId, currentTime, key];
	console.log("paramUser", paramUser);

	const token = createGeneralToken(paramUser, currentTime, "01");

	let params = {
		action: "removeUser",
		organizationId,
		userId,
		userToken,
		token,
	};
	let url = `${API_Server}/api/user`;

	const res = await Axios.get(url, { params });
	return res;
};

export {
	getCategories,
	getRootSubCategories,
	getSubCategories,
	getSeries,
	getLiveNowEvents,
	getEventsCategories,
	getSingleEvent,
	getAllEvents,
	// getEventsCategoriesBeta,
	// getAllEventsBeta,
	// getChannelsBeta,
	// getUpcomingEventsBeta,
	getChannels,
	getUpcomingEvents,
	getAssets,
	getSimiliar,
	getAsset,
	getEpisodes,
	getPromo,
	getBanner,
	getUser,
	authenticateUser,
	changePassword,
	registerUser,
	registerUserWithRoles,
	searchAssets,
	updateUser,
	getRecordings,
	getPlayerConfig,
	voucherPurchase,
	getShoppingCategories,
	getPackages,
	getPaymentUrl,
	purchasePackage,
	getPaymentMethods,
	checkTicketNumber,
	getLatestAssets,
	getTrendingAssets,
	findSubOrganizationSecret,
	createNewEvent,
	editEvent,
	deleteEvent,
	createNewGame,
	getSingleGame,
	updateSingleGame,
	deleteGame,
	getUpcomingGames,
	getLiveNowGames,
	getAccountSettings,
	updateAccountSettings,
	getUserTickets,
};
