import React from 'react';

import * as classes from '../PrivacyPolicy/PrivacyPolicy.module.css';

export default function AboutUs() {
  return (
    <div className={`${classes.tosContainer} font-300`}>
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        SalibandyTV nyt livenä – katso tietopaketti salibandyn lähetyksistä
      </div>
      <br />
      <hr />
      <br />
      <div className={`${classes.tosMainTitle_p} font-300`}>
        {' '}
        SalibandyTV on aloittanut uuden aikakauden lajin lähetyksissä. 
        SalibandyTV on Salibandyliiton uusi videoalusta, joka kokoaa kattavasti 
        lajin lähetykset yhteen kanavaan. SalibandyTV löytyy osoitteesta salibandy.tv.
      </div>

      <br />
      <div className={`${classes.tosMainTitle_p}  font-300`}>
        SalibandyTV on Salibandyliiton oma palvelu ja se on avautunut elokuussa 2022. 
        Salibandyliitto on sopinut palvelun rakentamisesta suomalaisen Icareus Oy:n 
        kanssa. Yhteistyö on monivuotinen.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        SalibandyTV-kokonaisuuteen kuuluvat verkkosivusto osoitteessa salibandy.tv 
        sekä Android– ja iOS-striimaussovellukset. SalibandyTV:n katselu tapahtuu aina 
        selainpohjaisesti sekä mobiilissa että tietokoneella. SalibandyTV:n Android- 
        ja iOS-sovellukset ovat siis striimaamista varten, eivät katselua. SalibandyTV:n 
        käyttö ja katselu vaatii aina kirjautumisen.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        SalibandyTV:ssä seurat voivat lähettää sekä maksullisia että maksuttomia 
        lähetyksiä. SalibandyTV:n tarjontaan kuuluvat kaikki Salibandyliiton alaiset 
        ottelut F-liigaa lukuunottamatta. Samasta palvelusta voi siis jatkossa katsoa 
        otteluita pienimmistä junioreista aina naisten 1. divisioonaan ja miesten 
        Inssi-Divariin sekä MAAJOUKKUETIE-otteluista Suomen maaotteluihin. Tuotannoista 
        seurat vastaavat itse. Palvelun käyttö lähetysten tekemiseen on seuroille maksutonta.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        Salibandylähetysten ABC – SalibandyTV, Ruutu, F-liigaTV ja YLE:
        <br />
        <a href="https://salibandy.fi/fi/info/lahetykset/lahetysten-abc/">
          https://salibandy.fi/fi/info/lahetykset/lahetysten-abc/
        </a>
      </div>
      <br />

      <br />
      <br />
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        Mikä on SalibandyTV:n tarkoitus?
      </div>
      <br />
      <hr />
      <br />
      <div className={`${classes.tosMainTitle_p} font-300`}>
        SalibandyTV:llä on kolme selkeää tavoitetta: 1. Palvella mahdollisimman hyvin 
        seurojen tarpeita, 2. Kaikki salibandy samassa kanavassa ja 3. Käyttäjäystävällinen 
        ja toimintavarma palvelu.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Koko homman pihvi on se, että teemme tätä aidosti suomalaiselle salibandylle ja 
        salibandyseuroille. Liitto ja muutamat sidosryhmät investoivat tähän, ja kun seura 
        tuottaa lähetyksen SalibandyTV-palveluun, niin siitä tiliytyy automaattisesti suoraan 
        seuralle 70–80 prosenttia lähetystuotoista. Näin korkeisiin jakoprosentteihin ei 
        olla aiemmin päästy. Näin seurat saavat pätevän tavan hankkia resursseja toimintaansa.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        SalibandyTV keskittää F-liigaa lukuunottamatta kaikki salibandylähetykset yhteen 
        paikkaan. Avaintekijänä tässä on se, että palveluun voi tuottaa sekä maksullisia 
        että maksuttomia lähetyksiä.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Hienointa tässä on se, että pääsemme tekemään tätä niin laajalle yleisölle. 
        Vuosittain striimataan tuhansia otteluita ja käyttäjiä on monenlaisia, siinä on 
        tietysti haasteensa ja se on myös valtava mahdollisuuus. Jostain mediasta lähes 
        kaikki pelit nykyään tulevat – on upea juttu saada kaikki yhteen palveluun, 
        SalibandyTV:n rakentamisesta vastaavan Icareuksen kehitysjohtaja Mikko Karppinen 
        kommentoi.
      </div>
      <br />

      <br />
      <br />
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        Mikä on SalibandyTV:n tilanne nyt?
      </div>
      <br />
      <hr />
      <br />

      <div className={`${classes.tosMainTitle_p} font-300`}>
        SalibandyTV:n tilanne on tällä hetkellä se, että palvelusta on julki betaversio. 
        Palveluun on rekisteröitynyt jo lähes 100 seuraa ja yli 9000 katselijaa (tilanne 6.10.2022). 
        Vaikka kyseessä on betaversio, niin otteluiden striimaaminen ja katselu onnistuvat normaalisti.
      </div>
      <br />
 
      <div className={`${classes.tosMainTitle_p} font-300`}>
        – Pyrimme tekemään palvelusta mahdollisimman hyvän kaikille käyttäjäryhmille. Nyt alkuun 
        keskitymme voimakkaasti ns. peruskallion rakentamiseen eli siihen, että palvelun perusasiat 
        toimivat hyvin ja luotettavasti. Sitten lisäilemme ominaisuuksia pala kerrallaan. Toiveiden 
        tynnyri ominaisuuksien osalta on todella kattava, viestintäjohtaja Ojala taustoittaa ja jatkaa:
      </div>
      <br />
 
      <div className={`${classes.tosMainTitle_p} font-300`}>
        – Vuoropuhelu seurojen ja striimaajien kanssa on ollut vilkasta viime viikot ja kuukaudet
        – palaute ja vastaanotto on ollut positiivista ja innostunutta. Monet ovat heti nähneet 
        SalibandyTV:n potentiaalin ja mahdollisuudet – se lämmittää kovasti.
      </div>
      <br />
 
      <div className={`${classes.tosMainTitle_p} font-300`}>
        SalibandyTV:ssä tapahtuu joka viikko paljon. Palvelun kehitystä voi seurata kätevästi jatkuvasti 
        päivittyvän seurantasivun avulla. Sivulle keräämme ne tärkeimmät päivitykset, joten tarjolla 
        on ajankohtaista ja tärkeää tietoa erityisesti seuroille ja otteluiden striimaajille (sekä katsojille).
      </div>
      <br />
 
      <div className={`${classes.tosMainTitle_p} font-300`}>
        SalibandyTV:n tilannekatsaus – päivittyvä seuranta:

        {' '}
        <a href="https://salibandy.fi/fi/info/lahetykset/salibandytvn-tilannekatsaus-paivittyva-seuranta/">
        https://salibandy.fi/fi/info/lahetykset/salibandytvn-tilannekatsaus-paivittyva-seuranta/
        </a>
      </div>
      <br />

      <br />
      <br />
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        Näin katselet SalibandyTV:tä
      </div>
      <br />
      <hr />
      <br />
      <div className={`${classes.tosMainTitle_p} font-300`}>
        – Avaa osoite salibandy.tv.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – SalibandyTV:n katselu tapahtuu aina selainpohjaisesti sekä mobiilissa että 
        tietokoneella. SalibandyTV:n Android- ja iOS-sovellukset ovat striimaamista 
        varten, eivät katselua.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Palvelun käyttö ja katselu vaatii aina kirjautumisen. Katselutunnukset saat 
        luotua kohdasta ”REKISTERÖIDY”. Kun olet saanut tunnukset luotua, niin mene 
        kohtaan ”KIRJAUDU” ja valitse ”KÄYTTÄJÄNÄ”.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – SalibandyTV:n tarjontaan kuuluvat kaikki Salibandyliiton alaiset ottelut 
        F-liigaa lukuun ottamatta. Samasta palvelusta voi siis jatkossa katsoa otteluita 
        pienimmistä junioreista aina naisten 1. divisioonaan ja miesten Divariin sekä 
        MAAJOUKKUETIE-otteluista Suomen maaotteluihin. Myös salibandyotteluita ja -turnauksia, 
        jotka eivät ole Salibandyliiton alaisia on mahdollista striimata ja katsella SalibandyTV:stä.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Tuotannoista seurat vastaavat itse. Palvelun käyttö lähetysten tekemiseen on seuroille maksuton.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Osa SalibandyTV:n otteluista on maksullisia ja osa maksuttomia. Seurat saavat 
        itse päättää pyytävätkö ottelulähetyksistä maksua. Beta-vaiheessa maksutoiminto 
        ei kuitenkaan ole vielä käytössä, joten kaikki ottelut ovat katseltavissa maksuttomasti.
      </div>
      <br />

      <br />
      <br />
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        Näin striimaat SalibandyTV:stä
      </div>
      <br />
      <hr />
      <br />

      <div className={`${classes.tosMainTitle_p} font-300`}>
        – Avaa osoite salibandy.tv.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Seuran tarvitsee ensin rekisteröityä palveluun. Tämä tehdään koko seuran 
        osalta vain yhden kerran: rekisteröityminen tapahtuu REKISTERÖI SEURA -sivulla 
        osoitteessa: 
        {' '}
        <a href="https://salibandy.tv/addNewSub">
          https://salibandy.tv/addNewSub
        </a>
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Seurojen tulee sisäisesti koordinoida (tarvittaessa Salibandyliiton viestinnän 
        avustuksella), että kuka seurasta käy rekisteröimässä seuran. REKISTERÖI SEURA 
        -sivulle syötetään perustiedot seurasta. Logo ja seurasivu kansikuva lisätään 
        SalibandyTV:seen Salibandyliiton toimesta.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Rekisteröitymisen jälkeen verkkosivuston HALLINTAPANEELISTA voi hallita ja 
        lisätä seuran alle käyttäjiä, jotka voivat striimata.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Kun kirjaudut palveluun seuran hallintakäyttäjänä, niin muista kirjautuessa 
        klikata kohtaa KIRJAUDU HALLINTAKÄYTTÄJÄNÄ. Näin striimaaminen onnistuu.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – Mobiilisovelluksella striimaaminen tapahtuu siten, että ottelu käydään ensin 
        luomassa verkkosivujen HALLINTAPANEELISTA klikkaamalla toimintoa LISÄÄ OTTELU. 
        Siellä asetetaan ottelulle mm. nimi, ajankohta, joukkueet, kesto sekä onko 
        kyseessä sarja- vai harjoituspeli. Tämä jälkeen avaat ja kirjaudut Android- tai 
        iOS-sovellukseen, etsit kyseisen ottelun ja alat striimata. Betaversiossa 
        striimausapplikaatiossa ei ole vielä esim. tulosseurantaa tai ottelukelloa, 
        mutta nämä pyritään lisätään sinne lähiaikoina.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        – SalibandyTV:seen voi striimata myös muilla yleisillä striimaussovelluksilla, 
        esim. Tricaster, Open Broadcaster, Wirecast jne. Ottelu käydään ensin luomassa 
        verkkosivujen HALLINTAPANEELISTA klikkaamalla toimintoa LISÄÄ OTTELU. Siellä 
        asetetaan ottelulle mm. nimi, ajankohta, joukkueet, kesto sekä onko kyseessä 
        sarja- vai harjoituspeli. Tämä jälkeen ottelulle muodustuu striimiavain ja muut 
        tarvittavat tiedot, jonka syöttämällä striimisovellukseen saat striimin toteutettua. 
        Tiedot löytyvät ottelun hallintasivulta hallintapaneelin kautta. Täällä voit myös 
        esikatsella striimiäsi ennen ottelun alkua.
      </div>
      <br />

      <br />
      <br />
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        SalibandyTV:n tuki
      </div>
      <br />
      <hr />
      <br />
      
      <div className={`${classes.tosMainTitle_p} font-300`}>
        SalibandyTV:n tukipalvelut vastaavat ja auttavat nopeasti sekä katsojien että 
        striimaajien kysymyksiin. Tuesta huolehtivat SalibandyTV:n kanavakoordinaattori 
        Atte Palonen ja Salibandyliiton viestintäjohtaja Jussi Ojala yhteistyössä 
        SalibandyTV:n ylläpitäjän Icareus Oy:n kanssa.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p} font-300`}>
        – Lupaamme vastata ihan jokaiseen yhteydenottoon – tuli se sitten somessa, 
        puhelimitse, meilillä tai mistä tahansa. Yhteydessä kannattaa olla matalalla 
        kynnyksellä, tyhmiä kysymyksiä ei ole olemassakaan. Panostamme erityisesti 
        SalibandyTV:n alkumetreille tukitoimintoihin, liiton viestintäjohtaja Ojala kommentoi.
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        SalibandyTV:n tuen yhteystiedot:
        {' '}
        <a href="https://salibandy.fi/fi/info/lahetykset/salibandytv-tuki/">
          https://salibandy.fi/fi/info/lahetykset/salibandytv-tuki/
        </a>
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        —
      </div>
      <br />

      <div className={`${classes.tosMainTitle_p}  font-300`}>
        Infoja salibandylähetyksistä kootusti:
        {' '}
        <a href="https://salibandy.fi/fi/info/lahetykset/">
          https://salibandy.fi/fi/info/lahetykset/
        </a>
      </div>
      <br />
    </div>
  );
}
