const SwitchCheckBox = ({ id, switchChecked, onChange, label }) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between ",
                alignItems: "center",
            }}
        >
            <p
                className={`font-400`}
                style={{
                    //   marginBottom: '5px',
                    padding: "0rem 0rem 0.2rem 1rem",
                    color: "white",
                }}
            >
                {label}
            </p>
            <div className="button-switch">
                <input type="checkbox" id={id} className="switch" checked={switchChecked} onChange={(e) => onChange(e.target.checked)} />
                <label htmlFor={id} className="lbl-off">
                    Off
                </label>
                <label htmlFor={id} className="lbl-on">
                    On
                </label>
            </div>
        </div>
    );
};

export default SwitchCheckBox;
