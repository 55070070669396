import React, { useCallback, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useMyContext } from "../../../contexts/StateHolder";

import { useParams } from "react-router-dom";
import { getPackages, getSingleEvent, getUser, getUserTickets, purchasePackage } from "../../../scripts/dataHandlers";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import { toast } from "react-toastify";
import "moment/locale/fi";
import { useLocation } from "react-router-dom";
import CompletedEvents from "./CompletedEvents";
import FutureEvents from "./FutureEvent";
import * as settings from "../../../config_settings.json";

export default function UpcomingEvent(props) {
    const [singleEvent, setSingleEvent] = useState(null);
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [loading, setLoading] = useState(true);

    const { singleEventItem, setTicketQuantity, profilePackages, setUser, setProfilePackages, language, isLocationAccessGranted, setIsLocationAccessGranted } =
        useMyContext();
    const { orgId, eventId } = useParams();
    const { user, setGoBackToPrevious } = useMyContext();

    // if the events requires ticket to grant access

    const [ticketAccessRequired, setTicketAccessRequired] = useState(false);
    const [ticketAccessGranted, setTicketAccessGranted] = useState(false);
    const [cookies, setCookie] = useCookies(["tiAcc"]);
    const history = useHistory();
    const location = useLocation();
    const [organizationPackageFetching, setOrganizationPackageFetching] = useState(true);

    useEffect(() => {
        // we need to check for login if it does not exists we will redirect to home page

        if (!user?.loggedIn) {
            console.log("location", location.pathname);
            // lets save this location to cookies

            setCookie("redirectUrl", location.pathname, {
                path: "/",
            });
            setGoBackToPrevious(true);
            history.replace("/login");
        }
    }, [user]);

    const getPackagesData = useCallback(async () => {
        try {
            const responseData = await getPackages(settings.organization.organizationId, language, 0);

            console.log(`packagesData`, responseData);

            if (responseData.status === "ok" && typeof responseData.packages !== "undefined") {
                console.log("set progilePack");
                setProfilePackages(responseData.packages);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    const getUserData = useCallback(async () => {
        try {
            const response = await getUser(cookies?.userData?.userToken, user.organizationId);
            console.log("products:", response.data);

            if (response?.data?.buyerProducts) {
                user.buyerProducts = response.data.buyerProducts;

                setUser({ ...user });
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    const getUserTicketData = useCallback(async () => {
        try {
            const response = await getUserTickets(settings.organization.organizationId, cookies?.userData?.userToken, language);
            console.log(response.data.data);

            if (response?.data?.data) {
                user.ticketProducts = response.data.data;
                setUser({ ...user });
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    const giveTicketAccess = useCallback(() => {
        setTicketAccessRequired(false);
        setTicketAccessGranted(true);
    }, []);

    const denyTicketAccess = useCallback(() => {
        setTicketAccessRequired(true);
        setTicketAccessGranted(false);
    }, []);
    // if ticccket has been checked already so we can give error /succcesful message

    // this useEffect will only run when user is redirected from purchase page to here
    useEffect(() => {
        let params = queryString.parse(window.location.search);

        async function purchasePackageData() {
            const purchaseResponse = await purchasePackage(cookies?.userData?.userToken, params);
            console.log(purchaseResponse, "purchaseResponse");

            console.log(purchaseResponse, purchaseResponse.message, purchaseResponse.errorCode === 27, purchaseResponse.errorCode);
            if (purchaseResponse.message.includes("successful") || purchaseResponse.status === "ok") {
                giveTicketAccess();
                toast.success("Purchase Successful");
            } else if (
                purchaseResponse.errorCode === 27 ||
                purchaseResponse.message.includes("refresh call for order")

                // this occours when user refrsh the page
            ) {
                if (purchaseResponse?.tickets) {
                    giveTicketAccess();
                }
            }

            setLoading(false);
        }

        // for dpo payment
        if (params) {
            if (params?.paymentMethod === "7") {
                purchasePackageData();
            } else if (params?.RETURN_CODE === "0") {
                purchasePackageData();
            } else if (params.voucheractivated) {
                console.log("params123", params);
                // here we have to profile fetching again and this will check for voucher
                setTimeout(() => {
                    setOrganizationPackageFetching(true);
                }, 200);
                setTimeout(() => {
                    const fetchUserInfo = async () => {
                        await getPackagesData();
                        await getUserData();
                        await getUserTicketData();
                        setOrganizationPackageFetching(false);
                    };
                    fetchUserInfo();
                }, 200);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // checks ticket
    useEffect(() => {
        if (!organizationPackageFetching) {
            const checkTicket = (eventObj) => {
                setSingleEvent(eventObj);
                setDefaultLanguage(eventObj.defaultLanguage || "en_US");
                console.log("user,profilePackages", user, profilePackages);
                // first get all sku from user buyer product

                let userAllSKU = user?.buyerProducts?.map((el) => el.sku);
                if (!userAllSKU) {
                    userAllSKU = [];
                }
                // for some reason when package is bought by using voucher ->ticketprofile api seeems to have their info instead of buyerProducts so we need to check that as well
                let ticketProfileSKU = user?.ticketProducts?.map((el) => el.sku);
                userAllSKU = [...userAllSKU, ...ticketProfileSKU];

                // get all configurration from organization

                let allEventInPackage = profilePackages.map((el) => el.configuration)?.filter((el1) => el1 !== "");

                let finalString = "";
                let isCurrentEventLocationGranted;

                allEventInPackage.forEach((el, i) => {
                    if (i === allEventInPackage.length - 1) {
                        finalString += el;
                    } else {
                        // we add : for all element except the last one
                        finalString += `${el},`;
                    }
                });
                console.log("finalString", finalString, "eventId", eventId);
                // we give all access to superAdmin
                if (user?.superAdmin) {
                    giveTicketAccess();
                    setIsLocationAccessGranted(true);
                } else if (finalString.includes(eventId)) {
                    // event is included in some kind of packages

                    // now filtering out sku which belings to current event
                    let currentEventPackage = profilePackages.filter((el) => el?.configuration.includes(eventId));
                    // current Event SKU
                    let divaripackageSKU = currentEventPackage.map((el1) => el1.sku);
                    console.log("divaripackageSKU ", divaripackageSKU, userAllSKU);
                    // first we have to check if access is granted or not
                    if (divaripackageSKU?.length) {
                        console.log("cuurentEventpackage", currentEventPackage);
                        isCurrentEventLocationGranted = currentEventPackage.every((el) => el?.locationAccess?.access);

                        console.log("isCurrentEventLocationGranted", isCurrentEventLocationGranted);
                    }

                    if (isCurrentEventLocationGranted) {
                        // here if the package is granted location access we dont check for single ticket location at all
                        if (!userAllSKU?.some((el) => divaripackageSKU?.includes(el))) {
                            // if userAllSKu is not included in divariPackage it means user have not bought the product yet
                            // if user have not bought the package ->check if they have bought the same event as single bought ticket
                            const ticketExists = eventObj?.accessControls.find((el) => el.typeName === "Ticket");
                            console.log("ticketExists", ticketExists);
                            if (ticketExists) {
                                //    CHECK FOT TICKET IN USER PROFILE

                                const allUserBoughtTicket = user?.ticketProducts?.map((el) => el?.eventId);
                                console.log("allUserBoughtTicket", allUserBoughtTicket, eventId);

                                if (allUserBoughtTicket?.includes(eventId * 1)) {
                                    // user has bought single ticket
                                    giveTicketAccess();
                                } else {
                                    // not bought   ticket

                                    denyTicketAccess();
                                }
                            } else {
                                // not bought ticket nor package
                                denyTicketAccess();
                            }
                        } else {
                            giveTicketAccess();
                        }
                        setIsLocationAccessGranted(true);
                    } else {
                        setIsLocationAccessGranted(false);
                        denyTicketAccess(true);
                    }
                } else if (eventObj?.accessControls?.length > 0) {
                    const ticketExists = eventObj?.accessControls.find((el) => el.typeName === "Ticket");
                    console.log("ticketExists", ticketExists);
                    if (ticketExists) {
                        //    CHECK FOT TICKET IN USER PROFILE
                        isCurrentEventLocationGranted = ticketExists?.locationAccess?.access;

                        if (isCurrentEventLocationGranted) {
                            // check for ticket now

                            const allUserBoughtTicket = user?.ticketProducts?.map((el) => el?.eventId);
                            console.log("allUserBoughtTicket", allUserBoughtTicket, eventId);

                            if (allUserBoughtTicket?.includes(eventId * 1)) {
                                giveTicketAccess();
                            } else {
                                denyTicketAccess();
                            }
                            setIsLocationAccessGranted(true);
                        } else {
                            setIsLocationAccessGranted(false);
                            denyTicketAccess();
                        }
                    } else {
                        // this should never reach here but just a prevention for any bug
                        setIsLocationAccessGranted(true);
                        giveTicketAccess();
                    }
                } else {
                    // for free ticket we dont have location check for now
                    setIsLocationAccessGranted(true);

                    giveTicketAccess();
                }
                setLoading(false);
            };
            if (singleEventItem) {
                setSingleEvent(singleEventItem);

                checkTicket(singleEventItem);
            } else {
                const getData = async () => {
                    const res = await getSingleEvent(orgId, eventId);

                    checkTicket(res?.event);
                };
                getData();
            }
            return () => {
                setTicketQuantity(1);
            };
        }
    }, [singleEventItem, eventId, orgId, organizationPackageFetching, user]);

    useEffect(() => {
        if (user?.userToken) {
            const fetchInfo = async () => {
                (!profilePackages || profilePackages.length === 0) && (await getPackagesData());
                await getUserData();
                !user?.ticketProducts && (await getUserTicketData());
                setTimeout(() => {
                    setOrganizationPackageFetching(false);
                }, 10);
            };

            fetchInfo();
        }
    }, [user?.userToken]);

    const Renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return !loading ? (
                <CompletedEvents
                    routes={props.routes}
                    singleEvent={singleEvent}
                    ticketAccessRequired={ticketAccessRequired}
                    setTicketAccessGranted={setTicketAccessGranted}
                    ticketAccessGranted={ticketAccessGranted}
                    defaultLanguage={defaultLanguage}
                />
            ) : (
                <></>
            );
        } else {
            return (
                <>
                    <FutureEvents
                        days={days}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                        completed={completed}
                        routes={props.routes}
                        singleEvent={singleEvent}
                        defaultLanguage={defaultLanguage}
                        setTicketAccessGranted={setTicketAccessGranted}
                        ticketAccessGranted={ticketAccessGranted}
                        ticketAccessRequired={ticketAccessRequired}
                    />
                </>
            );
        }
    };

    return (
        <>
            <div>
                {!loading && singleEvent && (
                    <>
                        <Countdown date={singleEvent?.startTime} renderer={Renderer} />
                    </>
                )}
            </div>
        </>
    );
}
