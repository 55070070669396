import { NavLink } from 'react-router-dom';
import * as classes from './NavBarButton.module.css';
import { useMyContext } from '../../contexts/StateHolder';
import { FiSearch } from 'react-icons/fi';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const NavBarButton = (props) => {
  const { closeHamMenu } = useMyContext();

  const location = useLocation();
  // console.log(location);

  return (
    <li className='menu-item'>
      {' '}
      <NavLink
        key={props.name}
        style={props?.styles}
        to={`/${props.route}`}
        exact={true}
        activeClassName={classes.activeSelected}
        onClick={closeHamMenu}
        className={`${classes.navBarSearch} ${
          props.extraClassname && 'font-600'
        } protrebleextrabold`}
      >
        {props.icon === 'FaSearch' ? (
          <FiSearch className={classes.FaIcon} />
        ) : null}
        {props.name && <div className='protrebleextrabold'>{props.name}</div>}
      </NavLink>
    </li>
  );
};

export default NavBarButton;
