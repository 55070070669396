import React from "react";

import * as classes from "../../components/Scoreboard/Scoreboard.module.css";

const ScoreBoardInput = ({
    changeHandler,
    label,
    value,
    disabled,
    min,
    id,
    inputClassName,
    showCTAButton,
    onClickIncrease,
    onClickDecrease,
    disabledButton,
    disabledAllButton,
}) => {
    return (
        <>
            <div
                className={classes[inputClassName]}
                style={{
                    flexDirection: "column",
                }}
            >
                <p className="font-400">{label}</p>
                <input
                    className={`font-400 regular`}
                    placeholder="&nbsp;"
                    id={id}
                    type="number"
                    onChange={(e) => {
                        changeHandler(e);
                    }}
                    value={value}
                    disabled={disabled || disabledAllButton}
                    min={min}
                />
            </div>

            {showCTAButton && (
                <>
                    <div className={`${classes.teamNameButton} font-600 ${classes["scoreboard-item--topcta_items_button_item"]}`}>
                        <button className={classes.decreaseScoreButton} disabled={disabledButton || disabledAllButton} onClick={onClickDecrease}>
                            -1
                        </button>
                        <button onClick={onClickIncrease} disabled={disabledAllButton}>
                            +1
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default ScoreBoardInput;

/* <div
                        className={classes["scoreboard-time-container"]}
                        style={{
                            flexDirection: "column",
                        }}
                    >
                        <p>Minute</p>
                        <input
                            className={`${classes.input_text} font-400 regular`}
                            placeholder="&nbsp;"
                            id={"id"}
                            type="number"
                            onChange={(e) => {
                                setMinutes(e.target.value);
                                MINUTE = e.target.value * 1;
                            }}
                            value={minutes}
                            disabled={clockRunning}
                            min={0}
                        />
                    </div> */

{
    /* <div
                        className={classes["scoreboard-time-container"]}
                        style={{
                            flexDirection: "column",
                        }}
                    >
                        <p>Minute</p>
                        <input
                            className={`${classes.input_text} font-400 regular`}
                            placeholder="&nbsp;"
                            id={"id"}
                            type="number"
                            onChange={(e) => {
                                setMinutes(e.target.value);
                                MINUTE = e.target.value * 1;
                            }}
                            value={minutes}
                            disabled={clockRunning}
                            min={0}
                        />
                    </div> */
}

{
    /* <p>Home team Name Score</p>
                        <input
                            className={`${classes.input_text} font-400 regular`}
                            placeholder="&nbsp;"
                            id={"id"}
                            type="number"
                            onChange={(e) => {
                                setTeamScoreHandler(e?.target?.value * 1, "home");
                            }}
                            value={scoreTeamA}
                            min={0}
                        /> */
}

{
    /* <div className={`${classes.teamNameButton} font-600 ${classes["scoreboard-item--topcta_items_button_item"]}`}>
                            <button
                                className={classes.decreaseScoreButton}
                                disabled={scoreTeamB <= 0}
                                onClick={() => {
                                    let scoreB = scoreTeamB;
                                    setTeamScoreHandler(scoreB - 1, "away");
                                }}
                            >
                                -1
                            </button>
                            <button
                                onClick={() => {
                                    let scoreB = scoreTeamB;
                                    setTeamScoreHandler(scoreB + 1, "away");
                                }}
                            >
                                +1
                            </button>
                        </div> */
}

{
    /* <div className={`${classes.teamNameButton} font-600 ${classes["scoreboard-item--topcta_items_button_item"]}`}>
    <button
        className={classes.decreaseScoreButton}
        disabled={scoreTeamA <= 0}
        onClick={() => {
            let scoreA = scoreTeamA;
            setTeamScoreHandler(scoreA - 1, "home");
        }}
    >
        -1
    </button>

    <button
        onClick={() => {
            let scoreA = scoreTeamA;
            setTeamScoreHandler(scoreA + 1, "home");
        }}
    >
        +1
    </button>
</div>; */
}
