import React, { useEffect, useState } from "react";

import { getAllSuborganization } from "../scripts/dataHandlers";
import * as settings from "../config_settings.json";
import Loader from "react-loader-spinner";
import ChangeOrganizationItem from "../components/Admin/Settings/ChangeOrganization/ChangeOrganizationItem";

export default function ChangeOrganization() {
    const [allOrganizationList, setAllOrganizationList] = useState(null);

    useEffect(() => {
        const getAllOrg = async () => {
            const res = await getAllSuborganization(settings.organization.organizationId, settings.organization.key);
            console.log("res", res);
            let y = [];
            res?.data?.organization?.subOrganizations.forEach((el) => {
                let g = { value: `${el.organizationId}:${el.secret}:${el.nodeonClubId}`, label: el.name };
                y.push(g);
            });

            setAllOrganizationList(y);
        };

        getAllOrg();
    }, []);

    return allOrganizationList?.length > 0 ? (
        <ChangeOrganizationItem allOrganizationList={allOrganizationList} />
    ) : (
        <div className="display-flex-center">
            {" "}
            <Loader type="TailSpin" color="#161eaf" />{" "}
        </div>
    );
}
