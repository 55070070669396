import React, { useEffect } from "react";
import * as classes from "./ListAllSubOrg.module.css";
import { Link } from "react-router-dom";
import { useMyContext } from "../../contexts/StateHolder";
import { getChannels } from "../../scripts/dataHandlers";
import * as settings from "../../config_settings.json";
import Carousel from "react-multi-carousel";
import { useState } from "react";
import Loader from "react-loader-spinner";
import Header from "../../Shared/Header/Header";

// for smooth scroll within same page
import { HashLink, NavHashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { getImageByKey } from "../../scripts/getImageByKey";

export default function ListAllSubOrg({ setting }) {
  const { t } = useTranslation();
  const { subOrgList, setSubOrgList } = useMyContext();
  const [loading, setLoading] = useState(true);

  const { slickSettings } = setting;
  // http://mylanguages.org/finnish_alphabet.php
  let allLetter = "abcdefghijklmnopqrstuvwxyzåäö";
  const [filteredContent, setFilteredContent] = useState([]);
  const [searchFilteredContent, setSearchFilteredContent] = useState([]);
  // for search handle input
  const [searchFieldInput, setSearchFieldInput] = useState("");

  const searchFormHandler = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const fetchRes = async () => {
      const res = await getChannels(
        settings.organization.organizationId,
        settings.organization.key
      );
      // console.log('res', res);
      setSubOrgList(
        res?.data?.organization?.subOrganizations.sort((a, b) =>
          a?.name.localeCompare(b?.name, "es-ES")
        )
      );
      // setSubOrgList(res?.data?.organization?.subOrganizations.sort((a, b) => a?.name.localeCompare(b?.name)));

      let content = res?.data?.organization?.subOrganizations;
      // console.log('content', content);

      setLoading(false);
      let x = [];
      content.forEach((el) => {
        allLetter
          .toUpperCase()
          .split("")
          .forEach((el1) => {
            // console.log(el.name, el.name.split('')[0]);

            if (el1 === el.name.split("")[0].toUpperCase()) {
              x.push(el1);
            }
          });
      });
      setFilteredContent([...new Set(x)]);
      // console.log('x name', filteredContent);
    };

    fetchRes();
  }, []);

  useEffect(() => {
    if (filteredContent?.length > 0 && searchFieldInput) {
      let y = [];
      filteredContent.forEach((el) => {
        if (el === searchFieldInput.split("")[0].toUpperCase()) {
          y.push(el);
        }
      });

      setSearchFilteredContent(y);
    }
  }, [searchFieldInput]);

  const contentItem = searchFieldInput
    ? searchFilteredContent
    : filteredContent;

  // console.log(contentItem, 'contentItem');
  console.log("subOrgList", subOrgList);

  return !loading && subOrgList?.length > 0 ? (
    <div>
      <div className={classes.primaryContainer}>
        <Header
          title={t("allClubs.title")}
          showSearchBox
          searchFieldInput={searchFieldInput}
          searchFormHandler={searchFormHandler}
          setSearchFieldInput={setSearchFieldInput}
          hideSearchIcon={true}
        />
        {!searchFieldInput && (
          <Carousel {...slickSettings}>
            {subOrgList.map((el) => {
              return (
                <div
                  className={classes.ListAllSubOrg_item}
                  key={el.organizationId}
                >
                  <div
                    style={{
                      height: "290px",
                      width: "100%",
                      minHeight: "290px",
                      background: "white",
                      padding: "20px",
                    }}
                  >
                    <Link to={`clubs/${el.name}/${el.organizationId}`}>
                      <div
                        className={classes.ListAllSubOrg_image}
                        style={{
                          backgroundImage: `url(${
                            el?.logoUrl
                              ? el?.logoUrl
                              : getImageByKey("defaultClubLogo")
                          })`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "50%",
                          display: "block",
                          width: "100%",
                          height: "100%",
                          // borderRadius: "50px",
                        }}
                        alt=""
                      ></div>
                    </Link>
                  </div>
                  <div
                    className={`${classes.ListAllSubOrg_name} protrebleheavy`}
                  >
                    {el.name}
                  </div>
                </div>
              );
            })}
          </Carousel>
        )}
      </div>

      <div className={classes.secondaryContainer}>
        <div className={classes.tertiaryContainer}>
          <ul className={`${classes.allLetter} smallcasebold`}>
            {allLetter
              .toUpperCase()
              .split("")
              .map((el, i) => {
                return (
                  <li
                    key={`${el}${i}`}
                    className={`font-400  ${
                      !filteredContent.includes(el) &&
                      classes.allLetter_disabled
                    }`}
                  >
                    <HashLink smooth to={`/clubs#${el}`}>
                      {el}
                    </HashLink>
                  </li>
                );
              })}
          </ul>
          <div className={classes.allLetter_alphabet}>
            {contentItem.map((el, i) => {
              return (
                <div
                  className={classes.allLetter_alphabet_primary}
                  key={`${el}${i}`}
                >
                  <div className={classes.container}>
                    <div className={classes.item_alphabet}> {el}</div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.container}>
                    <div></div>
                    <div className={classes.allLetter_alphabet_item_content}>
                      {subOrgList.map((el1, i) => {
                        return searchFieldInput
                          ? el1.name.toUpperCase().split("")[0] === el &&
                              el1.name
                                .toUpperCase()
                                .includes(searchFieldInput.toUpperCase()) && (
                                <>
                                  <NavHashLink
                                    smooth
                                    to={`clubs/${el1.name}/${el1.organizationId}`}
                                    className={`font-400 ${classes.allLetter_alphabet_item}`}
                                    key={`${el}${i}`}
                                    id={`${el}`}
                                  >
                                    <div
                                      key={el1.groupId}
                                      className={`${classes.item_content} probold`}
                                    >
                                      {el1.name}
                                    </div>
                                  </NavHashLink>
                                </>
                              )
                          : el1.name.toUpperCase().split("")[0] === el && (
                              <NavHashLink
                                smooth
                                to={`clubs/${el1.name}/${el1.organizationId}`}
                                className={`font-400 ${classes.allLetter_alphabet_item} 
  
      `}
                                key={`${el}${i}`}
                                id={`${el}`}
                              >
                                <div
                                  key={el1.groupId}
                                  className={`${classes.item_content} probold`}
                                >
                                  {el1.name}
                                </div>
                              </NavHashLink>
                            );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader type="TailSpin" color="#dfdfdf" height={50} width={50} />
  );
}
